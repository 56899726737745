import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import {reducer} from 'redux-json-api';
import events from "./events";


const api = reduceReducers(
    reducer,
    events
);


export default combineReducers({
    api,
    // events,
});
