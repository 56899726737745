import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import {SyncLoader} from 'react-spinners';
import Fade from 'react-reveal/Fade';
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS} from "../../../Constants";
import DOMPurify from "dompurify";
import moment from "moment-timezone";
import Moment from "react-moment";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const dependencies = state.api['dependencia-municipal'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        dependencies,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class DependenciesDetail extends Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/dependencia-municipal?include=image,map'));
    }


    //==================================================================================================================
    //== Get props
    //==================================================================================================================
    componentWillReceiveProps(nextProps, nextContext)
    {

    }


    //==================================================================================================================
    //== Render "areas" body
    //==================================================================================================================
    renderDependencia()
    {
        // Get id from params (url) or component (props)
        let dependencia;
        if (this.props.match)
        {
            dependencia = this.props.dependencies.data.find(obj => (obj.id === this.props.match.params.id));
        }
        else
        {
            dependencia = this.props.dependencies.data.find(obj => (obj.id === this.props.id));
        }

        if (!dependencia)
        {
            return (
                <Fade>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <p><FormattedMessage id={'app.ajuntament.dependencies.dependencia.sense-resultats'}/></p>
                        </div>
                    </div>
                </Fade>
            );
        }

        let description = null;
        let imageUrl = null;
        let mapUrl = null;
        let mapLink = null;
        if (dependencia.attributes.description)
        {
            description = DOMPurify.sanitize(dependencia.attributes.description.value, {
                ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
                ADD_ATTR: DOM_PURIFY_ADD_ATTR
            });
        }

        if (dependencia.relationships.image)
        {
            let image = this.props.files.data.find(obj => (obj.id === dependencia.relationships['image']['data']['id']));
            if (image)
            {
                imageUrl = API_APP_URL + image.attributes.uri.url;
                // imageUrl = imageUrl.replace('files/', 'files/styles/section_header/public/');  // TODO: FIX IT! NO HI HA COMPATIBILITAT AMB JSONAPI
            }
        }

        if (dependencia.relationships.map)
        {
            let map = this.props.files.data.find(obj => (obj.id === dependencia.relationships['map']['data']['id']));
            if (map)
            {
                mapUrl = API_APP_URL + map.attributes.uri.url;
                mapLink = `https://www.google.com/maps/place/${dependencia.attributes.location.lat},${dependencia.attributes.location.lon}`;
            }
        }

        return (
            <div>
                <Fade>
                    <div>
                        <h2>{dependencia.attributes.title}</h2>
                    </div>
                    <div className={'mt-3'}>
                        <div className={'mt-2'}>
                            <div className={''}>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6'}>
                                        {(imageUrl) ?
                                            <div>
                                                <img src={imageUrl} className={"img-fluid card-img-top"}/>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className={'col-12 col-md-6 mt-3 mt-md-0'}>
                                        {(mapUrl) ?
                                            <div>
                                                <a href={mapLink} target={'_blank'}>
                                                    <img src={mapUrl} className={"img-fluid card-img-top"}/>
                                                </a>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    {/*<div className={'col-12 col-md-6 mt-3 mt-md-3'}>*/}
                                    <div className={'col-12 mt-3 mt-md-3'}>
                                        <div className={'row'}>
                                            <div>
                                                {(description)
                                                    ?
                                                    <div className={'col-12'}>
                                                        <div className={'html-content'}
                                                             dangerouslySetInnerHTML={{__html: description}}
                                                             style={{textAlign: 'justify'}}/>
                                                    </div>
                                                    : null
                                                }
                                            </div>


                                            <div className={'col-12'}>
                                                <address>{dependencia.attributes.address}</address>
                                                <div>
                                                    {(dependencia.attributes.phone)
                                                        ?
                                                        <span itemProp="telephone">
                                                                        Tel.: <a
                                                            href={'tel:+34' + dependencia.attributes.phone}>+34 {dependencia.attributes.phone}</a>
                                                                    </span>
                                                        : null
                                                    }
                                                </div>
                                                <div>
                                                    {(dependencia.attributes.fax)
                                                        ?
                                                        <span itemProp="telephone">
                                                                        Fax.: <a
                                                            href={'tel:+34' + dependencia.attributes.fax}>+34 {dependencia.attributes.fax}</a>
                                                                    </span>
                                                        : null
                                                    }
                                                </div>
                                                <div>
                                                    {(dependencia.attributes.email)
                                                        ?
                                                        <span itemProp="email">
                                                                        Correu electrònic: <a
                                                            href={'mailto:' + dependencia.attributes.email}>{dependencia.attributes.email}</a>
                                                                    </span>
                                                        : null
                                                    }
                                                </div>
                                                <div className={'mt-2 timetable'}>
                                                    {(dependencia.attributes.office_hours.length > 0)
                                                        ?
                                                        <div className={'mb-1'}>
                                                            <span><strong>Horari d'apertura</strong></span>
                                                        </div>
                                                        : null
                                                    }
                                                    {(dependencia.attributes.office_hours)
                                                        ?
                                                        dependencia.attributes.office_hours.map((obj, key2) =>
                                                        {
                                                            let starthours = obj.starthours.toString();
                                                            let endhours = obj.endhours.toString();
                                                            starthours = (starthours.length === 3) ? '0' + starthours : starthours;
                                                            endhours = (endhours.length === 3) ? '0' + endhours : endhours;

                                                            return (
                                                                <div key={key2}>
                                                                                    <span
                                                                                        className={'days'}><strong>{moment.weekdays(obj.day)}</strong></span>:
                                                                    <Moment format=" HH:mm"
                                                                            parse="HHmm">{starthours}</Moment> - <Moment
                                                                    format="HH:mm"
                                                                    parse="HHmm">{endhours}</Moment>
                                                                </div>
                                                            );
                                                        }) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        );
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        return (
            <div className={'dependencia'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <SyncLoader
                            className={"loader"}
                            sizeUnit={"px"}
                            size={10}
                            color={'#7ED321'}
                            loading={this.props.dependencies.data.length <= 0}
                        />
                        {(this.props.dependencies.data.length > 0) ? this.renderDependencia() : null}
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(DependenciesDetail);