import {
    EVENT_FILTER_DATE_START,
    EVENT_FILTER_DATE_END,
    EVENT_FILTER_VIEW_INACTIVE,
    EVENT_FILTER_SEARCH_TEXT,
    EVENT_ORDER,
    EVENT_PAGINATION_PAGE_SIZE,
    EVENT_PAGINATION_PAGE_NUM
} from "../actions/event";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import * as fuzzy from "fuzzy";


const getFilteredEvents = (events, filters) =>
{
    // console.log(events);
    // console.log(filters);

    if (!events)
    {
        return events;
    }

    let aux = cloneDeep(events);
    if (filters.dateStart)
    {
        let date = moment(filters.dateStart).startOf('day').toDate();
        aux.data = aux.data.filter((event) => moment(event.attributes.date_range.value).startOf('day').toDate() >= date);
    }

    if (filters.dateEnd)
    {
        let date = moment(filters.dateEnd).startOf('day').toDate();
        aux.data = aux.data.filter((event) => moment(event.attributes.date_range.end_value).startOf('day').toDate() <= date);
    }

    if (!filters.showInactive)
    {
        let date = moment().startOf('day').toDate();
        aux.data = aux.data.filter((event) => moment(event.attributes.date_range.end_value).startOf('day').toDate() >= date);
    }

    if (filters.searchText && filters.searchText !== '')
    {
        let matches = fuzzy.filter(filters.searchText, aux.data, {extract: (event) => event.attributes.title});
        aux.data = aux.data.filter((event) =>
        {
            let res = false;
            for (const match of matches)
            {
                if (event.id === match.original.id)
                {
                    res = true;
                    break;
                }
            }
            return res;
        });
    }

    if (filters.orderBy)
    {
        switch (filters.orderBy)
        {
            case 'desc':
                aux.data = aux.data.sort((a, b) =>
                    (new Date(b.attributes.date_range.value)
                        - new Date(a.attributes.date_range.value)));
                break;

            case 'asc':
                aux.data = aux.data.sort((a, b) =>
                    (new Date(a.attributes.date_range.value)
                        - new Date(b.attributes.date_range.value)));
                break;

            default:
                aux.data = aux.data.sort((a, b) =>
                    (new Date(b.attributes.date_range.value)
                        - new Date(a.attributes.date_range.value)));
                break;

        }
    }

    // Pagination
    if (filters.pageSize > 0)
    {
        let pageNum = (!filters.pageNum || filters.pageNum < 0) ? 0 : filters.pageNum;
        let aux2 = [];
        const start = filters.pageSize * pageNum;
        const end = start + filters.pageSize;
        if (aux.data.length >= start)  // Prevent overflow
        {
            for (let j = start; j < end; ++j)
            {
                if (aux.data[j])
                {
                    aux2.push(aux.data[j]);
                }
                else
                {
                    break;
                }
            }
        }
        aux.data = aux2;
    }

    return aux;
};


const events = (state = {}, action) =>
{
    // console.log(state);
    // console.log(state.event);
    // console.log(state.eventFiltered);
    // console.log(action);

    switch (action.type)
    {
        // Filter when available
        case 'API_READ':
        {
            // if (action.payload.endpoint.startsWith('/node/event'))
            if (action.payload.finalEndpoint.includes('/node/event'))
            {
                const filters = state.eventFilters || {};
                return Object.assign({}, state, {
                    eventFilters: filters,
                    eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
                });
            }
            return state;
        }

        case EVENT_FILTER_DATE_START:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {dateStart: action.date});
            return Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
        }

        case EVENT_FILTER_DATE_END:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {dateEnd: action.date});
            return Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
        }

        case EVENT_FILTER_VIEW_INACTIVE:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {showInactive: action.showInactive});
            return Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
        }

        case EVENT_ORDER:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {orderBy: action.order});
            const newState = Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
            return newState;
        }

        case EVENT_FILTER_SEARCH_TEXT:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {searchText: action.searchText});
            return Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
        }

        case EVENT_PAGINATION_PAGE_SIZE:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {pageSize: action.pageSize});
            return Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
        }

        case EVENT_PAGINATION_PAGE_NUM:
        {
            const filters = Object.assign({}, state.eventFilters || {}, {pageNum: action.pageNum});
            return Object.assign({}, state, {
                eventFilters: filters,
                eventFiltered: getFilteredEvents(state.event, filters) || {data: []}
            });
        }

        default:
        {
            return state;
        }
    }
};

export default events