import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";
import {FormattedMessage} from "react-intl";
import {SyncLoader} from "react-spinners";
import * as m from "moment";
import Fade from "react-reveal/Fade";
import filesize from 'filesize';
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {renderFileAttachmentIcon} from "../../../utils";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS} from "../../../Constants";
import DOMPurify from "dompurify";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const poum = state.api['poum'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        poum,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class POUM extends React.Component
{

    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        if (!this.props.poum || this.props.poum.data.length <= 0)
        {
            console.info('Retrieving node \'POUM\'.');
            this.props.dispatch(readEndpoint('/node/poum?include=catalogs_files,gc_files,gq_files,regulations_files'));
        }
    }


    //==================================================================================================================
    //== Link files with poum data
    //==================================================================================================================
    getFiles(data, fileType)
    {
        const {files} = this.props;

        let attached = [];
        if (data.relationships[fileType] &&
            data.relationships[fileType].data &&
            data.relationships[fileType].data.length > 0)
        {
            data.relationships[fileType].data.map(file =>
            {
                if (file.type === 'file' && files && files.data && files.data.length > 0)
                {
                    let obj = files.data.find(aux => (aux.id === file.id));
                    obj.attributes.description = file.meta.description;
                    attached.push(obj);
                }
            });
        }

        return attached;
    }


    //==================================================================================================================
    //== Render section with attached files
    //==================================================================================================================
    renderSectionAttachedFiles(data, description, fileType)
    {
        const files = this.getFiles(data, fileType);
        let body = null;
        if (data.attributes && data.attributes[description] && data.attributes[description].value)
        {
            body = DOMPurify.sanitize(data.attributes[description].value, {
                ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
                ADD_ATTR: DOM_PURIFY_ADD_ATTR
            });
        }

        return (
            <div>

                <div className={'mt-2'}>
                    {(body) ? <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/> : <p>Sense dades.</p>}
                </div>

                <div className={'mt-3'}>

                    <table className={'table mt-4'}>
                        <thead>
                        <tr>
                            <th width="75%">Descripció</th>
                            <th width="10%">Fitxer</th>
                            <th width="15%">Mida</th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.map((file, key) =>
                        {
                            return (
                                <Fade key={key}>
                                    <tr>
                                        <td>
                                            <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                                                {(file.attributes.description) ? file.attributes.description : file.attributes.filename}
                                            </a>
                                        </td>
                                        <td>
                                            <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                                                {renderFileAttachmentIcon(file.attributes.filename)}
                                            </a>
                                        </td>
                                        <td>{filesize(file.attributes.filesize, {exponent: 2})}</td>
                                    </tr>
                                </Fade>
                            );
                        })}
                        </tbody>
                    </table>

                </div>

            </div>
        );
    }


    //==================================================================================================================
    //== Render section with attached liks
    //==================================================================================================================
    renderSectionAttachedLinks(data, description)
    {
        const aux = (data.attributes[description]) ? data.attributes[description].value : 'Sense descripció';
        const body = DOMPurify.sanitize(aux, {ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS, ADD_ATTR: DOM_PURIFY_ADD_ATTR});

        // console.log(data);

        return (
            <div>

                <div className={'mt-2'}>
                    {(body) ? <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/> : <p>Sense dades.</p>}
                </div>

                <div className={'mt-3'}>

                    <table className={'table mt-4'}>
                        <thead>
                        <tr>
                            <th width="100%">Enllaç</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.attributes['other_links'].map((link, key) =>
                        {
                            const url = link.uri.replace(/&amp;/g, "&");  // TODO: OJO CHAPUSA PERQUE EL DRUPAL NO SERVEIX BÉ ELS LINKS
                            return (
                                <Fade key={key}>
                                    <tr>
                                        <td>
                                            <a href={url} target={'_blank'}>{link.title}</a>
                                        </td>
                                    </tr>
                                </Fade>
                            );
                        })}
                        </tbody>
                    </table>

                </div>

            </div>
        );
    }


    //==================================================================================================================
    //== Render component
    //==================================================================================================================
    render()
    {
        const {poum} = this.props;
        return (
            <div>

                <Post data={poum.data[0]}/>

                <div className={'row mt-4'}>
                    <div className={'col-12'}>

                        <SyncLoader
                            className={"loader"}
                            sizeUnit={"px"}
                            size={10}
                            color={'#7ED321'}
                            loading={poum.data.length <= 0}
                        />
                        {(poum.data.length > 0)
                            ? <div>
                                <div>
                                    <h2><FormattedMessage id={'app.ajuntament.poum.normativa.title'}/></h2>
                                    {this.renderSectionAttachedFiles(poum.data[0], 'description_regulations', 'regulations_files')}
                                </div>

                                <div className={'mt-5'}>
                                    <h2><FormattedMessage id={'app.ajuntament.poum.catalegs.title'}/></h2>
                                    {this.renderSectionAttachedFiles(poum.data[0], 'description_catalogs', 'catalogs_files')}
                                </div>

                                <div className={'mt-5'}>
                                    <h2><FormattedMessage id={'app.ajuntament.poum.gc.title'}/></h2>
                                    {this.renderSectionAttachedFiles(poum.data[0], 'description_gc', 'gc_files')}
                                </div>

                                <div className={'mt-5'}>
                                    <h2><FormattedMessage id={'app.ajuntament.poum.gq.title'}/></h2>
                                    {this.renderSectionAttachedFiles(poum.data[0], 'description_gq', 'gq_files')}
                                </div>

                                <div className={'mt-5'}>
                                    <h2><FormattedMessage id={'app.ajuntament.poum.other.title'}/></h2>
                                    {this.renderSectionAttachedLinks(poum.data[0], 'description_other')}
                                </div>
                            </div>
                            : null
                        }

                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(POUM);