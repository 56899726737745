import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {FormattedMessage} from 'react-intl';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import {readEndpoint} from "redux-json-api";
import Fade from "react-reveal/Fade";
import {SyncLoader} from "react-spinners";
import connect from "react-redux/es/connect/connect";
import {API_APP_URL} from "../../../Constants";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const sindic = state.api['sindic-greuges'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        sindic,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Sindic extends Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/sindic-greuges?include=map,photo'));
    }


    //==================================================================================================================
    //== Render sindic de greuges
    //==================================================================================================================
    renderSindic()
    {
        let sindic = null;
        let photo = null;
        let map = null;

        for (let obj of this.props.sindic.data)
        {
            let currentDate = new Date();
            if (moment(currentDate).isAfter(obj.attributes.date_range.value, 'second') && moment(currentDate).isBefore(obj.attributes.date_range.end_value, 'second'))
            {
                sindic = obj;
                break;
            }
        }

        if (!sindic)
        {
            return (
                <div className={'row mt-3'}>
                    <div className={'col-12'}>
                        <p>No hi ha cap síndic definit actualment. Si creu que aquesta informació és erronia, pot contactar amb l'ajuntament.</p>
                    </div>
                </div>
            )
        }

        if (sindic.relationships.photo.data)
        {
            photo = this.props.files.data.find((obj) => (obj.id === sindic.relationships.photo.data.id));
            photo = API_APP_URL + photo.attributes.uri.url;
            photo = photo.replace('files/', 'files/styles/regidor/public/');  // TODO: FIX IT! NO HI HA COMPATIBILITAT AMB JSONAPI
        }

        if (sindic.relationships.map.data)
        {
            map = this.props.files.data.find((obj) => (obj.id === sindic.relationships.map.data.id));
        }

        return (
            <div>
                <Fade>
                    <div className={'row mt-3'}>
                        <div className={'col-12'}>
                            <h2>{sindic.attributes.name}</h2>
                        </div>
                    </div>
                    <div className={'row mt-3'}>
                        <div className={'col-12 col-sm-3'}>
                            <div className={"img"}>
                                <img src={photo} className={"img-fluid"}/>
                            </div>
                        </div>
                        <div className={'col-12 col-sm-9 mt-3 mt-sm-0'}>
                            <div className={'html-content'}
                                 dangerouslySetInnerHTML={{__html: sindic.attributes.bio.value}}
                                 style={{textAlign: 'justify'}}/>
                        </div>
                    </div>
                    <div className={'row mt-2 mt-lg-4'}>
                        <div className={'col-12'}>
                            <h2>Contacte</h2>
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-12'}>
                            <p><strong>Adreça:</strong></p>
                        </div>
                        <div className={'col-12'}>
                            <p>{sindic.attributes.address}</p>
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-12'}>
                            <p><strong>Correu electrònic:</strong></p>
                        </div>
                        <div className={'col-12'}>
                            <p>{sindic.attributes.email}</p>
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-12'}>
                            <p><strong>Telèfon:</strong></p>
                        </div>
                        <div className={'col-12'}>
                            <span itemProp="telephone">
                                <a href={'tel:+34' + sindic.attributes.phone}>+34 {sindic.attributes.phone}</a>
                            </span>
                        </div>
                    </div>
                    {(sindic.attributes.office_hours)
                        ?
                        <div className={'row mt-2'}>
                            <div className={'col-12'}>
                                <p><strong>Horari d'atenció:</strong></p>
                            </div>
                            <div className={'col-12 timetable'}>
                                {sindic.attributes.office_hours.map((obj, key) =>
                                {
                                    let starthours = obj.starthours.toString();
                                    let endhours = obj.endhours.toString();
                                    starthours = (starthours.length === 3) ? '0' + starthours : starthours;
                                    endhours = (endhours.length === 3) ? '0' + endhours : endhours;

                                    return (
                                        <div key={key}>
                                            <span className={'days'}><strong>{moment.weekdays(obj.day)}</strong></span>:
                                            <Moment format=" HH:mm" parse="HHmm">{starthours}</Moment> - <Moment
                                            format="HH:mm"
                                            parse="HHmm">{endhours}</Moment>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        : null
                    }
                </Fade>
            </div>
        )
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        return (
            <div className={'sindic'}>
                <div className={'row'}>
                    <div className={'col-12'}>

                        <Fade>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <h1><FormattedMessage id="app.ajuntament.sindic.title"/></h1>
                                </div>
                            </div>
                        </Fade>

                        <SyncLoader
                            className={"loader"}
                            sizeUnit={"px"}
                            size={10}
                            color={'#7ED321'}
                            loading={this.props.sindic.data.length <= 0}
                        />
                        {(this.props.sindic.data.length > 0) ? this.renderSindic() : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Sindic);