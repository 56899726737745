import React from 'react'
import PropTypes from 'prop-types'
import {SyncLoader} from "react-spinners";
import * as Scroll from "react-scroll/modules";
import DOMPurify from "dompurify";
import {DOM_PURIFY_ALLOWED_TAGS} from "../Constants";
import PageSimpleHeader from "./PageSimpleHeader";
import PageSimpleBody from "./PageSimpleBody";


const renderPage = (data) =>
{
    let title = data.attributes.title;

    // TODO: Unificar tipus de camps
    let text = null;
    if (!data.attributes.body && !data.attributes.description)
    {
        text = '';
    }
    else if (!data.attributes.body)
    {
        text = data.attributes.description.value;
    }
    else
    {
        text = data.attributes.body.value;
    }

    return (
        <div>
            <PageSimpleHeader title={title}/>
            <PageSimpleBody body={text}/>
        </div>
    )
};


const PageSimple = ({data}) =>
{
    if (data)
    {
        Scroll.animateScroll.scrollToTop();
    }

    return (
        <div className="app-home">

            {/* LOADER */}
            <SyncLoader
                className={'loader'}
                sizeUnit={'px'}
                size={10}
                color={'#7ED321'}
                loading={!data}
            />
            {/* END LOADER */}

            {/* RENDER AVIS LEGAL */}
            <div>
                {(data) ? renderPage(data) : null}
            </div>
            {/* END RENDER AVIS LEGAL */}

        </div>
    );
};


PageSimple.propTypes = {
    // title: PropTypes.string.isRequired,
    // body: PropTypes.string.isRequired
};


export default PageSimple
