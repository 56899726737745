import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";
import {FormattedMessage} from "react-intl";
import Fade from "react-reveal/Fade";
import filesize from 'filesize';
import {renderFileAttachmentIcon} from "../../../utils";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS} from "../../../Constants";
import DOMPurify from "dompurify";
import Loader from "../../../components/Loader";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Fullscreen from "react-full-screen";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const otherDocs = state.api['other-docs'] || {data: []};
  const files = state.api['file'] || {data: []};
  const isReading = (state.api.isReading > 0);

  return {
    isReading,
    otherDocs,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class AltresDocuments extends React.Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    this.props.dispatch(readEndpoint('/node/other-docs?include=other_docs'));
  }

  //==================================================================================================================
  //== Link files with poum data
  //==================================================================================================================
  getFiles(data, fileType)
  {
    const {files} = this.props;

    let attached = [];
    if (data.relationships[fileType] &&
      data.relationships[fileType].data &&
      data.relationships[fileType].data.length > 0)
    {
      data.relationships[fileType].data.map(file =>
      {
        if (file.type === 'file' && files && files.data && files.data.length > 0)
        {
          let obj = files.data.find(aux => (aux.id === file.id));
          obj.attributes.description = file.meta.description;
          attached.push(obj);
        }
      });
    }

    return attached;
  }

  //==================================================================================================================
  //== Render section with attached files
  //==================================================================================================================
  renderSectionAttachedFiles(data, fileType)
  {
    const files = this.getFiles(data, fileType);
    return (
      <div>

        {(files.length > 0) ?
          <div className={'mt-3'}>

            <table className={'table mt-4'}>
              <thead>
              <tr>
                <th width="75%">Descripció</th>
                <th width="10%">Fitxer</th>
                <th width="15%">Mida</th>
              </tr>
              </thead>
              <tbody>
              {files.map((file, key) =>
              {
                return (
                  <Fade key={key}>
                    <tr>
                      <td>
                        <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                          {(file.attributes.description) ? file.attributes.description : file.attributes.filename}
                        </a>
                      </td>
                      <td>
                        <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                          {renderFileAttachmentIcon(file.attributes.filename)}
                        </a>
                      </td>
                      <td>{filesize(file.attributes.filesize, {exponent: 2})}</td>
                    </tr>
                  </Fade>
                );
              })}
              </tbody>
            </table>

          </div>
          : null
        }

      </div>
    );
  }

  //==================================================================================================================
  //== Render component
  //==================================================================================================================
  render()
  {
    const {otherDocs, isReading} = this.props;
    return (
      <div>

        <Post data={otherDocs.data[0]} isReading={isReading}/>

        <div className={'row mt-4'}>
          <div className={'col-12'}>

            {(isReading) ? <Loader/>
              :
              (otherDocs.data.length > 0)
                ?
                <div>
                  {(otherDocs.data[0].relationships.other_docs.data.length > 0) ?
                    <div className={'mb-5'}>
                      <div>
                        {/*<h2><FormattedMessage id={'app.ajuntament.altres-documents.title'}/></h2>*/}
                        {this.renderSectionAttachedFiles(otherDocs.data[0], 'other_docs', 'other_docs')}
                      </div>
                    </div> : null
                  }
                </div>
                :
                <div>
                  <p>No hi ha dades disponibles.</p>
                </div>
            }

          </div>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps)(AltresDocuments);