import React from 'react'
import PropTypes from 'prop-types'
import Event from './Event'
import Loader from "./Loader";


const EventList = ({events, isReading}) =>
{
    return (
        <div>
            {(isReading)
                ? <Loader/>
                :
                <div>
                    {(events.length > 0)
                        ?
                        <div className={'mb-3 mb-lg-5'}>
                            {events.map((event) =>
                            {
                                // Event attributes
                                const id = event.id;
                                const title = event.attributes.title;
                                const description = event.attributes.description;
                                const date = event.attributes.date_range.value;
                                const address = event.attributes.address;

                                // Render event
                                return (
                                    <Event
                                        key={id}
                                        id={id}
                                        title={title}
                                        description={description}
                                        date={date}
                                        address={address}
                                    />);
                            })}
                        </div>
                        :
                        <div className={'mt-3 mb-4'}>
                            <p>No hi ha events disponibles.</p>
                        </div>
                    }
                </div>
            }
        </div>
    );
};


EventList.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        attributes: PropTypes.shape({
            title: PropTypes.string.isRequired,
            date_range: PropTypes.shape({
                value: PropTypes.string.isRequired
            }).isRequired,
            address: PropTypes.string.isRequired
        }).isRequired
    }).isRequired).isRequired
};


export default EventList;
