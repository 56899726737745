import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import PageSimple from "../../components/PageSimple";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const avisLegal = state.api['avis-legal'] || {data: []};

    return {
        avisLegal
    }
};


//======================================================================================================================
//== AvisLegal component
//======================================================================================================================
class AvisLegal extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor()
    {
        super();
        this.state = {};
    }


    //==================================================================================================================
    //== Retrieve data
    //==================================================================================================================
    componentWillMount()
    {
        this.props.dispatch(readEndpoint('/node/avis-legal'))
    }


    //==================================================================================================================
    //== Render view
    //==================================================================================================================
    render()
    {
        return (<PageSimple data={this.props.avisLegal.data[0]}/>);
    }
}

export default connect(mapStateToProps)(AvisLegal);