import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import {SyncLoader} from 'react-spinners';
import Fade from 'react-reveal/Fade';
import Post from "../../../components/Post";
import {Link} from "react-router-dom";
import Loader from "../../../components/Loader";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const isReading = (state.api.isReading > 0);
    const arees = state.api['area'] || {data: []};
    const unitats = state.api['unitat-organitzativa'] || {data: []};

    return {
        isReading,
        arees,
        unitats
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Area extends Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/unitat-organitzativa'));
        this.props.dispatch(readEndpoint('/node/area'));  // Es fa aixi perque si s'inclou des d'OU nomes es carreguen les àrees que tenen OU.
    }


    //==================================================================================================================
    //== Get props
    //==================================================================================================================
    componentWillReceiveProps(nextProps, nextContext)
    {

    }


    //==================================================================================================================
    //== Render "area"
    //==================================================================================================================
    renderArea()
    {
        const {arees, unitats} = this.props;
        let area = arees.data.find((obj) => (obj.id === this.props.match.params.id));

        if (!area)
        {
            return (
                <Fade>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <p><FormattedMessage id={'app.ajuntament.organitzacio-interna.area.sense-resultats'}/></p>
                        </div>
                    </div>
                </Fade>
            );
        }

        let unitatsAux = unitats.data.filter((obj) => (obj.relationships.area.data.id === area.id));
        unitatsAux = unitatsAux.sort((a, b) =>
        {
            let a1 = a.attributes.title.toLowerCase();
            let b1 = b.attributes.title.toLowerCase();
            return (a1 < b1) ? -1 : (a1 > b1) ? 1 : 0;
        });

        return (
            <div>

                <Fade>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1>{area.attributes.title}</h1>
                        </div>
                    </div>
                </Fade>

                <Fade>
                    <div className={'row mt-3'}>
                        <div className={'col-12'}>
                            <div className={'html-content mt-2'}>
                                <p dangerouslySetInnerHTML={{__html: area.attributes.description.value}}
                                   style={{textAlign: 'justify'}}/>
                            </div>
                        </div>
                    </div>
                </Fade>

                {(unitatsAux.length > 0)
                    ?
                    <Fade>
                        <div className={'row mt-3'}>
                            <div className={'col-12'}>
                                <strong><FormattedMessage
                                    id={'app.ajuntament.organitzacio-interna.area.unitats'}/></strong>
                            </div>
                        </div>
                        <div className={'row mt-2'}>
                            <div className={'col-12'}>
                                <div className={'html-content mt-2'}>
                                    {unitatsAux.map((unitat, key) =>
                                    {
                                        return (
                                            <ul key={key}><Link
                                                to={'/ajuntament/arees/unitat/' + unitat.id}>{unitat.attributes.title}</Link>
                                            </ul>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </Fade>
                    : null
                }
            </div>
        );
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {isReading} = this.props;

        return (
            <div className={'arees'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        {(isReading) ? <Loader/> : this.renderArea()}
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Area);