import React from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import DOMPurify from "dompurify";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS} from "../Constants";
import ReactPlayer from "react-player";
import {renderAttachedFiles} from "../utils";


//======================================================================================================================
//== Extract linked media from 'props'
//======================================================================================================================
export const getAttachments = (props, noticia) =>
{
  // Media types
  let images = [];
  let audios = [];
  let videos = [];
  let files = [];

  noticia.relationships['media'].data.map(media =>
  {
    // Get all associated images
    if (media.type === 'image' && props.images && props.images.data && props.images.data.length > 0)
    {
      let image = props.images.data.find(image => (media.id === image.id));
      let file = props.files.data.find(file => (file.id === image.relationships['media_image'].data.id));
      images.push(file);
    }
    else if (media.type === 'media--audio' && props.audios && props.audios.data && props.audios.data.length > 0)
    {
      let audio = props.audios.data.find(audio => (media.id === audio.id));
      let file = props.files.data.find(file => (file.id === audio.relationships['field_media_audio_file'].data.id));
      audios.push(file);
    }
    else if (media.type === 'media--video' && props.videos && props.videos.data && props.videos.data.length > 0)
    {
      let video = props.videos.data.find(video => (media.id === video.id));
      let file = props.files.data.find(file => (file.id === video.relationships['field_media_video_file'].data.id));
      videos.push(file);
    }
  });

  noticia.relationships['files'].data.map(file =>
  {
    if (file.type === 'file' && props.files && props.files.data && props.files.data.length > 0)
    {
      let obj = props.files.data.find(aux => (aux.id === file.id));
      obj.attributes.description = file.meta.description;
      files.push(obj);
    }
  });

  // Return object
  return {
    images: images,
    audios: audios,
    videos: videos,
    files: files
  };
};


//======================================================================================================================
//== Render first media of 'noticia' by order (image -> video -> audio)
//======================================================================================================================
const renderMainMedia = (attached, noticia, display) =>
{
  // If we have images, render first
  if (attached.images.length > 0)
  {
    return (
      <div>
        {(display === 'summary') ?
          <div className={"image link"}>
            <Link to={"/municipi/noticies/" + noticia.id}>
              <img src={API_APP_URL + attached.images[0].attributes.uri.url}/>
              <div className="middle">
                <div className="text">
                  Llegir més
                </div>
              </div>
            </Link>
          </div> : null
        }

        {(display === 'full') ?
          <div className={'image'}>
            <img src={API_APP_URL + attached.images[0].attributes.uri.url}/>
          </div> : null
        }

        {(display === 'card') ?
          <img className={'card-img-top'} src={API_APP_URL + attached.images[0].attributes.uri.url}/>
          : null
        }
      </div>
    );
  }

  // If we have videos, render it
  else if (attached.videos.length > 0)
  {
    return (
      <ReactPlayer
        url={API_APP_URL + attached.videos[0].attributes.uri.url}
        className='react-player'
        playing={false}
        controls={true}
        width='100%'
      />
    );
  }

  // If we have audios, render it
  else if (attached.audios.length > 0)
  {
    return (
      <ReactPlayer
        url={API_APP_URL + attached.audios[0].attributes.uri.url}
        className='react-player'
        playing={false}
        controls={true}
        width='100%'
        height='55px'
      />
    );
  }
};


//======================================================================================================================
//== Render noticia
//======================================================================================================================
const Servei = ({servei, media, display}) =>
{
  const attached = getAttachments(media, servei);
  const body = DOMPurify.sanitize(servei.attributes['description']['value'], {
      ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
      ADD_ATTR: DOM_PURIFY_ADD_ATTR
    });

  return (
    <React.Fragment>
        <div className={'row noticia px-3 px-lg-0'}>
          <div className={'w-100'}>

            <div className={'col-12'}>

              {renderAttachedFiles(attached)}

              <div className={'row mt-2'}>
                <div className={'col-12'}>
                  <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/>
                </div>
              </div>

            </div>

          </div>
        </div>
    </React.Fragment>
  );
};


Servei.propTypes = {
  servei: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired
};


export default Servei