import React from 'react';
import {FormattedMessage} from 'react-intl';
import Fade from 'react-reveal/Fade';
import {connect} from "react-redux";
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import {
    filterDateEnd,
    filterDateStart,
    filterShowInactive,
    filterSearchText,
    orderBy,
    filterSetPageSize, filterSetPageNum
} from "../../../actions/event";
import moment from "moment";
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {readEndpoint} from "redux-json-api";
import EventList from "../../../components/EventList";

// import ca from 'date-fns/locale/ca';
// registerLocale('ca', ca);
// setDefaultLocale('ca');

import es from 'date-fns/locale/es';
import {API_APP_URL} from "../../../Constants";

registerLocale('es', es);
setDefaultLocale('es');

const localizer = BigCalendar.momentLocalizer(moment);


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const isReading = (state.api.isReading > 0);
    let events = state.api['eventFiltered'] || {data: []};

    return {
        isReading,
        events
    };
};


//======================================================================================================================
//== Agenda component
//======================================================================================================================
class Agenda extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Called before first render
    //==================================================================================================================
    componentWillMount()
    {
        //==============================================================================================================
        //== Scroll to top on init
        //==============================================================================================================
        window.scrollTo(0, 0);

        //==============================================================================================================
        //== Setup events widget
        //==============================================================================================================
        this.handleClearParams();
    }


    //==================================================================================================================
    //== Called after first render
    //==================================================================================================================
    componentDidMount()
    {
        const {dispatch} = this.props;
        const getPagedEvents = (link) =>
        {
            dispatch(readEndpoint(link))
                .then(r =>
                {
                    if (r.body.links && r.body.links.next)
                    {
                        getPagedEvents(r.body.links.next.href.replace('http', 'https'));
                    }
                });
        };
        getPagedEvents('/node/event?include=image,map,files');
    }


    //==================================================================================================================
    //== Handle 'onChange' for date start param
    //==================================================================================================================
    handleDateStart(date)
    {
        this.setState({eventDateStart: date}, () => this.props.dispatch(filterDateStart(date)));
    }


    //==================================================================================================================
    //== Handle 'onChange' for date end param
    //==================================================================================================================
    handleDateEnd(date)
    {
        this.setState({eventDateEnd: date}, () => this.props.dispatch(filterDateEnd(date)));
    }


    //==================================================================================================================
    //== Handle toggle for inactive events
    //==================================================================================================================
    handleToggleShowInactive()
    {
        this.setState((prevState) => ({eventShowInactive: !prevState.eventShowInactive}), () =>
        {
            this.props.dispatch(filterShowInactive(this.state.eventShowInactive))
        });

    }


    //==================================================================================================================
    //== Handle 'onChange' for search text param
    //==================================================================================================================
    handleSearchText(event)
    {
        const value = event.target.value;
        this.setState({eventSearchText: value}, () => this.props.dispatch(filterSearchText(value)));
    }


    //==================================================================================================================
    //== Handle event widget params clear
    //==================================================================================================================
    handleClearParams()
    {
        let minDate = moment().subtract(1, 'year').toDate();
        let maxDate = moment().add(1, 'year').toDate();
        this.handleDateStart(minDate);
        this.handleDateEnd(maxDate);
        this.setState({eventPageSize: 0}, () => this.props.dispatch(filterSetPageSize(0)));  // Disable pagination
        this.setState({eventShowInactive: false}, () => this.props.dispatch(filterShowInactive(false)));
        this.setState({eventOrder: 'asc'}, () => this.props.dispatch(orderBy('asc')));
        this.setState({eventSearchText: ''}, () => this.props.dispatch(filterSearchText('')));
    }


    //==================================================================================================================
    //== Handle calendar event selection
    //==================================================================================================================
    handleCalendarEventClick(event)
    {
        this.props.history.push('/municipi/agenda/' + event.id);
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {events, isReading} = this.props;

        let calEvents = [];
        for (let event of events.data)
        {
            calEvents.push({
                id: event.id,
                title: event.attributes.title,
                allDay: false,
                start: new Date(event.attributes.date_range.value),
                end: new Date(event.attributes.date_range.end_value),
            });
        }

        const calMessages = {
            allDay: 'Tot el dia',
            previous: 'Anterior',
            next: 'Següent',
            today: 'Avui',
            month: 'mes',
            week: 'setmana',
            day: 'dia',
            agenda: 'Agenda',
            date: 'data',
            time: 'hora',
            event: 'event',
            showMore: total => `+ ${total} events(s)`
        };

        return (
            <div>
                <Fade>
                    <h1><FormattedMessage id={'app.municipi.agenda.title'}/></h1>
                </Fade>

                {/* RENDER CONTROLS */}
                <div>
                    <div className={'row py-3'}>
                        <div className={'col-lg-6'}>
                            <div className={'controls px-3 py-3'}>
                                <div className={'pb-3'}>
                                    <h5>FILTRAR EVENTS</h5>
                                </div>

                                <div className={'row'}>
                                    <div className={'col-lg-12'}>
                                        <div className="form-group">
                                            <label htmlFor="inputTitle" className={'pl-1'}>Cerca per títol</label>
                                            <input id={'inputTitle'}
                                                   type={'text'}
                                                   className={'form-control form-control-sm'}
                                                   placeholder={'Filtrar el títol de l\'event'}
                                                   value={this.state.eventSearchText}
                                                   autoComplete={'off'}
                                                   onChange={(searchText) => this.handleSearchText(searchText)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'row'}>

                                    {/*<div className={'col-lg-4'}>*/}
                                    {/*<div className="form-group">*/}
                                    {/*<label htmlFor="selectArea" className={'pl-1'}>Àrea</label>*/}
                                    {/*<select className="form-control form-control-sm" id="selectArea"*/}
                                    {/*disabled={true}>*/}
                                    {/*<option>Totes</option>*/}
                                    {/*<option>Turisme</option>*/}
                                    {/*</select>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}

                                    <div className={'col-lg-6'}>
                                        <div className="form-group">
                                            <label htmlFor="inputStartDate" className={'pl-1'}>Data inici</label>
                                            <DatePicker id="inputStartDate"
                                                // locale='ca'
                                                        autoComplete={'off'}
                                                        className="form-control form-control-sm"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={this.state.eventDateStart}
                                                        onChange={(date) => this.handleDateStart(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode={'select'}
                                                        todayButton={"Avui"}
                                                        popperModifiers={{
                                                            preventOverflow: {
                                                                enabled: true,
                                                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                                                boundariesElement: 'viewport'
                                                            }
                                                        }}/>
                                        </div>
                                    </div>

                                    <div className={'col-lg-6'}>
                                        <div className="form-group">
                                            <label htmlFor="inputFinalDate" className={'pl-1'}>Data final</label>
                                            <DatePicker id="inputFinalDate"
                                                // locale='ca'
                                                        autoComplete={'off'}
                                                        className="form-control form-control-sm"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={this.state.eventDateEnd}
                                                        onChange={(date) => this.handleDateEnd(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode={'select'}
                                                        todayButton={"Avui"}/>
                                        </div>
                                    </div>

                                </div>
                                <div className={'row pt-2'}>

                                    <div className={'col-lg-6'}>
                                        <div className={'pb-3 pb-lg-0 pl-2'}>
                                            <div className="form-check">
                                                <input type={"checkbox"}
                                                       className={'form-check-input'}
                                                       checked={this.state.eventShowInactive}
                                                       onChange={() => this.handleToggleShowInactive()}/>
                                                <label className="form-check-label" htmlFor="defaultCheck1">
                                                    Mostrar events passats
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'col-lg-6'}>
                                        <div className="form-group">
                                            <button type={'button'}
                                                    className={'btn btn-warning btn-sm btn-block'}
                                                    style={{color: 'white'}}
                                                    onClick={() => this.handleClearParams()}>Netejar filtres
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-6 mt-5 mt-lg-0'}>
                            <BigCalendar
                                events={calEvents}
                                toolbar={true}
                                views={['month']}
                                localizer={localizer}
                                messages={calMessages}
                                showMultiDayTimes
                                onSelectEvent={(event) => this.handleCalendarEventClick(event)}
                            />
                        </div>
                    </div>
                </div>

                <EventList events={events.data || []} isReading={isReading}/>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Agenda);