import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import Fade from 'react-reveal/Fade';
import Post from "../../../components/Post";
import {Link} from "react-router-dom";
import Loader from "../../../components/Loader";
import ReactSVG from 'react-svg'


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const isReading = (state.api.isReading > 0);
  const oi = state.api['organitzacio-interna'] || {data: []};
  const arees = state.api['area'] || {data: []};
  const unitats = state.api['unitat-organitzativa'] || {data: []};
  const files = state.api['file'] || {data: []};

  return {
    isReading,
    oi,
    arees,
    unitats,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class OrganitzacioInterna extends Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {};
  }


  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    this.props.dispatch(readEndpoint('/node/organitzacio-interna?include=image'));
    this.props.dispatch(readEndpoint('/node/unitat-organitzativa'));
    this.props.dispatch(readEndpoint('/node/area'));  // Es fa aixi perque si s'inclou des d'OU nomes es carreguen les àrees que tenen OU.
  }


  //==================================================================================================================
  //== Render "areas" and "unitats" description
  //==================================================================================================================
  renderDescription()
  {
    let oi = this.props.oi.data[0];
    return (
      <div>
        {/*<Fade>*/}
        {/*<div className={'row mt-3'}>*/}
        {/*<div className={'col-12'}>*/}
        {/*{(oi.attributes.description)*/}
        {/*?*/}
        {/*<div className={'html-content mt-2'}>*/}
        {/*<p dangerouslySetInnerHTML={{__html: oi.attributes.description.value}}*/}
        {/*style={{textAlign: 'justify'}}/>*/}
        {/*</div>*/}
        {/*: null*/}
        {/*}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</Fade>*/}
      </div>
    );
  }


  //==================================================================================================================
  //== Render "areas" body
  //==================================================================================================================
  renderAreas()
  {
    const {arees, unitats} = this.props;

    const areesSorted = arees.data.sort((a, b) =>
    {
      let a1 = a.attributes.title.toLowerCase();
      let b1 = b.attributes.title.toLowerCase();
      return (a1 < b1) ? 1 : (a1 > b1) ? -1 : 0;
    });

    return (
      <div className={'mt-3'}>

        {/*<div>*/}
          {/*<object type="image/svg+xml" data="https://api.calonge.cat/sites/default/files/2019-10/organitzacio-interna.svg">*/}
            {/*El seu navegador no soporta imatges SVG.*/}
          {/*</object>*/}
        {/*</div>*/}

        <div className={'mt-3'}>
          <FormattedMessage id={'app.ajuntament.organitzacio-interna.arees.descripcio'}/>
        </div>
        <div className={'row mt-4'}>
          {areesSorted.map((area, key) =>
          {
            // let us = unitats.data.filter((obj) => (obj.relationships.area.data.id === area.id));
            // unitsSorted = us.sort((a, b) =>
            // {
            //     let a1 = a.attributes.title.toLowerCase();
            //     let b1 = b.attributes.title.toLowerCase();
            //     return (a1 < b1) ? -1 : (a1 > b1) ? 1 : 0;
            // });

            return (
              <Fade key={key}>
                <div className={'col-12 col-sm-6 col-md-4 mb-4'}>

                  <Link to={'/ajuntament/arees/area/' + area.id}>
                    <div className={'card'}>
                      <img className={'card-img-top'}
                           src={'https://via.placeholder.com/300x200?text=Sense foto'}
                           alt={area.attributes.title}/>
                      <div className={'card-body'}>
                        <p className={'card-title'}>{area.attributes.title}</p>
                        {/*<p className="card-text">Blablabla</p>*/}
                        {/*<div className={'float-right'}>*/}
                        {/*<a href="#" className="btn btn-sm btn-secondary mt-2">Anar-hi</a>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </Link>

                </div>
              </Fade>
            );
          })}
        </div>

        <h1 className={'mt-3'}><FormattedMessage id={'app.ajuntament.arees.unitats.title'}/></h1>

        <div className={'row mt-4'}>
          {unitats.data.map((unitat, key) =>
          {
            return (
              <Fade key={key}>
                <div className={'col-12 col-sm-6 col-md-4 mb-4'}>

                  <Link to={'/ajuntament/arees/unitat/' + unitat.id}>
                    <div className={'card'}>
                      <img className={'card-img-top'}
                           src={'https://via.placeholder.com/300x200?text=Sense foto'}
                           alt={unitat.attributes.title}/>
                      <div className={'card-body'}>
                        <p className={'card-title'}>{unitat.attributes.title}</p>
                      </div>
                    </div>
                  </Link>

                </div>
              </Fade>
            );
          })}
        </div>

      </div>
    );
  }


  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render()
  {
    const {isReading} = this.props;

    // console.log(isReading);
    // console.log(arees);
    // console.log(oi);

    return (
      <div className={'arees'}>

        {/*<Post data={this.props.oi.data[0]} files={this.props.files.data}/>*/}

        <div className={'row'}>
          <div className={'col-12'}>
            <h1><FormattedMessage id={'app.ajuntament.arees.arees.title'}/></h1>
            {(isReading) ? <Loader/> : this.renderAreas()}
          </div>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps)(OrganitzacioInterna);