import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import Fade from 'react-reveal/Fade';
import Post from "../../../components/Post";
import {Link} from "react-router-dom";
import Loader from "../../../components/Loader";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const isReading = (state.api.isReading > 0);
    const services = state.api['service'] || {data: []};

    return {
        isReading,
        services
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Serveis extends Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/service'));
    }


    //==================================================================================================================
    //== Render "service" body
    //==================================================================================================================
    renderServices()
    {
        const {services} = this.props;

        let servicesShow = services.data.filter(obj => obj.attributes['is_main']);

        servicesShow = servicesShow.sort((a, b) =>
        {
            let a1 = a.attributes.title.toLowerCase();
            let b1 = b.attributes.title.toLowerCase();
            return (a1 > b1) ? 1 : (a1 < b1) ? -1 : 0;
        });

        return (
            <div className={'mt-3'}>
                <div>
                    <FormattedMessage id={'app.ajuntament.serveis.descripcio'}/>
                </div>
                <div className={'row mt-4'}>
                    {servicesShow.map((servei, key) =>
                    {
                        return (
                            <Fade key={key}>
                                <div className={'col-12 col-sm-6 col-md-4 mb-4'}>

                                    <Link to={'/ajuntament/serveis/' + servei.attributes['f_url']}>
                                        <div className={'card'}>
                                            <img className={'card-img-top'}
                                                 src={'https://via.placeholder.com/300x200?text=Sense foto'}
                                                 alt={servei.attributes['title']}/>
                                            <div className={'card-body'}>
                                                <p className={'card-title'}>{servei.attributes['title']}</p>
                                            </div>
                                        </div>
                                    </Link>

                                </div>
                            </Fade>
                        );
                    })}
                </div>

            </div>
        );
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {isReading} = this.props;

        return (
            <div className={'serveis'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1><FormattedMessage id={'app.ajuntament.serveis.title'}/></h1>
                        {(isReading) ? <Loader/> : this.renderServices()}
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Serveis);