import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { API_APP_URL, API_SEU_E_ACTES_PLE } from "../../../Constants";
import { readEndpoint } from "redux-json-api";
import { SyncLoader } from "react-spinners";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Regidor from "../../../components/Regidor";

//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) => {
  const equipGovern = state.api["equip-govern"] || { data: [] };
  const regidors = state.api["regidor"] || { data: [] };
  const areas = state.api["area"] || { data: [] };
  const ou = state.api["unitat-organitzativa"] || { data: [] };
  const files = state.api["file"] || { data: [] };
  const grups = state.api["grup-municipal"] || { data: [] };

  console.log(regidors);

  return {
    equipGovern,
    regidors,
    areas,
    ou,
    files,
    grups,
  };
};

//======================================================================================================================
//==
//======================================================================================================================
class GrupsMunicipals extends Component {
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props) {
    super(props);
    this.state = {
      allLoaded: false,
    };
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount(state) {
    this.props.dispatch(readEndpoint("/node/equip-govern"));
    this.props.dispatch(
      readEndpoint(
        "/node/regidor?filter[status]=1&filter[is_equip_govern]=1&sort=order&include=areas,photo,grup,ou"
      )
    );
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.regidors.data.length > 0) {
      this.setState({
        allLoaded: true,
      });
    }
  }

  //==================================================================================================================
  //== Render description
  //==================================================================================================================
  renderDescription(equipGovern) {
    return (
      <div>
        <Fade>
          <div className={"row mt-3"}>
            <div className={"col-12"}>
              <div className={"html-content mt-2"}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: equipGovern.data[0].attributes.description.value,
                  }}
                  style={{ textAlign: "justify" }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }

  //==================================================================================================================
  //== Render "regidors" body
  //==================================================================================================================
  renderRegidors() {
    const { regidors, areas, ou, files, grups } = this.props;

    // Ordenem els regidors segons els grups municipals al que pertanyen (els ergidor ja venen ordenats).
    const sortedGrups = grups.data.sort(
      (a, b) => a.attributes.order - b.attributes.order
    );

    let sortedRegidors = [];
    for (const g of sortedGrups) {
      sortedRegidors = [
        ...sortedRegidors,
        ...regidors.data.filter(
          (r) =>
            r.relationships.grup.data?.id === g.id &&
            r.attributes.is_equip_govern
        ),
      ];
    }

    return (
      <div className={"mt-3"}>
        {sortedRegidors.map((regidor) => (
          <Regidor
            key={regidor.id}
            regidor={regidor}
            grups={grups}
            arees={areas}
            unitats={ou}
            files={files}
          />
        ))}
      </div>
    );
  }

  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render() {
    const { equipGovern, regidors, isReading } = this.props;
    const { allLoaded } = this.state;

    console.log(equipGovern);

    return (
      <div className={"ple"}>
        <div className={"row"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage id={"app.ajuntament.equip-govern.title"} />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={equipGovern.data.length <= 0}
            />
            {equipGovern.data.length > 0
              ? this.renderDescription(equipGovern)
              : null}
          </div>
        </div>

        <div className={"row mt-4"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage
                id={"app.ajuntament.equip-govern.regidors.title"}
              />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={!allLoaded}
            />
            {allLoaded ? this.renderRegidors() : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(GrupsMunicipals);
