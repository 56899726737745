import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import {SyncLoader} from 'react-spinners';
import Fade from 'react-reveal/Fade';
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS} from "../../../Constants";
import DOMPurify from "dompurify";
import Moment from "react-moment";
import {renderAttachedFiles} from "../../../utils";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const events = state.api['event'] || {data: []};
  const files = state.api['file'] || {data: []};

  return {
    events,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class AgendaDetail extends Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {};
  }


  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    this.props.dispatch(readEndpoint('https://api.calonge.cat/jsonapi/node/event?filter[datefilter2][condition][path]=id&filter[datefilter2][condition][operator]=%3D&filter[datefilter2][condition][value]=' + this.props.match.params.id + '&include=image,map,files'));
  }


  //==================================================================================================================
  //== Get props
  //==================================================================================================================
  componentWillReceiveProps(nextProps, nextContext)
  {

  }


  //==================================================================================================================
  //== Render "areas" body
  //==================================================================================================================
  renderEvent()
  {
    const {files} = this.props;

    let event = this.props.events.data.find(obj => (obj.id === this.props.match.params.id));
    let description = null;
    let imageUrl = null;
    let mapUrl = null;
    let mapLink = null;
    let attached = {files: []};

    if (!event)
    {
      return (
        <Fade>
          <div className={'row'}>
            <div className={'col-12'}>
              <p><FormattedMessage id={'app.municipi.agenda.event.sense-resultats'}/></p>
            </div>
          </div>
        </Fade>
      );
    }

    if (event.attributes.description)
    {
      description = DOMPurify.sanitize(event.attributes.description.value, {
        ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
        ADD_ATTR: DOM_PURIFY_ADD_ATTR
      });
    }

    if (event.relationships.image && event.relationships.image.data)
    {
      let image = files.data.find(obj => (obj.id === event.relationships['image']['data']['id']));
      if (image)
      {
        imageUrl = API_APP_URL + image.attributes.uri.url;
      }
    }

    if (event.relationships.map)
    {
      // console.log(event.relationships.map);
      let map = files.data.find(obj => (obj.id === event.relationships['map']['data']['id']));
      // console.log(map);
      if (map)
      {
        mapUrl = API_APP_URL + map.attributes.uri.url;
        mapLink = `https://www.google.com/maps/place/${event.attributes.location.lat},${event.attributes.location.lon}`;
      }
    }

    if (event.relationships.files && event.relationships.files.data)
    {
      event.relationships['files'].data.map(file =>
      {
        if (file.type === 'file' && files && files.data && files.data.length > 0)
        {
          let obj = files.data.find(aux => (aux.id === file.id));
          if (obj)
          {
            obj.attributes.description = file.meta.description;
            attached.files.push(obj);
          }
        }
      });
    }

    return (
      <div>
        <Fade>
          <div>
            <h2>{event.attributes.title}</h2>
          </div>
          <div className={'mt-3'}>
            <div className={'mt-2 mb-4'}>
              <div className={''}>
                <div className={'row'}>
                  <div className={'col-12'}>
                    {(imageUrl) ?
                      <div className={'mb-3'}>
                        <img src={imageUrl} className={"img-fluid"} style={{width: '100%'}}/>
                      </div>
                      : null
                    }
                  </div>
                  <div className={'col-12 mb-3'}>
                    <div className={'row'}>
                      <div className={'col-12'}>

                        {renderAttachedFiles(attached)}

                        <div>
                          <div>
                            <strong>Hora d'inici: </strong>
                          </div>
                          <div>
                            <Moment date={event.attributes.date_range.value}
                                    format={'dddd DD MMMM '}/>de
                            <Moment date={event.attributes.date_range.value}
                                    format={' YYYY '}/>a les
                            <Moment date={event.attributes.date_range.value}
                                    format={' HH:mm'}/>
                          </div>
                        </div>

                        <div>
                          {(event.attributes.date_range.value !== event.attributes.date_range.end_value)
                            ? <div className={'mt-2'}>
                              <div>
                                <strong>Hora final:</strong>
                              </div>
                              <div>
                                <Moment date={event.attributes.date_range.end_value}
                                        format={'dddd DD MMMM '}/>de
                                <Moment date={event.attributes.date_range.end_value}
                                        format={' YYYY '}/>a les
                                <Moment date={event.attributes.date_range.end_value}
                                        format={' HH:mm'}/>
                              </div>
                            </div>
                            : null
                          }
                        </div>

                        <div>
                          {(description)
                            ?
                            <div className={'mt-2'}>
                              <div>
                                <strong>Descripció:</strong>
                              </div>
                              <div className={'html-content'}
                                   dangerouslySetInnerHTML={{__html: description}}
                                   style={{textAlign: 'justify'}}/>
                            </div>
                            : null
                          }
                        </div>

                        <div className={'mt-2'}>
                          <strong>Adreça: </strong>
                          <address>{event.attributes.address}</address>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'col-12 mt-3 mt-md-0'}>
                    {(mapUrl) ?
                      <div>
                        <a href={mapLink} target={'_blank'}>
                          <img src={mapUrl} className={'img-fluid card-img-top'}/>
                        </a>
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }


  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render()
  {
    return (
      <div className={'dependencia'}>

        <div className={'row'}>
          <div className={'col-12'}>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={'#7ED321'}
              loading={this.props.events.data.length <= 0}
            />
            {(this.props.events.data.length > 0) ? this.renderEvent() : null}
          </div>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps)(AgendaDetail);