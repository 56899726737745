import React from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import Fade from "react-reveal/Fade";
import {FormattedMessage} from "react-intl";
import {Link} from 'react-router-dom';


function renderAnunciStatus(anunci)
{
    switch (anunci._status)
    {
        case 'TERMINI_TANCAT':
            return (
                <span className="badge badge-warning">
                    <FormattedMessage id={'app.ajuntament.oferta-publica.status.termini-tancat'}/>
                </span>
            );
        case 'NO_VIGENT':
            return (
                <span className="badge badge-danger">
                    <FormattedMessage id={'app.ajuntament.oferta-publica.status.no-vigent'}/>
                </span>
            );
        case 'PENDENT_CONVOCATORIA':  // Nomes oferta pública
            return (
                <span className="badge badge-info">
                    <FormattedMessage id={'app.ajuntament.oferta-publica.status.pendent-convocatoria'}/>
                </span>
            );
        case 'TERMINI_OBERT':  // Nomes oferta pública
            return (
                <span className="badge badge-success">
                    <FormattedMessage id={'app.ajuntament.oferta-publica.status.termini-obert'}/>
                </span>
            );
        case 'RESOLT':  // Contractacio únicament
            return (
                <span className="badge badge-info">
                    <FormattedMessage id={'app.ajuntament.oferta-publica.status.resolt'}/>
                </span>
            );
        default:
            return (
                <span className="badge badge-success">
                    <FormattedMessage id={'app.ajuntament.oferta-publica.status.no-informat'}/>
                </span>
            );
    }
}


const OfertaOcupacioList = ({anuncis}) =>
{
    // console.log(anuncis);

    // Sort
    anuncis = anuncis.sort((a, b) => (a._datePub.isAfter(b._datePub)) ? -1 : 1);

    return (
        <div className={'row mt-4'}>
            <div className={'col-12'}>

                <table className={'table'}>
                    <thead className={''}>
                    <tr>
                        <th width={'50%'}>Títol</th>
                        <th width={'30%'}>Terminis</th>
                        {/*
                        <th width={'15%'}>Data publicació</th>
                        <th width={'15%'}>Termini presentació</th>
                        */}
                        <th width={'15%'}>Estat</th>
                        <th width={'10%'}>Origen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {anuncis.map((anunci, key) =>
                    {
                        return (
                            <Fade key={key}>
                                <tr>
                                    <td><a href={anunci._link} target={'_blank'}>{anunci._title}</a></td>
                                    <td><Link to={'/ajuntament/tauler'}>Tauler d'anuncis</Link></td>
                                    {/*
                                    <td>
                                        {!anunci._datePub || !anunci._datePub.isValid()
                                            ? <Link to={'/ajuntament/tauler'}>Tauler d'anuncis</Link>
                                            : <Moment date={anunci._datePub} format={'DD-MM-YYYY'}/>
                                        }
                                    </td>
                                    <td>
                                        {!anunci._dateEnd
                                            ? <span>Sense data</span>
                                            //: !anunci._dateEnd.isValid() || (anunci._dateEnd.isValid() && anunci._dateEnd.isBefore(anunci._datePub.add('20', 'days')))
                                            : !anunci._dateEnd.isValid()
                                                ? <Link to={'/ajuntament/tauler'}>Tauler d'anuncis</Link>
                                                : <Moment date={anunci._dateEnd} format={'DD-MM-YYYY'}/>
                                        }
                                    </td>
                                    */}
                                    <td>{renderAnunciStatus(anunci)}</td>
                                    <td>
                                        {(anunci._origin.id === 'ajuntament')
                                            ? <a href={anunci._link} target={'_blank'}>
                                                <span className="badge badge-info">{anunci._origin.name}</span>
                                            </a>
                                            : (anunci._origin.id === 'opendata')
                                                ? <a href={anunci._link} target={'_blank'}>
                                                    <span className="badge badge-success">{anunci._origin.name}</span>
                                                </a>
                                                : <a href={anunci._link} target={'_blank'}>
                                                    <span className="badge badge-light">{anunci._origin.name}</span>
                                                </a>
                                        }
                                    </td>
                                </tr>
                            </Fade>
                        );
                    })}
                    </tbody>
                </table>

            </div>
        </div>
    );
};

OfertaOcupacioList.propTypes = {
    anuncis: PropTypes.array.isRequired
};

export default OfertaOcupacioList