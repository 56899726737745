import React, { Component } from "react";
import { connect } from "react-redux";
import * as m from "moment";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";
import { API_APP_URL, API_SEU_E_ACTES_PLE } from "../../../Constants";
import { readEndpoint } from "redux-json-api";
import { SyncLoader } from "react-spinners";
import Fade from "react-reveal/Fade";
import * as Scroll from "react-scroll";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Regidor from "../../../components/Regidor";
import Loader from "../../../components/Loader";

const ScrollLink = Scroll.Link;
const ScrollElement = Scroll.Element;

//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) => {
  const ple = state.api["ple"] || { data: [] };
  const regidors = state.api["regidor"] || { data: [] };
  const areas = state.api["area"] || { data: [] };
  const ou = state.api["unitat-organitzativa"] || { data: [] };
  const files = state.api["file"] || { data: [] };
  const grups = state.api["grup-municipal"] || { data: [] };

  return {
    ple,
    regidors,
    areas,
    ou,
    files,
    grups,
  };
};

//======================================================================================================================
//==
//======================================================================================================================
class Ple extends Component {
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props) {
    super(props);
    this.state = {
      allLoaded: false,
      isLoadedRegidors: false,
      isLoadedActes: false,
      selectedPDF: null,
      selectedPDFNumPages: null,
      selectedPDFPageNumber: 1,
      // actes: [],
      // availableYears: [],
      // currentYear: null
    };
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount() {
    this.props.dispatch(readEndpoint("/node/ple"));
    this.props.dispatch(
      readEndpoint(
        "/node/regidor?filter[status]=1&sort=order&include=areas,photo,grup,ou"
      )
    );
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.regidors.data.length > 0) {
      this.setState({
        allLoaded: true,
      });
    }
  }

  //==================================================================================================================
  //== Render "ple" description
  //==================================================================================================================
  renderDescription() {
    let ple = this.props.ple.data[0];
    return (
      <div>
        <Fade>
          <div className={"row mt-3"}>
            <div className={"col-12"}>
              {ple.attributes.description ? (
                <div className={"html-content mt-2"}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ple.attributes.description.value,
                    }}
                    style={{ textAlign: "justify" }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fade>
      </div>
    );
  }

  //==================================================================================================================
  //== Render "regidors" body
  //==================================================================================================================
  renderRegidors() {
    const { regidors, areas, ou, files, grups } = this.props;

    // Ordenem els regidors segons els grups municipals al que pertanyen (els ergidor ja venen ordenats).
    const sortedGrups = grups.data.sort(
      (a, b) => a.attributes.order - b.attributes.order
    );

    let sortedRegidors = [];
    for (const g of sortedGrups) {
      sortedRegidors = [
        ...sortedRegidors,
        ...regidors.data.filter((r) => r.relationships.grup.data?.id === g.id),
      ];
    }

    return (
      <div className={"mt-3"}>
        {sortedRegidors.map((regidor) => (
          <Regidor
            key={regidor.id}
            regidor={regidor}
            grups={grups}
            arees={areas}
            unitats={ou}
            files={files}
          />
        ))}
      </div>
    );
  }

  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render() {
    const { ple } = this.props;
    const { allLoaded } = this.state;
    return (
      <div className={"ple"}>
        <div className={"row"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage id={"app.ajuntament.ple.title"} />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={ple.data.length <= 0}
            />
            {ple.data.length > 0 ? this.renderDescription() : null}
          </div>
        </div>

        <div className={"row mt-3"}>
          <div className={"col-12"}>
            {ple.data.length > 0 ? (
              <React.Fragment>
                <a
                  className="btn btn-info btn-sm"
                  href={ple.data[0].attributes.link_actes.uri}
                  target={"_blank"}
                >
                  Consultar actes de ple
                </a>
                <a
                  className="btn btn-info btn-sm ml-2"
                  href={ple.data[0].attributes.link_videoactes.uri}
                  target={"_blank"}
                >
                  Consultar videoactes de ple
                </a>
              </React.Fragment>
            ) : null}
          </div>
        </div>

        <div className={"row mt-4"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage id={"app.ajuntament.ple.regidors.title"} />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={!allLoaded}
            />
            {allLoaded ? this.renderRegidors() : null}
          </div>
        </div>

        {/*<div className={'row mt-4'}>*/}
        {/*<div className={'col-12'}>*/}
        {/*<ScrollElement name={'actes'}>*/}
        {/*<div className={'float-left'}>*/}
        {/*<h1><FormattedMessage id={'app.ajuntament.ple.actes.title'}/></h1>*/}
        {/*</div>*/}
        {/*<div className={'float-right'}>*/}
        {/*<small className={'align-text-bottom text-muted'}>Font:*/}
        {/*<a href={'http://dadesobertes.seu-e.cat'} target={'_blank'}> Dadesobertes - AOC</a>*/}
        {/*</small>*/}
        {/*</div>*/}
        {/*<div className="clearfix"/>*/}
        {/*{(actes.length <= 0) ? <Loader/> : this.renderActes()}*/}
        {/*</ScrollElement>*/}
        {/*</div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Ple);
