import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {setAxiosConfig} from 'redux-json-api';

import reducers from './reducers/reducers';
import {API_APP_URL} from "./Constants";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancer(applyMiddleware(thunk))
    // applyMiddleware(thunk),
    // applyMiddleware(logger)
);

store.dispatch(setAxiosConfig({
    baseURL: API_APP_URL + '/jsonapi/',
    headers: {
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
    },
    timeout: 60000,
}));

export default store;