//======================================================================================================================
//== DOM PURIFY SETTINGS
//======================================================================================================================
export const DOM_PURIFY_ALLOWED_TAGS = ['audio', 'video', 'source', 'strong', 'ul', 'li', 'a', 'br', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
export const DOM_PURIFY_ADD_ATTR = ['controls', 'target'];


//======================================================================================================================
//== APP API
//======================================================================================================================
export const API_APP_URL = 'https://api.calonge.cat';
// export const API_APP_URL = 'http://api.calonge.local';
// export const API_APP_URL = 'https://api.dev.calonge.cat';


//======================================================================================================================
//== DADESOBERTES API (PROXY)
//======================================================================================================================
const API_OPENDATA_PROXY = 'https://opendata-cache.calonge.cat';


//======================================================================================================================
//== DADESOBERTES SEU-E (odata)
//======================================================================================================================
const API_SEU_E_PATH = API_OPENDATA_PROXY + '/dadesobertes/api/aoc/action/odata';
const API_SEU_E_ID = '1703400000';
export const API_SEU_E_ACTES_PLE = API_SEU_E_PATH + '/b5d370d0-7916-48b6-8a69-3c7fa62a1467?$format=json&$q={"CODI_ENS":"' + API_SEU_E_ID + '"}';
export const API_SEU_E_CONTRACTACIO = API_SEU_E_PATH + '/7448c675-8880-464e-9980-1b92119e59c8?$format=json&$q={"CODI_ENS":"' + API_SEU_E_ID + '"}';
// export const API_DADESOBERTES_PRESSUPOST = API_PROXY_DADESOBERTES_PATH + '/595f8620-7a3c-4e38-8be2-7cffa744cd44?$format=json';

//======================================================================================================================
//== DADESOBERTES SEU-E (datastore search)
//======================================================================================================================
// const API_PROXY_DADESOBERTES_SEARCH_PATH = 'http://dadesobertes.seu-e.cat/api/action/datastore_search_sql';
// export const API_DADESOBERTES_PRESSUPOST = 'http://dadesobertes.seu-e.cat/api/action/datastore_search?resource_id=595f8620-7a3c-4e38-8be2-7cffa744cd44&filters={"CODI_ENS":"1703400000","ANY_EXERCICI":"2018"}';
// export const API_SEU_E_ACTES_PLE = API_PROXY_DADESOBERTES_SEARCH_PATH + '?sql=SELECT * FROM "b5d370d0-7916-48b6-8a69-3c7fa62a1467" WHERE "DATA_ACORD" > \'%s\' AND "CODI_ENS" = \'%s\'';


//======================================================================================================================
//== DADESOBERTES TAULER D'ANUNCIS
//======================================================================================================================
const API_TAULER_PATH = API_OPENDATA_PROXY + '/tauler';
export const API_TAULER_RSS = API_TAULER_PATH + '/rss.do?idens=1703400000';


//======================================================================================================================
//== DADESOBERTES DIBA
//======================================================================================================================
const API_DIBA_PATH = API_OPENDATA_PROXY + '/diba/cidows/rest';
export const API_DIBA_OFERTA_PUBLICA = API_DIBA_PATH + '/cercar?page=1&query={"tipusButlleti":"OPOSICIO","ordre":"DATAPUBLICACIO_DESC","filtres":{"INSTITUCIO":{"0":"17034"},"INSTITUCIO":{"0":"7759613"}}}';
// export const API_DIBA_OFERTA_PUBLICA_NEW = API_DIBA_PATH + '/cercar?page=1&query={"tipusButlleti":"OPOSICIO","ordre":"DATAPUBLICACIO_DESC","filtres":{"INSTITUCIO":{"0":"7759613"}}}';
export const API_DIBA_CONTRACTACIO = API_DIBA_PATH + '/cercar?page=1&query={"tipusButlleti":"CONTRACTACIO","ordre":"DATAPUBLICACIO_DESC","filtres":{"INSTITUCIO":{"0":"17034"}, "INSTITUCIO":{"0":"7759613"}}}';

//======================================================================================================================
//== DADESOBERTES TRANSPCAT
//======================================================================================================================
// const API_PROXY_TRANSPCAT_PATH = API_PROXY_HOST + '/transpcat/resource';
// export const API_TRANSPCAT_ELECTIONS_2015 = API_PROXY_TRANSPCAT_PATH + '/ikmm-fpxe.json?municipi=Calonge';


//======================================================================================================================
//== INE TEMPUS
//======================================================================================================================
const API_INE_PATH = 'https://cors-anywhere.herokuapp.com/http://servicios.ine.es/wstempus';  // TODO: Posar a través de l'Opendata Cache
export const API_INE_HISTORIC_POBLACIO = API_INE_PATH + '/js/es/DATOS_TABLA/2870?tip=AM&tv=18:451&tv=18:452&tv=18:453&tv=19:4283&tv=34:8677&tv=3:20258&date=19960101:20180101';
export const API_INE_POBLACIO_EDAT_QUINQUENNAL = API_INE_PATH + '/js/es/DATOS_TABLA//t20/e245/p05/a2018/l0/00017001.px?tip=AM&tv=sexo:hombres&tv=sexo:mujeres&tv=MUN:17034&tv=edadgruposquinquenales:04&tv=edadgruposquinquenales:59&tv=edadgruposquinquenales:1014&tv=edadgruposquinquenales:1519&tv=edadgruposquinquenales:2024&tv=edadgruposquinquenales:2529&tv=edadgruposquinquenales:3034&tv=edadgruposquinquenales:3539&tv=edadgruposquinquenales:4044&tv=edadgruposquinquenales:4549&tv=edadgruposquinquenales:5054&tv=edadgruposquinquenales:5559&tv=edadgruposquinquenales:6064&tv=edadgruposquinquenales:6569&tv=edadgruposquinquenales:7074&tv=edadgruposquinquenales:7579&tv=edadgruposquinquenales:8084&tv=edadgruposquinquenales:8589&tv=edadgruposquinquenales:9094&tv=edadgruposquinquenales:9599&tv=edadgruposquinquenales:100ymas';


//======================================================================================================================
//== REGEXP
//======================================================================================================================
export const REGEX_YOUTUBE = new RegExp(/(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:&list=(\S+))?/g);


//======================================================================================================================
//== ROUTING
//======================================================================================================================
export const ROUTES = [
  {
    title: 'app.menu.inici',
    link: '/',
    content: []
  },
  {
    title: 'app.menu.municipi',
    link: '/municipi',
    content: [
      {
        title: 'app.municipi.menu.vila',
        link: '',
        icon: 'monument'
      },
      {
        title: 'app.municipi.menu.agenda',
        link: '/agenda',
        icon: 'calendar-alt'
      },
      {
        title: 'app.municipi.menu.noticies',
        link: '/noticies',
        icon: 'newspaper'
      },
      {
        title: 'app.municipi.menu.eleccions',
        link: '/eleccions',
        icon: 'vote-yea'
      },
      {
        title: 'app.municipi.menu.estadistiques',
        link: '/estadistiques',
        icon: 'chart-line'
      },
      {
        title: 'app.municipi.menu.mapes',
        link: 'https://sitmun.ddgi.cat/calonge',
        icon: 'map-marked-alt'
      }
    ]
  },
  {
    title: 'app.menu.ajuntament',
    link: '/ajuntament',
    content: [
      {
        title: 'app.ajuntament.menu.alcaldia',
        link: '',
        icon: 'landmark'
      },
      {
        title: 'app.ajuntament.menu.premsa',
        link: '/premsa',
        icon: 'newspaper'
      },
      {
        title: 'app.ajuntament.menu.ple',
        link: '/ple',
        icon: 'school'
      },
      {
        title: 'app.ajuntament.menu.grups-municipals',
        link: '/grups-municipals',
        icon: 'dot-circle'
      },
      {
        title: 'app.ajuntament.menu.equip-govern',
        link: '/equip-govern',
        icon: 'users'
      },
      {
        title: 'app.ajuntament.menu.jgl',
        link: '/junta-govern-local',
        icon: 'users'
      },
      {
        title: 'app.ajuntament.menu.junta-portaveus',
        link: '/junta-portaveus',
        icon: 'users'
      },
      {
        title: 'app.ajuntament.menu.activitats',
        link: '/activitats',
        icon: 'ticket-alt'
      },
      {
        title: 'app.ajuntament.menu.sindic',
        link: '/sindic-greuges',
        icon: 'user-shield'
      },
      {
        title: 'app.ajuntament.menu.dependencies',
        link: '/dependencies',
        icon: 'building'
      },
      {
        title: 'app.ajuntament.menu.arees',
        link: '/arees',
        icon: 'sitemap'
      },
      // {
      //     title: 'app.ajuntament.menu.serveis',
      //     link: '/serveis',
      //     icon: 'tasks',
      // },
      {
        title: 'app.ajuntament.menu.tauler',
        link: '/tauler',
        icon: 'clipboard-list'
      },
      {
        title: 'app.ajuntament.menu.oferta-publica',
        link: '/oferta-publica',
        icon: 'briefcase'
      },
      {
        title: 'app.ajuntament.menu.contractacio',
        link: '/contractacio',
        icon: 'file-contract'
      },
      {
        title: 'app.ajuntament.menu.pressupost',
        link: '/pressupost',
        icon: 'clipboard-check'
      },
      {
        title: 'app.ajuntament.menu.normativa',
        link: '/normativa',
        icon: 'scroll'
      },
      {
        title: 'app.ajuntament.menu.subvencions',
        link: '/subvencions',
        icon: 'edit'
      },
      {
        title: 'app.ajuntament.menu.poum',
        link: '/poum',
        icon: 'map'
      },
      {
        title: 'app.ajuntament.menu.altres-documents',
        link: '/altres-documents',
        icon: 'file-alt'
      }
    ]
  },
  {
    title: 'app.menu.sobre-web',
    link: '',
    content: [
      {
        title: 'app.menu.sitemap',
        link: '/sitemap',
        icon: 'sitemap'
      },
      {
        title: 'app.menu.politica-cookies',
        link: '/politica-cookies',
        icon: 'sitemap'
      },
      {
        title: 'app.menu.avis-legal',
        link: '/avis-legal',
        icon: 'sitemap'
      },
      {
        title: 'app.menu.politica-privacitat',
        link: '/politica-privacitat',
        icon: 'sitemap'
      },
      {
        title: 'app.menu.dades-obertes',
        link: '/dades-obertes',
        icon: 'sitemap'
      },
      {
        title: 'app.menu.transparencia',
        link: '/transparencia',
        icon: 'sitemap'
      },
      {
        title: 'app.menu.contacte',
        link: '/contacte',
        icon: 'sitemap'
      },
    ]
  },
  {
    title: 'app.menu.turisme',
    link: 'https://www.calonge-santantoni.cat',
    content: []
  },
  {
    title: 'app.menu.seu-e',
    link: 'https://seu.calonge.cat',
    content: []
  },
];