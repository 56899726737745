import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const laVila = state.api['la-vila'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        laVila,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Vila extends React.Component
{

    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        if (!this.props.laVila || this.props.laVila.data.length <= 0)
        {
            // console.info('Retrieving node \'la vila\'.');
            this.props.dispatch(readEndpoint('/node/la-vila?include=image'));
        }
    }


    //==================================================================================================================
    //== Render component
    //==================================================================================================================
    render()
    {
        return (<Post data={this.props.laVila.data[0]} files={this.props.files.data}/>);
    }
}

export default connect(mapStateToProps)(Vila);