import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { API_APP_URL } from "../../../Constants";
import { readEndpoint } from "redux-json-api";
import { SyncLoader } from "react-spinners";
import Fade from "react-reveal/Fade";

//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) => {
  const grupsMunicipalsDesc = state.api["grups-municipals"] || { data: [] };
  const files = state.api["file"] || { data: [] };
  const grups = state.api["grup-municipal"] || { data: [] };

  return {
    grupsMunicipalsDesc,
    files,
    grups,
  };
};

//======================================================================================================================
//==
//======================================================================================================================
class GrupsMunicipals extends Component {
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props) {
    super(props);
    this.state = {
      allLoaded: false,
    };
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount() {
    this.props.dispatch(readEndpoint("/node/grups-municipals"));
    this.props.dispatch(readEndpoint("/node/grup-municipal?sort=order"));
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.grupsMunicipalsDesc.data.length > 0) {
      this.setState({
        allLoaded: true,
      });
    }
  }

  //==================================================================================================================
  //== Render "ple" description
  //==================================================================================================================
  renderDescription() {
    const { grupsMunicipalsDesc } = this.props;
    const gm = grupsMunicipalsDesc.data[0];

    return (
      <div>
        <Fade>
          <div className={"row mt-3"}>
            <div className={"col-12"}>
              {gm.attributes.description ? (
                <div className={"html-content mt-2"}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: gm.attributes.description.value,
                    }}
                    style={{ textAlign: "justify" }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fade>
      </div>
    );
  }

  //==================================================================================================================
  //== Render
  //==================================================================================================================
  renderGrupsMunicipals() {
    const { files, grups } = this.props;

    return (
      <div className={"mt-3 mb-4"}>
        <div className={"row"}>
          {grups.data.length <= 0 ? (
            <Fade>
              <div className={"col-12"}>
                <p>
                  No s'han trobat grups municipals o no s'has definit eleccions
                  municipals
                </p>
              </div>
            </Fade>
          ) : null}

          {grups.data.map((grup, key) => {
            // Obtenim el logo o posem la imatge per defecte
            let imageUrl;
            if (grup.relationships["logo"].data) {
              const image = files.data.find(
                (aux) => aux.id === grup.relationships["logo"].data.id
              );
              imageUrl = API_APP_URL + image.attributes.uri.url;
            } else {
              imageUrl =
                "https://via.placeholder.com/530x270?text=sense%20imatge"; // default image
            }

            return (
              <Fade key={key}>
                <div className={"col-12 col-sm-4 mt-3"}>
                  <div className={"img-thumbnail"}>
                    <img src={imageUrl} className={"img-fluid"} />
                  </div>

                  {grup.attributes.link ? (
                    <a href={grup.attributes.link.uri} target={"_blank"}>
                      <div className={"mt-2 px-2"}>
                        <h4>
                          <strong>{grup.attributes.title}</strong>
                        </h4>
                        <p>{grup.attributes.name}</p>
                      </div>
                    </a>
                  ) : (
                    <div className={"mt-2 px-2"}>
                      <h4>
                        <strong>{grup.attributes.title}</strong>
                      </h4>
                      <p>{grup.attributes.name}</p>
                    </div>
                  )}
                </div>
              </Fade>
            );
          })}
        </div>
      </div>
    );
  }

  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render() {
    const { grupsMunicipalsDesc } = this.props;
    const { allLoaded } = this.state;

    return (
      <div className={"ple"}>
        <div className={"row"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage id={"app.ajuntament.grups-municipals.title"} />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={grupsMunicipalsDesc.data.length <= 0}
            />
            {grupsMunicipalsDesc.data.length > 0
              ? this.renderDescription()
              : null}
          </div>
        </div>

        <div className={"row mt-4"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage
                id={"app.ajuntament.grups-municipals.grups.title"}
              />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={!allLoaded}
            />
            {allLoaded ? this.renderGrupsMunicipals() : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(GrupsMunicipals);
