import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import DependenciesDetail from "../ajuntament/dependencies/DependenciesDetail";
import {SyncLoader} from "react-spinners";
import {renderAttachedFiles} from "../../utils";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const contacte = state.api['contacte'] || {data: []};
    const dependencies = state.api['dependencia-municipal'] || {data: []};
    const files = state.api['file'] || {data: []};
    return {
        contacte,
        dependencies,
        files
    }
};


//======================================================================================================================
//== Contacte component
//======================================================================================================================
class Contacte extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor()
    {
        super();
        this.state = {
            showLogin: false
        };
        this.iframe = React.createRef();
    }


    //==================================================================================================================
    //== Retrieve data
    //==================================================================================================================
    componentWillMount()
    {
        if (!this.props.contacte || this.props.contacte.data.length <= 0)
        {
            this.props.dispatch(readEndpoint('/node/contacte?include=files_press'));
        }

        if (!this.props.dependencies || this.props.dependencies.data.length <= 0)
        {
            this.props.dispatch(readEndpoint('/node/dependencia-municipal?include=image,map'));
        }
    }

    componentDidMount()
    {
        // window.addEventListener('message', this.handleIframeTask);
    }


    //==================================================================================================================
    //== Render contact description
    //==================================================================================================================
    renderDescription(data)
    {
        const description = data.attributes.description.value;
        return (<p dangerouslySetInnerHTML={{__html: description}} style={{textAlign: 'justify'}}/>);
    }


    //==================================================================================================================
    //== Render software bugs contact
    //==================================================================================================================
    renderPressContact()
    {
        const {contacte, files} = this.props;
        const description = contacte.data[0].attributes.description_press.value;

        let attached = {files: []};
        if (contacte.data[0].relationships.files_press && contacte.data[0].relationships.files_press.data)
        {
            contacte.data[0].relationships.files_press.data.map(file =>
            {
                if (file.type === 'file' && files && files.data && files.data.length > 0)
                {
                    let obj = files.data.find(aux => (aux.id === file.id));
                    obj.attributes.description = file.meta.description;
                    attached.files.push(obj);
                }
            });
        }

        return (
            <div>
                <p dangerouslySetInnerHTML={{__html: description}} style={{textAlign: 'justify'}}/>
                {renderAttachedFiles(attached)}
            </div>);
    }


    //==================================================================================================================
    //== Render software bugs contact
    //==================================================================================================================
    renderSoftwareContact(data)
    {
        const description = data.attributes.description_bugs.value;
        return (<p dangerouslySetInnerHTML={{__html: description}} style={{textAlign: 'justify'}}/>);
    }


    //==================================================================================================================
    //== Render contact app
    //==================================================================================================================
    renderContact()
    {
        const {contacte, dependencies} = this.props;
        let mainDependencia;

        for (let dependencia of dependencies.data)
        {
            if (dependencia.attributes.is_main)
            {
                mainDependencia = dependencia;
            }
        }

        return (
            <div className={'mb-3'}>

                <div className={'mt-3'}>
                    <SyncLoader
                        className={"loader"}
                        sizeUnit={"px"}
                        size={10}
                        color={'#7ED321'}
                        loading={contacte.data.length <= 0}
                    />
                    {(contacte.data.length > 0) ? this.renderDescription(contacte.data[0]) : null}
                </div>

                <div className={'mt-4'}>
                    <SyncLoader
                        className={"loader"}
                        sizeUnit={"px"}
                        size={10}
                        color={'#7ED321'}
                        loading={(mainDependencia === null)}
                    />
                    {(mainDependencia) ? <DependenciesDetail id={mainDependencia.id}/> : null}

                </div>

                <div className={'mt-4'}>
                    <h2><FormattedMessage id={'app.contact.press.title'}/></h2>
                </div>

                <div className={'mt-3'}>
                    <SyncLoader
                        className={"loader"}
                        sizeUnit={"px"}
                        size={10}
                        color={'#7ED321'}
                        loading={contacte.data.length <= 0}
                    />
                    {(contacte.data.length > 0) ? this.renderPressContact() : null}
                </div>

                <div className={'mt-4'}>
                    <h2><FormattedMessage id={'app.contact.software.title'}/></h2>
                </div>

                <div className={'mt-3'}>
                    <SyncLoader
                        className={"loader"}
                        sizeUnit={"px"}
                        size={10}
                        color={'#7ED321'}
                        loading={contacte.data.length <= 0}
                    />
                    {(contacte.data.length > 0) ? this.renderSoftwareContact(contacte.data[0]) : null}
                </div>

            </div>
        )
    }

    //==================================================================================================================
    //== Render view
    //==================================================================================================================
    render()
    {
        return (
            <div>

                <div className={'page header'}>
                    <div className={'container'}>
                        <div className="row">

                            {/* PAGE TITLE */}
                            <div className={'col-lg-6'}>
                                <h1 className="title">
                                    <FormattedMessage id="app.contact.title"/>
                                </h1>
                            </div>
                            {/* END PAGE TITLE */}

                        </div>
                    </div>
                </div>

                <div id={'content'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                {this.renderContact()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


export default connect(mapStateToProps)(Contacte);