export default {

    //==================================================================================================================
    //== GLOBAL
    //==================================================================================================================
    'app.title': 'Ayuntamiento de Calonge i Sant Antoni',


    //==================================================================================================================
    //== MENÚ PRINCIPAL
    //==================================================================================================================
    'app.menu.inici': 'Inicio',
    'app.menu.ajuntament': 'Ayuntamiento',
    'app.menu.municipi': 'Municipio',
    'app.menu.sobre-web': 'Sobre el web',
    'app.menu.sitemap': 'Mapa web',
    'app.menu.politica-cookies': 'Politica de cookies',
    'app.menu.avis-legal': 'Aviso legal',
    'app.menu.dades-obertes': 'Datos abiertos (opendata)',
    'app.menu.transparencia': 'Transparencia',
    'app.menu.contacte': 'Contacto',
    'app.menu.turisme': 'Turismo',  // External
    'app.menu.seu-e': 'Sede electrónica',  // External


    //==================================================================================================================
    //== INICIO
    //==================================================================================================================
    'app.inici.title': 'Inicio',


    //==================================================================================================================
    //== MUNICIPIO
    //==================================================================================================================
    'app.municipi.title': 'Municipio',
    'app.municipi.menu.vila': 'La villa',
    'app.municipi.menu.agenda': 'Agenda',
    'app.municipi.menu.noticies': 'Notícias',
    'app.municipi.menu.eleccions': 'Resultados electorales',
    'app.municipi.menu.estadistiques': 'Estadísticas',
    'app.municipi.menu.mapes': 'Mapas',
    'app.municipi.vila.title': 'La villa',
    'app.municipi.agenda.title': 'Agenda',
    'app.municipi.agenda.event.sense-resultats': 'No se ha encontrado ningún evento con este ID.',
    'app.municipi.noticies.title': 'Notícias',
    'app.municipi.noticies.sense-resultats': 'No se ha encontrado ninguna notícia con este ID.',
    'app.municipi.eleccions.title': 'Resultados electorales',
    'app.municipi.estadistiques.title': 'Estadísticas',
    'app.municipi.mapes.title': 'Mapas',


    //==================================================================================================================
    //== AYUNTAMIENTO
    //==================================================================================================================
    'app.ajuntament.title': 'Ayuntamiento',
    'app.ajuntament.menu.alcaldia': 'Alcaldia',
    'app.ajuntament.menu.ple': 'Pleno',
    'app.ajuntament.menu.grups-municipals': 'Grupos municipales',
    'app.ajuntament.menu.equip-govern': 'Equipo de gobierno',
    'app.ajuntament.menu.jgl': 'Junta de gobierno local',
    'app.ajuntament.menu.junta-portaveus': 'Junta de portavoces',
    'app.ajuntament.menu.sindic': 'Síndico de agravios',
    'app.ajuntament.menu.dependencies': 'Dependencias municipales',
    'app.ajuntament.menu.arees': 'Organización interna',
    'app.ajuntament.menu.tauler': 'Tablón de anuncios',
    'app.ajuntament.menu.oferta-publica': 'Oferta de ocupación',
    'app.ajuntament.menu.contractacio': 'Contratación',
    'app.ajuntament.menu.pressupost': 'Presupuesto',
    'app.ajuntament.menu.normativa': 'Normativa',
    'app.ajuntament.menu.subvencions': 'Subvenciones',
    'app.ajuntament.menu.poum': 'POUM',
    'app.ajuntament.alcaldia.title': 'Alcaldia',
    'app.ajuntament.ple.title': 'Pleno',
    'app.ajuntament.ple.regidors.title': 'Concejales actuales',
    'app.ajuntament.ple.actes.title': 'Actas',
    'app.ajuntament.grups-municipals.title': 'Grupos municipales',
    'app.ajuntament.grups-municipals.grups.title': 'Listado de grupos actuals',
    'app.ajuntament.equip-govern.title': 'Equipo de gobierno',
    'app.ajuntament.equip-govern.regidors.title': 'Concejales del equipo de gobierno',
    'app.ajuntament.jgl.title': 'Junta de gobierno local',
    'app.ajuntament.jgl.regidors.title': 'Concejales de la junta de gobierno',
    'app.ajuntament.jp.title': 'Junta de portavoces',
    'app.ajuntament.jp.regidors.title': 'Portavoces de los grupos municipales',
    'app.ajuntament.sindic.title': 'Síndico de agravios',
    'app.ajuntament.dependencies.title': 'Dependencias municipales',
    'app.ajuntament.dependencies.dependencia.title': 'Dependencias',
    'app.ajuntament.dependencies.dependencia.descripcio': 'A continuación listamos las distintas dependencias municipales del Ayuntamiento',
    'app.ajuntament.dependencies.dependencia.sense-resultats': 'No se ha encontrado ninguna dependencia con este ID.',
    'app.ajuntament.organitzacio-interna.title': 'Organización interna',
    'app.ajuntament.organitzacio-interna.arees.title': 'Areas y Unidades',
    'app.ajuntament.organitzacio-interna.arees.descripcio': 'A continuación listamos las distintas áreas y unidades organizativas del Ayuntamiento.',
    'app.ajuntament.organitzacio-interna.area.sense-resultats': 'No se ha encontrado ninguna área con este ID.',
    'app.ajuntament.organitzacio-interna.area.unitats': 'Esta área esta formada por las siguientes unidades:',
    'app.ajuntament.organitzacio-interna.unitat.sense-resultats': 'No se ha encontrado ninguna unidad con este ID.',
    'app.ajuntament.organitzacio-interna.unitat.area': 'Esta unidad forma parte de la siguiente unidad:',
    'app.ajuntament.tauler.title': 'Tablón de anuncios',
    'app.ajuntament.oferta-publica.title': 'Oferta de ocupación',
    'app.ajuntament.oferta-publica.status.termini-tancat': 'Plazo cerrado',
    'app.ajuntament.oferta-publica.status.no-vigent': 'No vigente',
    'app.ajuntament.oferta-publica.status.pendent-convocatoria': 'Pendiente de convocatoria',
    'app.ajuntament.oferta-publica.status.termini-obert': 'Período abierto',
    'app.ajuntament.oferta-publica.status.no-informat': 'No informado',
    'app.ajuntament.contractacio.title': 'Contratación',
    'app.ajuntament.pressupost.title': 'Presupuesto',
    'app.ajuntament.pressupost.loading': 'Cargando presupuesto...',
    'app.ajuntament.pressupost.destacats.title': 'Archivos destacados',
    'app.ajuntament.pressupost.pmp.title': 'Plazo de pago a proveedores',
    'app.ajuntament.normativa.reglaments-organics.title': 'Reglamentos organicos',
    'app.ajuntament.normativa.ordenances-fiscals.title': 'Ordenanzas fiscales',
    'app.ajuntament.normativa.ordenances-urbanisme.title': 'Ordenanzas de urbanismo',
    'app.ajuntament.normativa.ordenances-policia.title': 'Ordenanzas policía',
    'app.ajuntament.normativa.tramitacio.title': 'En tramitación',
    'app.ajuntament.normativa.tramitacio.fase1.title': 'Consultas públicas previas',
    'app.ajuntament.normativa.tramitacio.fase2.title': 'Anteproyectos en tramitación',
    'app.ajuntament.normativa.tramitacio.fase3.title': 'Aprobaciones definitivas',
    'app.ajuntament.normativa.subvencions.title': 'Subvenciones',
    'app.ajuntament.normativa.subvencions.documentacio.title': 'Documentación para entidades locales',
    'app.ajuntament.normativa.subvencions.altres.title': 'Otras subvenciones',
    'app.ajuntament.normativa.subvencions.altres.description': 'A continuación se pueden encontrar los enlaces a las publicaciones de subvenciones del Ayuntamiento.',
    'app.ajuntament.poum.title': 'POUM',
    'app.ajuntament.poum.normativa.title': 'Normativa',
    'app.ajuntament.poum.catalegs.title': 'Catalogo de bienes protegidos',
    'app.ajuntament.poum.gc.title': 'Clasificación del suelo',
    'app.ajuntament.poum.gq.title': 'Calificación del suelo',
    'app.ajuntament.poum.other.title': 'Otra información de interés',


    //==================================================================================================================
    //== CONTACTO
    //==================================================================================================================
    'app.contact.title': 'Contacto',
    'app.contact.press.title': 'Prensa i comunicación',
    'app.contact.software.title': 'Incidencias informáticas',


    //==================================================================================================================
    //== MAPA WEB
    //==================================================================================================================
    'app.sitemap.title': 'Mapa web',


    //==================================================================================================================
    //== AVISO LEGAL
    //==================================================================================================================
    'app.avis-legal.title': 'Aviso legal'
}