import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";
import axios from 'axios';
import {Line} from 'react-chartjs-2';
import cloneDeep from 'lodash/cloneDeep';
import Fade from "react-reveal/Fade";
import * as uuid from "uuid";
import {API_INE_HISTORIC_POBLACIO} from "../../../Constants";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    // console.log(state.api);
    const estadistiques = state.api['estadistiques'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        estadistiques,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Estadistiques extends React.Component
{

    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {
            popHistoryData: null,
            popHistoryDisplay: 'graph'
        };
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        if (!this.props.estadistiques || this.props.estadistiques.data.length <= 0)
        {
            console.info('Retrieving node \'estadistiques\'.');
            this.props.dispatch(readEndpoint('/node/estadistiques'));
        }

        let that = this;
        let lines = [
            {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderColor: 'rgba(0, 0, 0, 0.3)',
                pointBackgroundColor: 'rgba(0, 0, 0, 0.5)',
                label: 'Total',
                datalabels: {
                    display: true,
                    color: 'rgba(0, 0, 0,  1)',
                    align: 'bottom',
                    offset: 2,
                    font: {
                        size: 12,
                        weight: 'normal'
                    },
                    rotation: 45
                }
            }, {
                backgroundColor: 'rgba(0, 176, 255, 0.1)',
                borderColor: 'rgba(0, 176, 255, 0.3)',
                pointBackgroundColor: 'rgba(0, 176, 255, 0.5)',
                label: 'Homes',
                datalabels: {
                    display: true,
                    color: 'rgba(0, 176, 255, 1)',
                    align: 'top',
                    offset: 2,
                    font: {
                        size: 12,
                        weight: 'normal'
                    },
                    rotation: 45
                }
            }, {
                backgroundColor: 'rgba(183, 28, 28, 0.1)',
                borderColor: 'rgba(183, 28, 28, 0.3)',
                pointBackgroundColor: 'rgba(183, 28, 28, 0.5)',
                label: 'Dones',
                datalabels: {
                    display: true,
                    color: 'rgba(183, 28, 28, 1)',
                    align: 'bottom',
                    offset: 2,
                    font: {
                        size: 12,
                        weight: 'normal'
                    },
                    rotation: 45
                }
            }

        ];

        axios.get(API_INE_HISTORIC_POBLACIO, {
            headers: {
                'Origin': window.location.origin
            }
        }).then(function (response)
        {
            console.log(response);
            let data = {
                labels: [],
                datasets: []
            };

            let first = true;
            for (let i = 0; i < response.data.length; ++i)
            {
                let dataset = response.data[i];
                let d = [];
                dataset['Data'] = dataset['Data'].sort((a, b) => (a['Anyo'] - b['Anyo']));  // Sort
                for (let year of dataset['Data'])
                {
                    if (first)
                    {
                        data.labels.push(year['Anyo']);
                    }
                    d.push(year['Valor']);
                }
                first = false;

                data.datasets.push({
                    backgroundColor: lines[i].backgroundColor,
                    borderColor: lines[i].borderColor,
                    pointBackgroundColor: lines[i].pointBackgroundColor,
                    pointHoverRadius: 5,
                    fill: true,
                    lineTension: 0.4,
                    label: lines[i].label,
                    data: d,
                    datalabels: lines[i].datalabels
                })
            }

            that.setState({
                popHistoryData: data
            });
        })
            .catch(function (error)
            {
                console.error(error);
            })
            .then(function ()
            {
                console.log('Hola!');
                // always executed
            });
    }


    //==================================================================================================================
    //== Switch between graph and table
    //==================================================================================================================
    handleSwitchGraphTable()
    {
        this.setState(prevState => ({
            popHistoryDisplay: (prevState.popHistoryDisplay === 'graph') ? 'table' : 'graph'
        }));
    }


    //==================================================================================================================
    //== Render table body with external data
    //==================================================================================================================
    renderTableRows(data)
    {
        // Deep copy object
        let aux = cloneDeep(data);

        // Sort
        aux.labels = aux.labels.reverse();
        aux.datasets[0].data = aux.datasets[0].data.reverse();
        aux.datasets[1].data = aux.datasets[1].data.reverse();
        aux.datasets[2].data = aux.datasets[2].data.reverse();

        // Create html object
        let rows = [];
        for (let i = 0; i < aux.labels.length; ++i)
        {
            rows.push(
                <tr className={'text-center'} key={uuid.v4()}>
                    <th>{aux.labels[i]}</th>
                    <td>{aux.datasets[0].data[i]}</td>
                    <td>{aux.datasets[1].data[i]}</td>
                    <td>{aux.datasets[2].data[i]}</td>
                </tr>
            );
        }

        return rows;
    }


    //==================================================================================================================
    //== Render component
    //==================================================================================================================
    render()
    {
        const {popHistoryDisplay, popHistoryData} = this.state;
        // console.log(popHistoryData);
        return (
            <div>

                <Post data={this.props.estadistiques.data[0]} files={this.props.files.data}/>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1>Històric de població</h1>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12'}>

                        <div className={'float-right'}>
                            <button type="button"
                                    className={'btn btn-sm btn-info'}
                                    onClick={() => this.handleSwitchGraphTable()}>
                                {(popHistoryDisplay === 'graph') ? <span>Mostrar Taula</span> :
                                    <span>Mostrar Gràfic</span>}
                            </button>
                        </div>

                    </div>
                </div>

                <div className={'row mt-4'}>

                    {(popHistoryData) ?
                        <div className={'col-12'}>
                            {(popHistoryDisplay === 'graph') ?
                                <Fade>
                                    <Line
                                        data={popHistoryData}
                                        options={{
                                            showLines: true,
                                        }}
                                    />
                                </Fade> :
                                <Fade>
                                    <table className={'table mt-3 mt-xl-0'}>
                                        <thead className={'thead-dark'}>
                                        <tr className={'text-center'}>
                                            <th>Any</th>
                                            <th>Total</th>
                                            <th>Homes</th>
                                            <th>Dones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.renderTableRows(popHistoryData)}
                                        </tbody>
                                    </table>
                                </Fade>
                            }
                        </div>
                        : <div className={'col-12'}>
                            <span>Carregant dades des de l'INE...</span>
                        </div>
                    }
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Estadistiques);