import React, {Component} from 'react';
import {connect} from 'react-redux';
import {readEndpoint} from "redux-json-api";
import {Link, Route} from "react-router-dom";
import Loader from "../../../components/Loader";
import Noticia from "../../../components/Noticia";
import Servei from "../../../components/Servei";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const isReading = (state.api.isReading > 0);
  const services = state.api['service'] || {data: []};
  const images = state.api['image'] || {data: []};  // Attached images
  const audios = state.api['media--audio'] || {data: []};  // Attached audio
  const videos = state.api['media--video'] || {data: []};  // Attached video
  const files = state.api['file'] || {data: []};  // Files

  return {
    isReading,
    services,
    images,
    audios,
    videos,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class ServeiDetail extends Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {
      selectedTab: null
    };
  }


  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    this.props.dispatch(readEndpoint('/node/service?include=media,media.media_image,media.field_media_audio_file,media.field_media_video_file'));
  }


  //==================================================================================================================
  //== Render "service" body
  //==================================================================================================================
  renderService(service)
  {
    const {services, match, images, audios, videos, files} = this.props;
    const media = {
      images: images,
      audios: audios,
      videos: videos,
      files: files
    };

    // Obtenim el llistat de sub serveis que té el servei actual
    const subServices = services.data.filter(obj => (obj.relationships.service.data && obj.relationships.service.data.id === service.id));

    const that = this;
    return (
      <Route path={['/ajuntament/serveis/:id/:sid', '/ajuntament/serveis/:id']} render={(props) =>
      {
        const selectedTab = that.state.selectedTab || props.match.params['sid'] || null;
        const selectedSubService = subServices.find(obj => (obj.attributes['f_url'] === props.match.params['sid']));

        return (
          <div>

            <div>
              <h1>{service.attributes['title']}</h1>
            </div>

            <div className={'mt-3'}>
              <Servei media={media} servei={service} display={'full'}/>
            </div>

            {subServices.length > 0 &&
            <React.Fragment>

              <div className={'mt-5'}>
                <h3>Serveis associats</h3>
              </div>

              <div className={'mt-3 services-header'}>
                <ul className="nav">
                  {subServices.map((subService, key) =>
                  {
                    const fUrl = subService.attributes['f_url'];
                    return (
                      <li key={key} className="nav-item">
                        <Link to={`${match.url}/${fUrl}`}
                              className={(selectedTab === fUrl) ? 'nav-link active' : 'nav-link'}
                              onClick={() => this.setState({selectedTab: fUrl})}>
                          <div className={'selected'}>
                            {subService.attributes.title}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

            </React.Fragment>
            }

            {selectedSubService &&
              <div className={'row'}>
                <div className={'col-12'}>
                  <div className={'mt-3'}>
                    <Servei media={media} servei={selectedSubService} display={'full'}/>
                  </div>
                </div>
              </div>
            }

          </div>
        );
      }}/>
    );
  }


  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render()
  {
    const {isReading, services} = this.props;
    const service = services.data.find(obj => (obj.attributes.f_url === this.props.match.params.id));

    return (
      <div>
        <div>
          {(isReading) ? <Loader/> : this.renderService(service)}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ServeiDetail);