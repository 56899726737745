import React from 'react';
import Moment from 'moment';
import RSSParser from 'rss-parser';
import {FormattedMessage} from 'react-intl';
import {API_TAULER_RSS} from '../../../Constants';


//======================================================================================================================
//==
//======================================================================================================================
export default class Tauler extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {
            anuncis: null,
            errorTauler: null,
            isLoadedTauler: false
        };
    }

    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        let that = this;
        const parser = new RSSParser();
        parser.parseURL(API_TAULER_RSS, (err, feed) =>
            {
                // console.log(err, res);
                if (err)
                {
                    console.error(err);
                    that.setState({
                        isLoadedTauler: true,
                        errorTauler: err
                    });
                }

                if (!feed || !feed.items)
                {
                    that.setState({
                        isLoadedTauler: true,
                        errorTauler: {message: 'Bad result'}
                    });
                    return;
                }

                // TODO: sort if necessary

                // Set state
                that.setState({
                    isLoadedTauler: true,
                    anuncis: feed.items
                });
            }
        )
    }


    //==================================================================================================================
    //== Render "actes" table body
    //==================================================================================================================
    renderTaulerTable()
    {
        const {errorTauler, isLoadedTauler, anuncis} = this.state;

        if (errorTauler)
        {
            return <div>Error: {errorTauler.message}</div>;
        }
        else if (!isLoadedTauler)
        {
            return <div>Carregant llistat d'anuncis...</div>;
        }
        else
        {
            return (
                <div className={'mt-3'}>
                    {anuncis.map((data, key) =>
                    {
                        let datePub = Moment(data.isoDate).format("DD-MM-YYYY");
                        let linkPDF = data.link.replace('pagDetall.do', 'descarrega');
                        let linkPDFEvidencia = data.link.replace('pagDetall.do', 'descarregaEvidencia');
                        return (
                            <div key={key} className={'mb-3'}>
                                <p><strong><a href={data.link} target={'_blank'}>{data.title}</a></strong> (<a href={linkPDF} target={'_blank'}>PDF</a>) (<a
                                    href={linkPDFEvidencia} target={'_blank'}>evidència</a>)</p>
                                <p><strong>Publicat:</strong> {datePub}</p>
                                <p>{data.contentSnippet}</p>
                            </div>
                        )
                    })}
                </div>
            );
        }
    }

    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {} = this.state;
        return (
            <div>
                <h1><FormattedMessage id={'app.ajuntament.tauler.title'}/></h1>
                {this.renderTaulerTable()}
            </div>
        );
    }
}