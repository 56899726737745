import React from "react";

const Activitats = () =>
{
  return (
    <>
      <div className={"alert alert-success"} role={"alert"} style={{textAlign: "center"}}>
        <div>Activitats disponibles i venta d'entrades</div>
        <br/>
        <div><span><b><a href={"https://reserves.calongeisantantoni.cat"} target={"_blank"}>https://reserves.calongeisantantoni.cat</a></b></span></div>
      </div>
    </>
  );
}

export default Activitats;