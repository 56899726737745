import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, Route} from "react-router-dom";
import Alcaldia from "./alcaldia/Alcaldia";
import Premsa from "./premsa/Premsa";
import Ple from "./ple/Ple";
import Tauler from "./tauler-anuncis/Tauler";
import OfertaPublica from "./oferta-publica/OfertaPublica";
import JGL from "./jgl/JGL";
import Sindic from "./sindic-greuges/Sindic";
import JP from "./jp/JP";
import OrganitzacioInterna from "./organitzacio-interna/OrganitzacioInterna";
import Area from "./organitzacio-interna/Area";
import Unitat from "./organitzacio-interna/Unitat";
import Fade from 'react-reveal/Fade';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Contractacio from "./contractacio/Contractacio";
import Pressupost from "./pressupost/Pressupost";
import Dependencies from "./dependencies/Dependencies";
import DependenciesDetail from "./dependencies/DependenciesDetail";
import POUM from "./poum/POUM";
import EquipGovern from "./equip-govern/EquipGovern";
import GrupsMunicipals from "./grups-municipals/GrupsMunicipals";
import Normativa from "./normativa/Normativa";
import Subvencions from "./subvencions/Subvencions";
import {ROUTES} from '../../Constants';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Serveis from "./serveis/Serveis";
import ServeiDetail from "./serveis/ServeiDetail";
import AltresDocuments from "./altres-documents/AltresDocuments";
import Activitats from "./activitats/Activitats";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const isReading = (state.api.isReading > 0);
  const services = state.api['service'] || {data: []};

  return {
    isReading,
    services
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class Ajuntament extends React.Component
{
  constructor()
  {
    super();
    this.state = {};
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    this.props.dispatch(readEndpoint('/node/service'));
  }

  render()
  {
    const {services} = this.props;
    const menu = ROUTES.find(r => r.link === '/ajuntament');

    // console.log(services.data);

    // Obtenim nomes el serveis principals (excloem els subserveis)
    let servicesShow = services.data.filter(obj => obj.attributes['is_main']);

    // Ordenem els serveis alfabeticament
    servicesShow = servicesShow.sort((a, b) =>
    {
      let a1 = a.attributes.title.toLowerCase();
      let b1 = b.attributes.title.toLowerCase();
      return (a1 > b1) ? 1 : (a1 < b1) ? -1 : 0;
    });

    // console.log(servicesShow);

    return (
      <Fade>

        <div className={'page header'}>
          <div className={'container'}>
            <div className="row">

              {/*<div className={'col-lg-6'}>*/}
              <div className={'col-6'}>
                <h1 className="title">
                  <FormattedMessage id="app.ajuntament.title"/>
                </h1>
              </div>

              {/*<div className={'col-lg-6'}>*/}
              <div className={'col-6'}>
                <div className={'breadcrumbs float-right'}>
                  <ul className={'list-inline'}>
                    <li className={'list-inline-item'}>
                      <FontAwesomeIcon icon={'home'} fixedWidth/>
                    </li>
                    <li className={'list-inline-item'}>/</li>
                    <li className={'list-inline-item'}>
                      <a> <FormattedMessage id="app.ajuntament.title"/></a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div id={'content'}>
          <div className={'container'}>
            <div className={'row my-3 my-lg-5'}>

              {/* SIDEBAR */}
              <aside className={'d-none d-lg-block col-lg-4 col-xl-3 mb-0 mb-lg-5'}>
                <div className={'list-group'}>
                  <div className={'header'}>
                    <h3>Seccions</h3>
                  </div>

                  {services && services.data && menu.content.map((section, key) =>
                  {
                    return (
                      (section.link.startsWith('http'))
                        ?
                        <a key={key} href={section.link} target={'_blank'}
                           className={'list-group-item'}>
                          <FontAwesomeIcon icon={section.icon} fixedWidth/>
                          <FormattedMessage id={section.title}/>
                        </a>
                        :
                        <React.Fragment key={key}>
                          <Link to={menu.link + section.link}
                                className={'list-group-item'}>
                            <FontAwesomeIcon icon={section.icon} fixedWidth/>
                            <FormattedMessage id={section.title}/>
                          </Link>

                          {(section.link === '/serveis' && servicesShow.map((service, key2) =>
                          {
                            return (
                              <Link key={key2}
                                    to={menu.link + section.link + '/' + service.attributes['f_url']}
                                    className={'list-group-subitem'}>
                                <FontAwesomeIcon icon={service.attributes['icon']}
                                                 fixedWidth/>
                                <span>{service.attributes['title']}</span>
                              </Link>
                            );
                          }))}
                        </React.Fragment>
                    );
                  })}

                </div>
              </aside>

              <div className="col-lg-8 col-xl-9 mb-0 mb-lg-5">
                <Route exact path="/ajuntament" component={Alcaldia} key="alcaldia"/>
                <Route exact path="/ajuntament/premsa" component={Premsa} key="premsa"/>
                <Route exact path="/ajuntament/ple" component={Ple} key="ple"/>
                <Route exact path="/ajuntament/grups-municipals" component={GrupsMunicipals} key="grups-municipals"/>
                <Route exact path="/ajuntament/equip-govern" component={EquipGovern} key="equip-govern"/>
                <Route exact path="/ajuntament/junta-govern-local" component={JGL} key="junta-govern-local"/>
                <Route exact path="/ajuntament/junta-portaveus" component={JP} key="junta-portaveus"/>
                <Route exact path="/ajuntament/activitats" component={Activitats} key="activitats"/>
                <Route exact path="/ajuntament/sindic-greuges" component={Sindic} key="sindic-greuges"/>
                <Route exact path="/ajuntament/dependencies" component={Dependencies} key="dependencies"/>
                <Route exact path="/ajuntament/dependencies/:id" component={DependenciesDetail} key="dependencies-detail"/>
                <Route exact path="/ajuntament/arees" component={OrganitzacioInterna} key="arees"/>
                <Route exact path="/ajuntament/arees/area/:id" component={Area} key="area"/>
                <Route exact path="/ajuntament/arees/unitat/:id" component={Unitat} key="unitat"/>
                <Route exact path="/ajuntament/serveis" component={Serveis} key="serveis"/>
                <Route path="/ajuntament/serveis/:id" component={ServeiDetail} key="servei"/>
                <Route exact path="/ajuntament/tauler" component={Tauler} key="tauler"/>
                <Route exact path="/ajuntament/oferta-publica" component={OfertaPublica} key="oferta-publica"/>
                <Route exact path="/ajuntament/contractacio" component={Contractacio} key="contractacio"/>
                <Route exact path="/ajuntament/pressupost" component={Pressupost} key="pressupost"/>
                <Route exact path="/ajuntament/normativa" component={Normativa} key="normativa"/>
                <Route exact path="/ajuntament/subvencions" component={Subvencions} key="subvencions"/>
                <Route exact path="/ajuntament/poum" component={POUM} key="poum"/>
                <Route exact path="/ajuntament/altres-documents" component={AltresDocuments} key="altres-documents"/>
              </div>

            </div>
          </div>
        </div>
      </Fade>
    );
  }
}


export default connect(mapStateToProps)(Ajuntament);