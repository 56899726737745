import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { API_APP_URL, API_SEU_E_ACTES_PLE } from "../../../Constants";
import { readEndpoint } from "redux-json-api";
import { SyncLoader } from "react-spinners";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Regidor from "../../../components/Regidor";

//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) => {
  const jp = state.api["jp"] || { data: [] };
  const regidors = state.api["regidor"] || { data: [] };
  const areas = state.api["area"] || { data: [] };
  const ou = state.api["unitat-organitzativa"] || { data: [] };
  const files = state.api["file"] || { data: [] };
  const grups = state.api["grup-municipal"] || { data: [] };

  return {
    regidors,
    areas,
    ou,
    files,
    jp,
    grups,
  };
};

//======================================================================================================================
//==
//======================================================================================================================
class JP extends Component {
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props) {
    super(props);
    this.state = {
      allLoaded: false,
    };
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount() {
    // OR filter: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/filtering
    let orFilter = `filter[or-group][group][conjunction]=OR`;

    orFilter += `&filter[sticky-filter][condition][path]=is_portaveu`;
    orFilter += `&filter[sticky-filter][condition][value]=1`;
    orFilter += `&filter[sticky-filter][condition][memberOf]=or-group`;

    // Create the promoted filter and put it in the OR GROUP
    orFilter += `&filter[promote-filter][condition][path]=is_alcalde`;
    orFilter += `&filter[promote-filter][condition][value]=1`;
    orFilter += `&filter[promote-filter][condition][memberOf]=or-group`;

    this.props.dispatch(readEndpoint("/node/jp"));
    this.props.dispatch(
      readEndpoint(
        `/node/regidor?filter[status]=1&${orFilter}&sort=order&include=areas,photo,grup,ou`
      )
    );
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.regidors.data.length > 0) {
      this.setState({
        allLoaded: true,
      });
    }
  }

  //==================================================================================================================
  //== Render description
  //==================================================================================================================
  renderDescription(jp) {
    return (
      <div>
        <Fade>
          <div className={"row mt-3"}>
            <div className={"col-12"}>
              <div className={"html-content mt-2"}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: jp.data[0].attributes.description.value,
                  }}
                  style={{ textAlign: "justify" }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }

  //==================================================================================================================
  //== Render "regidors" body
  //==================================================================================================================
  renderRegidors() {
    const { regidors, areas, ou, files, grups } = this.props;

    // Ordenem els regidors segons els grups municipals al que pertanyen (els ergidor ja venen ordenats).
    const sortedGrups = grups.data.sort(
      (a, b) => a.attributes.order - b.attributes.order
    );

    let sortedRegidors = [];
    for (const g of sortedGrups) {
      sortedRegidors = [
        ...sortedRegidors,
        ...regidors.data.filter(
          (r) =>
            r.relationships.grup.data?.id === g.id &&
            (r.attributes.is_alcalde || r.attributes.is_portaveu)
        ),
      ];
    }

    return (
      <div className={"mt-3"}>
        {sortedRegidors.map((regidor) => (
          <Regidor
            key={regidor.id}
            regidor={regidor}
            grups={grups}
            arees={areas}
            unitats={ou}
            files={files}
          />
        ))}
      </div>
    );
  }

  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render() {
    const { jp, regidors, isReading } = this.props;
    const { allLoaded } = this.state;

    return (
      <div className={"ple"}>
        <div className={"row"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage id={"app.ajuntament.jp.title"} />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={jp.data.length <= 0}
            />
            {jp.data.length > 0 ? this.renderDescription(jp) : null}
          </div>
        </div>

        <div className={"row mt-4"}>
          <div className={"col-12"}>
            <h1>
              <FormattedMessage id={"app.ajuntament.jp.regidors.title"} />
            </h1>
            <SyncLoader
              className={"loader"}
              sizeUnit={"px"}
              size={10}
              color={"#7ED321"}
              loading={!allLoaded}
            />
            {allLoaded ? this.renderRegidors() : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(JP);
