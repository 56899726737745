import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";
import {FormattedMessage} from "react-intl";
import Fade from "react-reveal/Fade";
import filesize from 'filesize';
import {renderFileAttachmentIcon} from "../../../utils";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS} from "../../../Constants";
import DOMPurify from "dompurify";
import Loader from "../../../components/Loader";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Fullscreen from "react-full-screen";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const normativa = state.api['regulations'] || {data: []};
  const normativaTram = state.api['regulation-tram'] || {data: []};
  const files = state.api['file'] || {data: []};
  const isReading = (state.api.isReading > 0);
  const areas = state.api['area'] || {data: []};

  return {
    isReading,
    normativa,
    normativaTram,
    files,
    areas
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class Normativa extends React.Component
{

  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {
      tramitacioModalOpen: false
    };
  }


  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    // if (!this.props.normativa || this.props.normativa.data.length <= 0)
    // {
    // console.info('Retrieving node normativaDescription.');
    this.props.dispatch(readEndpoint('/node/regulations?include=reg_fiscals_files,reg_organic_files,reg_policia_files,reg_urbanisme_files'));
    // }

    // if (!this.props.normativaTram || this.props.normativaTram.data.length <= 0)
    // {
    //     console.info('Retrieving node normativaTram.');
    this.props.dispatch(readEndpoint('/node/regulation-tram?include=area,ntram_p1_files,ntram_p2_files,ntram_p3_files,ntram_p4_files'));
    // }
  }


  //==================================================================================================================
  //== Link files with poum data
  //==================================================================================================================
  getFiles(data, fileType)
  {
    const {files} = this.props;

    let attached = [];
    if (data.relationships[fileType] &&
      data.relationships[fileType].data &&
      data.relationships[fileType].data.length > 0)
    {
      data.relationships[fileType].data.map(file =>
      {
        if (file.type === 'file' && files && files.data && files.data.length > 0)
        {
          let obj = files.data.find(aux => (aux.id === file.id));
          obj.attributes.description = file.meta.description;
          attached.push(obj);
        }
      });
    }

    return attached;
  }


  //==================================================================================================================
  //== Render section normativa en tramitacio
  //==================================================================================================================
  renderSectionNormativaTramitacio(data, description, fileType)
  {
    const {normativaTram, areas, files} = this.props;
    let body = null;
    if (data.attributes && data.attributes[description] && data.attributes[description].value)
    {
      body = DOMPurify.sanitize(data.attributes[description].value, {
        ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
        ADD_ATTR: DOM_PURIFY_ADD_ATTR
      });
    }

    return (
      <div>

        <div className={'mt-2 html-content'}>
          {(body) ? <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/> :
            <p>Sense dades.</p>}
        </div>

        <button type={'button'}
                className={'btn btn-info btn-xs float-right mt-2'}
                onClick={() => this.setState({isFullscreen: true})}>
          <FontAwesomeIcon icon={'tv'} fixedWidth/> Veure pantalla completa
        </button>

        <div className={'mt-4'}>
          <Fullscreen
            enabled={this.state.isFullscreen}
            onChange={isFullscreen => this.setState({isFullscreen})}
          >
            <div className={(this.state.isFullscreen) ? 'results' : ''}>
              <h3><FormattedMessage id={'app.ajuntament.normativa.tramitacio.subtitle'}/></h3>
              <div className={'mt-3 scroll-x'}>

                <table className={'table mt-4'}>
                  <thead>
                  <tr>
                    <th width="30%">Descripció</th>
                    <th width="20%">Fase 1</th>
                    <th width="20%">Fase 2</th>
                    <th width="20%">Fase 3</th>
                    <th width="10%">Fase 4</th>
                  </tr>
                  </thead>
                  <tbody>

                  {normativaTram.data.map((normativa, key) =>
                  {
                    const area = areas.data.find(a => a.id === normativa.relationships.area.data.id);
                    if (normativa.attributes.summary)
                    {
                      return (
                        <tr key={key}>

                          <td>
                            <span>{normativa.attributes.summary} </span>

                            <Link to={'/ajuntament/arees/area/' + area.id}>
                              <span className="badge badge-secondary">{area.attributes['title']}</span>
                            </Link>

                          </td>

                          <td>
                            {(() =>
                            {
                              // Fase 1
                              if (normativa.attributes['ntram_p1_period'])
                              {
                                const dateStart = new Date(normativa.attributes['ntram_p1_period']['value']).getTime();
                                const dateEnd = new Date(normativa.attributes['ntram_p1_period']['end_value']).getTime();
                                const dateNow = new Date().getTime();
                                const inTime = (dateNow >= dateStart && dateNow <= dateEnd);
                                const attached = [];

                                normativa.relationships['ntram_p1_files'].data.map((aux =>
                                {
                                  let obj = files.data.find(f => f.id === aux.id);
                                  obj.attributes.description = aux.meta.description;
                                  attached.push(obj);
                                }));

                                return (
                                  <React.Fragment>
                                <span className={(inTime) ? 'badge badge-success' : 'badge badge-danger'}>
                                  Inici expo: <Moment format={'DD-MM-YYYY'} date={dateStart}/>
                                </span>
                                    <span
                                      className={(inTime) ? 'badge badge-success' : 'badge badge-danger'}>
                                  Fi expo: <Moment format={'DD-MM-YYYY'} date={dateEnd}/>
                                </span>

                                    {attached.map((f, key) =>
                                    {
                                      return (
                                        <div key={key}>
                                          <a href={API_APP_URL + f.attributes.uri.url}
                                             target={'_blank'}>
                                        <span
                                          className="badge badge-secondary">{renderFileAttachmentIcon(f.attributes.filename)} {f.attributes.description}</span>
                                          </a>
                                        </div>
                                      );
                                    })}

                                    {normativa.attributes['ntram_p1_link'] &&
                                    <a href={normativa.attributes['ntram_p1_link'].uri}
                                       target={'_blank'}>
                                      <span className="badge badge-info">Seu electrònica</span>
                                    </a>
                                    }
                                  </React.Fragment>
                                );
                              }
                            })()}
                          </td>

                          <td>
                            {(() =>
                            {
                              // Fase 2
                              if (normativa.attributes['ntram_p2_period'])
                              {
                                const dateStart = new Date(normativa.attributes['ntram_p2_period']['value']).getTime();
                                const dateEnd = new Date(normativa.attributes['ntram_p2_period']['end_value']).getTime();
                                const dateNow = new Date().getTime();
                                const inTime = (dateNow >= dateStart && dateNow <= dateEnd);
                                const attached = [];

                                normativa.relationships['ntram_p2_files'].data.map((aux =>
                                {
                                  let obj = files.data.find(f => f.id === aux.id);
                                  obj.attributes.description = aux.meta.description;
                                  attached.push(obj);
                                }));

                                return (
                                  <React.Fragment>

                                <span className={(inTime) ? 'badge badge-success' : 'badge badge-danger'}>
                                  Inici expo: <Moment format={'DD-MM-YYYY'} date={dateStart}/>
                                </span>

                                    <span className={(inTime) ? 'badge badge-success' : 'badge badge-danger'}>
                                  Fi expo: <Moment format={'DD-MM-YYYY'} date={dateEnd}/>
                                </span>

                                    {attached.map((f, key) =>
                                    {
                                      return (
                                        <div key={key}>
                                          <a href={API_APP_URL + f.attributes.uri.url}
                                             target={'_blank'}>
                                        <span
                                          className="badge badge-secondary">{renderFileAttachmentIcon(f.attributes.filename)} {f.attributes.description}</span>
                                          </a>
                                        </div>
                                      );
                                    })}

                                    {normativa.attributes['ntram_p2_link'] &&
                                    <a href={normativa.attributes['ntram_p2_link'].uri}
                                       target={'_blank'}>
                                      <span className="badge badge-info">Seu electrònica</span>
                                    </a>
                                    }
                                  </React.Fragment>
                                );
                              }
                            })()}
                          </td>

                          <td>
                            {(() =>
                            {
                              // Fase 3
                              // if (normativa.attributes['ntram_p3_period'])
                              // {
                                // const dateStart = new Date(normativa.attributes['ntram_p3_period']['value']).getTime();
                                // const dateEnd = new Date(normativa.attributes['ntram_p3_period']['end_value']).getTime();
                                // const dateNow = new Date().getTime();
                                // const inTime = (dateNow >= dateStart && dateNow <= dateEnd);
                                // const dateBOP = new Date(normativa.attributes['ntram_p3_date_bop']['value']).getTime();
                                // const dateDOGC = new Date(normativa.attributes['ntram_p3_date_dogc']['value']).getTime();
                                // const dateETAULER = new Date(normativa.attributes['ntram_p3_date_etauler']['value']).getTime();
                                // const dateDiari = new Date(normativa.attributes['ntram_p3_date_diari']['value']).getTime();
                                const attached = [];

                                normativa.relationships['ntram_p3_files'].data.map((aux =>
                                {
                                  let obj = files.data.find(f => f.id === aux.id);
                                  obj.attributes.description = aux.meta.description;
                                  attached.push(obj);
                                }));

                                return (
                                  <React.Fragment>

                                {/*<span className={(inTime) ? 'badge badge-success' : 'badge badge-danger'}>*/}
                                  {/*Inici expo: <Moment format={'DD-MM-YYYY'} date={dateStart}/>*/}
                                {/*</span>*/}

                                    {/*<span className={(inTime) ? 'badge badge-success' : 'badge badge-danger'}>*/}
                                  {/*Fi expo: <Moment format={'DD-MM-YYYY'} date={dateEnd}/>*/}
                                {/*</span>*/}

                                    {attached.map((f, key) =>
                                    {
                                      return (
                                        <div key={key}>
                                          <a href={API_APP_URL + f.attributes.uri.url}
                                             target={'_blank'}>
                                        <span
                                          className="badge badge-secondary">{renderFileAttachmentIcon(f.attributes.filename)} {f.attributes.description}</span>
                                          </a>
                                        </div>
                                      );
                                    })}

                                    {normativa.attributes['ntram_p3_date_bop'] &&
                                    <span className="badge badge-info">Data BOP: <Moment format={'DD-MM-YYYY'}
                                                                                         date={new Date(normativa.attributes['ntram_p3_date_bop']).getTime()}/></span>
                                    }
                                    {normativa.attributes['ntram_p3_date_dogc'] &&
                                    <span className="badge badge-info">Data DOCG: <Moment format={'DD-MM-YYYY'}
                                                                                          date={new Date(normativa.attributes['ntram_p3_date_dogc']).getTime()}/></span>
                                    }
                                    {normativa.attributes['ntram_p3_date_etauler'] &&
                                    <span className="badge badge-info">Data eTAULER: <Moment format={'DD-MM-YYYY'}
                                                                                             date={new Date(normativa.attributes['ntram_p3_date_etauler']).getTime()}/></span>
                                    }
                                    {normativa.attributes['ntram_p3_date_diari'] &&
                                    <span className="badge badge-info">Data diari: <Moment format={'DD-MM-YYYY'}
                                                                                           date={new Date(normativa.attributes['ntram_p3_date_diari']).getTime()}/></span>
                                    }

                                    {normativa.attributes['ntram_p3_link'] &&
                                    <a href={normativa.attributes['ntram_p3_link'].uri}
                                       target={'_blank'}>
                                      <span className="badge badge-info">Seu electrònica</span>
                                    </a>
                                    }
                                  </React.Fragment>
                                );
                              // }
                            })()}
                          </td>

                          <td>
                            {(() =>
                            {
                              const attached = [];
                              normativa.relationships['ntram_p4_files'].data.map((aux =>
                              {
                                let obj = files.data.find(f => f.id === aux.id);
                                obj.attributes.description = aux.meta.description;
                                attached.push(obj);
                              }));

                              return (
                                <React.Fragment>
                                  {(normativa.attributes['ntram_p4_link_bop']) ?
                                    <a href={normativa.attributes['ntram_p4_link_bop'].uri}
                                       target={'_blank'}>
                                      <span className="badge badge-info">Enllaç BOP</span>
                                    </a> : null
                                  }
                                  {(normativa.attributes['ntram_p4_link_dogc']) ?
                                    <a href={normativa.attributes['ntram_p4_link_dogc'].uri}
                                       target={'_blank'}>
                                      <span className="badge badge-info">Enllaç DOCG</span>
                                    </a> : null
                                  }
                                  {(normativa.attributes['ntram_p4_link_etauler']) ?
                                    <a href={normativa.attributes['ntram_p4_link_etauler'].uri}
                                       target={'_blank'}>
                                      <span className="badge badge-info">Enllaç eTAULER</span>
                                    </a> : null
                                  }
                                  {attached.map((f, key) =>
                                  {
                                    return (
                                      <div key={key}>
                                        <a href={API_APP_URL + f.attributes.uri.url}
                                           target={'_blank'}>
                                      <span className="badge badge-secondary">
                                        {renderFileAttachmentIcon(f.attributes.filename)} {f.attributes.description}
                                        </span>
                                        </a>
                                      </div>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })()}

                          </td>
                        </tr>
                      );
                    }
                  })}
                  </tbody>
                </table>

              </div>
            </div>
          </Fullscreen>
        </div>


        {/*<h3><FormattedMessage id={'app.ajuntament.normativa.tramitacio.fase3.title'}/></h3>*/}

        {/*<div className={'mt-3'}>*/}
        {/*<table className={'table mt-4'}>*/}
        {/*<thead>*/}
        {/*<tr>*/}
        {/*<th width="70%">Descripció</th>*/}
        {/*<th width="30%">Publicacions</th>*/}
        {/*</tr>*/}
        {/*</thead>*/}
        {/*<tbody>*/}

        {/*{normativaTram.data.map((normativa, key) =>*/}
        {/*{*/}
        {/*if (normativa.attributes.link_bop*/}
        {/*|| normativa.attributes.link_dogc*/}
        {/*|| normativa.attributes.link_etauler*/}
        {/*)*/}
        {/*{*/}
        {/*return (*/}
        {/*<tr key={key}>*/}
        {/*<td>*/}
        {/*<span>{normativa.attributes.summary}</span>*/}
        {/*</td>*/}
        {/*<td>*/}
        {/*{(normativa.attributes.link_bop) ?*/}
        {/*<a href={normativa.attributes.link_bop.uri} target={'_blank'}>*/}
        {/*<span className="badge badge-danger">BOP</span>*/}
        {/*</a> : null*/}
        {/*}*/}
        {/*{(normativa.attributes.link_dogc) ?*/}
        {/*<a href={normativa.attributes.link_dogc.uri} target={'_blank'}*/}
        {/*style={{marginLeft: '10px'}}>*/}
        {/*<span className="badge badge-secondary">DOCG</span>*/}
        {/*</a> : null*/}
        {/*}*/}
        {/*{(normativa.attributes.link_etauler) ?*/}
        {/*<a href={normativa.attributes.link_etauler.uri} target={'_blank'}*/}
        {/*style={{marginLeft: '10px'}}>*/}
        {/*<span className="badge badge-info">eTAULER</span>*/}
        {/*</a> : null*/}
        {/*}*/}
        {/*</td>*/}
        {/*</tr>*/}
        {/*);*/}
        {/*}*/}
        {/*})}*/}

        {/*</tbody>*/}
        {/*</table>*/}
        {/*</div>*/}

        {/*</div>*/}

      </div>
    );
  }


  //==================================================================================================================
  //== Render section with attached files
  //==================================================================================================================
  renderSectionAttachedFiles(data, description, fileType)
  {
    const files = this.getFiles(data, fileType);
    let body = null;
    if (data.attributes && data.attributes[description] && data.attributes[description].value)
    {
      body = DOMPurify.sanitize(data.attributes[description].value, {
        ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
        ADD_ATTR: DOM_PURIFY_ADD_ATTR
      });
    }

    return (
      <div>

        <div className={'mt-2 html-content'}>
          {(body) ? <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/> :
            <p>Sense dades.</p>}
        </div>

        {(files.length > 0) ?
          <div className={'mt-3'}>

            <table className={'table mt-4'}>
              <thead>
              <tr>
                <th width="75%">Descripció</th>
                <th width="10%">Fitxer</th>
                <th width="15%">Mida</th>
              </tr>
              </thead>
              <tbody>
              {files.map((file, key) =>
              {
                return (
                  <Fade key={key}>
                    <tr>
                      <td>
                        <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                          {(file.attributes.description) ? file.attributes.description : file.attributes.filename}
                        </a>
                      </td>
                      <td>
                        <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                          {renderFileAttachmentIcon(file.attributes.filename)}
                        </a>
                      </td>
                      <td>{filesize(file.attributes.filesize, {exponent: 2})}</td>
                    </tr>
                  </Fade>
                );
              })}
              </tbody>
            </table>

          </div>
          : null
        }

      </div>
    );
  }


  //==================================================================================================================
  //== Render component
  //==================================================================================================================
  render()
  {
    const {normativa, isReading} = this.props;
    const {tramitacioModalOpen} = this.state;
    return (
      <div>

        <Modal show={tramitacioModalOpen} onHide={() => this.setState({tramitacioModalOpen: false})}>
          <Modal.Header closeButton>
            <Modal.Title>Càmera web en manteniment</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => this.setState({tramitacioModalOpen: false})}>Tancar</Button>
          </Modal.Footer>
        </Modal>

        <Post data={normativa.data[0]} isReading={isReading}/>

        <div className={'row mt-4'}>
          <div className={'col-12'}>

            {(isReading) ? <Loader/>
              :
              (normativa.data.length > 0)
                ?
                <div>
                  {(normativa.data[0].relationships.reg_organic_files.data.length > 0) ?
                    <div className={'mb-5'}>
                      <div>
                        <h2><FormattedMessage
                          id={'app.ajuntament.normativa.reglaments-organics.title'}/></h2>
                        {this.renderSectionAttachedFiles(normativa.data[0], null, 'reg_organic_files')}
                      </div>
                    </div> : null
                  }
                  {(normativa.data[0].relationships.reg_fiscals_files.data.length > 0) ?
                    <div className={'mb-5'}>
                      <div>
                        <h2><FormattedMessage
                          id={'app.ajuntament.normativa.ordenances-fiscals.title'}/></h2>
                        {this.renderSectionAttachedFiles(normativa.data[0], 'description_reg_fiscals', 'reg_fiscals_files')}
                      </div>
                    </div> : null
                  }
                  {(normativa.data[0].relationships.reg_urbanisme_files.data.length > 0) ?
                    <div className={'mb-5'}>
                      <div>
                        <h2><FormattedMessage
                          id={'app.ajuntament.normativa.ordenances-urbanisme.title'}/></h2>
                        {this.renderSectionAttachedFiles(normativa.data[0], 'description_reg_urbanisme', 'reg_urbanisme_files')}
                      </div>
                    </div> : null
                  }
                  {(normativa.data[0].relationships.reg_policia_files.data.length > 0) ?
                    <div className={'mb-5'}>
                      <div>
                        <h2><FormattedMessage
                          id={'app.ajuntament.normativa.ordenances-policia.title'}/></h2>
                        {this.renderSectionAttachedFiles(normativa.data[0], 'description_reg_policia', 'reg_policia_files')}
                      </div>
                    </div> : null
                  }
                  <div>
                    <div>
                      <h2><FormattedMessage
                        id={'app.ajuntament.normativa.tramitacio.title'}/></h2>
                      {this.renderSectionNormativaTramitacio(normativa.data[0], 'description_reg_tram', 'reg_tram_files')}
                    </div>
                  </div>
                </div>
                :
                <div>
                  <p>No hi ha dades disponibles.</p>
                </div>
            }

          </div>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps)(Normativa);