import React from 'react';
import connect from "react-redux/es/connect/connect";
import {readEndpoint} from "redux-json-api";
import Post from "../../../components/Post";
import Loader from "../../../components/Loader";
import {API_APP_URL} from "../../../Constants";
import Fade from "react-reveal/Fade";
import {renderFileAttachmentIcon} from "../../../utils";
import filesize from "filesize";
import {FormattedMessage} from "react-intl";

//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const isReading = (state.api.isReading > 0);
  const pressupost = state.api['pressupost'] || {data: []};
  const pressupostDetall = state.api['budget-details'] || {data: []};
  const files = state.api['file'] || {data: []};

  if (pressupost.data.length > 0)
  {
    pressupost.data[0].relationships['budgets'].data = pressupost.data[0].relationships['budgets'].data.reverse();
  }

  return {
    isReading,
    pressupost,
    pressupostDetall,
    files
  }
};

//======================================================================================================================
//==
//======================================================================================================================
class Pressupost extends React.Component
{

  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {
      treemapData: {},
      tipusPartida: "I"
    };
  }

  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    // TEST
    // let that = this;
    // const api = 'https://cors-anywhere.herokuapp.com/http://dadesobertes.seu-e.cat/api/action/datastore_search?resource_id=595f8620-7a3c-4e38-8be2-7cffa744cd44&filters={%22CODI_ENS%22:%221703400000%22,%22ANY_EXERCICI%22:%222019%22}';
    // function getRemoteData(data, url)
    // {
    //     axios.get(url)
    //         .then(function (response)
    //         {
    //             console.log(response);
    //             if (response.data && response.data.result && response.data.result.records && response.data.result.records.length > 0)
    //             {
    //                 data = data.concat(response.data.result.records);
    //                 if (data.length < response.data.result.total)
    //                 // if (response.data.result._links.next)  // fer segons el total
    //                 {
    //                     let nextUrl = 'https://cors-anywhere.herokuapp.com/http://dadesobertes.seu-e.cat' + response.data.result._links.next;
    //                     getRemoteData(data, nextUrl);
    //                 }
    //                 else
    //                 {
    //                     console.log(JSON.stringify(data));
    //
    //                     // Do something
    //                 }
    //             }
    //             else
    //             {
    //                 console.error("No data from server.");
    //             }
    //
    //             that.setState({
    //                 // actes: actes
    //             });
    //         })
    //         .catch(function (error)
    //         {
    //             console.log(error);
    //         })
    //         .then(function ()
    //         {
    //             // always executed
    //         });
    // }
    //
    // let tree = [];
    // getRemoteData(tree, api);
    // console.log(tree);
    // END TEST


    // if (!this.props.pressupost || this.props.pressupost.data.length <= 0)
    // {
    // console.info('Retrieving pressupost section.');
    // this.props.dispatch(readEndpoint('/node/pressupost?include=highlighted_files,pay_files,budgets,budgets.files'));
    this.props.dispatch(readEndpoint('/node/pressupost?include=highlighted_files,pay_files,budgets,budgets.files,budgets.files_exec'));
    // }
  }

  //==================================================================================================================
  //== Link files with data
  //==================================================================================================================
  getFiles(data, fileType)
  {
    const {files} = this.props;

    let attached = [];
    if (data.relationships[fileType] &&
      data.relationships[fileType].data &&
      data.relationships[fileType].data.length > 0)
    {
      data.relationships[fileType].data.map(file =>
      {
        if (file.type === 'file' && files && files.data && files.data.length > 0)
        {
          let obj = files.data.find(aux => (aux.id === file.id));
          if (obj)
          {
            obj.attributes.description = file.meta.description;
            attached.push(obj);
          }
        }
      });
    }

    return attached;
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  genTreemap(data)
  {
    // let response = {data: {result: {records: testdata}}};
    let response = {data: {result: {records: data}}};
    let treeIngressos = {
      name: 'Pressupost - Ingressos',
      children: []
    };
    let treeDespeses = {
      name: 'Pressupost - Despeses',
      children: []
    };

    let minLevel = parseInt(response.data.result.records.reduce((prev, current) =>
      (parseInt(prev['NIVELL']) < parseInt(current['NIVELL'])) ? prev : current)['NIVELL']);

    let maxLevel = parseInt(response.data.result.records.reduce((prev, current) =>
      (parseInt(prev['NIVELL']) > parseInt(current['NIVELL'])) ? prev : current)['NIVELL']);

    for (let currentLevel = minLevel; currentLevel <= maxLevel; currentLevel++)
    {
      for (let i = 0; i < response.data.result.records.length; i++)
      {
        let record = response.data.result.records[i];
        let level = parseInt(record['NIVELL']);

        if (level === currentLevel)
        {
          let tree = (record['TIPUS_PARTIDA'] === "D") ? treeDespeses : treeIngressos;
          let value = parseFloat(record['IMPORT']);

          // Insert root node
          if (level === minLevel)
          {
            tree.children.push({
              year: record['ANY_EXERCICI'],
              partida: record['TIPUS_PARTIDA'],
              classif: record['TIPUS_CLASSIF'],
              level: level,
              name: record['DESCRIPCIO'],
              structure: record['ESTRUCTURA'],
              value: value,
              children: []
            });
            response.data.result.records.splice(i, 1);
          }

          // Link node with parent
          else if (level > minLevel)
          {
            let currentTree = tree;
            for (let j = minLevel; j < level; ++j)
            {
              for (let child of currentTree.children)
              {
                if (record['ESTRUCTURA'].startsWith(child.structure))
                {
                  currentTree = child;
                  if (j === level - 1)
                  {
                    child.children.push({
                      year: record['ANY_EXERCICI'],
                      partida: record['TIPUS_PARTIDA'],
                      classif: record['TIPUS_CLASSIF'],
                      level: level,
                      name: record['DESCRIPCIO'],
                      structure: record['ESTRUCTURA'],
                      value: value,
                      children: []
                    });
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    return ({
      pressupostIngressos: treeIngressos,
      pressupostDespeses: treeDespeses
    });
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  handleTipusPartidaIngressos()
  {
    this.setState({tipusPartida: "I"});
  }

  //==================================================================================================================
  //==
  //==================================================================================================================
  handleTipusPartidaDespeses()
  {
    this.setState({tipusPartida: "D"});
  }

  //==================================================================================================================
  //== Render section with attached files
  //==================================================================================================================
  renderSectionAttachedFiles(data, description, fileType)
  {
    const files = this.getFiles(data, fileType);
    // let body = null;
    // if (data.attributes && data.attributes[description] && data.attributes[description].value)
    // {
    //     body = DOMPurify.sanitize(data.attributes[description].value, {
    //         ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
    //         ADD_ATTR: DOM_PURIFY_ADD_ATTR
    //     });
    // }

    return (
      <div>

        {/*<div className={'mt-2 html-content'}>*/}
        {/*{(body) ? <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/> :*/}
        {/*<p>Sense dades.</p>}*/}
        {/*</div>*/}

        {(files.length > 0) ?
          <div className={'mt-3'}>

            {(fileType === 'files_exec') ? <h5 className={'mt-4'}>Execució pressupostària</h5> : null}

            <table className={'table mt-4'}>
              <thead>
              <tr>
                <th width="75%">Descripció</th>
                <th width="10%">Fitxer</th>
                <th width="15%">Mida</th>
              </tr>
              </thead>
              <tbody>
              {files.map((file, key) =>
              {
                return (
                  <Fade key={key}>
                    <tr>
                      <td>
                        <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                          {(file.attributes.description) ? file.attributes.description : file.attributes.filename}
                        </a>
                      </td>
                      <td>
                        <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                          {renderFileAttachmentIcon(file.attributes.filename)}
                        </a>
                      </td>
                      <td>{filesize(file.attributes.filesize, {exponent: 2})}</td>
                    </tr>
                  </Fade>
                );
              })}
              </tbody>
            </table>

          </div>
          : null
        }

      </div>
    );
  }

  //==================================================================================================================
  //== Render component
  //==================================================================================================================
  render()
  {
    const {isReading, pressupost, pressupostDetall} = this.props;
    return (
      <div>

        <Post data={pressupost.data[0]} isReading={isReading}/>

        <div className={'row mb-4'}>
          <div className={'col-12'}>

            {(isReading)
              ? <Loader/>
              :
              <div className={'mt-4'}>

                {(pressupost.data.length > 0)
                  ?
                  <div>
                    <div className={'mb-5'}>
                      <div>
                        <h2><FormattedMessage id={'app.ajuntament.pressupost.destacats.title'}/>
                        </h2>
                        {this.renderSectionAttachedFiles(pressupost.data[0], '', 'highlighted_files')}
                      </div>
                    </div>

                    <div className={'mb-5'}>
                      <div>
                        <h2><FormattedMessage id={'app.ajuntament.pressupost.pmp.title'}/></h2>
                        {this.renderSectionAttachedFiles(pressupost.data[0], '', 'pay_files')}
                      </div>
                    </div>

                    <div>
                      {(pressupostDetall.data.length > 0)
                        ?
                        <div>
                          {(pressupost.data[0].relationships['budgets'].data.length > 0)
                            ?
                            <div>
                              {pressupost.data[0].relationships['budgets'].data.map((p, key) =>
                              {
                                const budget = pressupostDetall.data.find(b => b.id === p.id);
                                return (
                                  <div className={'mb-5'} key={key}>
                                    <div>
                                      <h2>{budget['attributes']['title']}</h2>
                                      {this.renderSectionAttachedFiles(budget, '', 'files')}
                                      {this.renderSectionAttachedFiles(budget, '', 'files_exec')}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            : null
                          }
                        </div>
                        : null
                      }
                    </div>
                  </div>
                  : null
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Pressupost);