import React from "react";
import { Link } from "react-router-dom";
import { API_APP_URL } from "../Constants";
import Fade from "react-reveal/Fade";

//======================================================================================================================
//== Converteix el número de tinencia d'alcaldia en text
//======================================================================================================================
const tinentAlcaldeToName = (numTinent, sex) => {
  switch (numTinent) {
    case 1:
      return sex === "male"
        ? "Primer tinent d'alcalde"
        : "Primera tinent d'alcalde";
    case 2:
      return sex === "male"
        ? "Segon tinent d'alcalde"
        : "Segona tinent d'alcalde";
    case 3:
      return sex === "male"
        ? "Tercer tinent d'alcalde"
        : "Tercera tinent d'alcalde";
    case 4:
      return sex === "male"
        ? "Quart tinent d'alcalde"
        : "Quarta tinent d'alcalde";
    case 5:
      return sex === "male"
        ? "Cinquè tinent d'alcalde"
        : "Cinquena tinent d'alcalde";
    case 6:
      return sex === "male"
        ? "Sisè tinent d'alcalde"
        : "Sisena tinent d'alcalde";
    case 7:
      return sex === "male"
        ? "Setè tinent d'alcalde"
        : "Setena tinent d'alcalde";
  }
};

//======================================================================================================================
//== Component Regidor
//======================================================================================================================
const Regidor = ({ regidor, grups, arees, unitats, files }) => {
  // let candidat = candidats.data.find(c => c.id === regidor.relationships.candidate.data.id);
  // let candidatura = candidatures.data.find(c => c.id === candidat.relationships.candidatura.data.id);
  let imageUrl = "https://via.placeholder.com/200x250"; // default image

  //   console.log(regidor);
  //   console.log(grups.data);
  //   console.log(regidor.relationships.grup.data?.id);
  let grup;
  if (regidor.relationships.grup.data?.id) {
    grup = grups.data.find((g) => g.id === regidor.relationships.grup.data.id);
  }

  if (files.data) {
    let image = files.data.find(
      (obj) => obj.id === regidor.relationships.photo.data.id
    );
    imageUrl = API_APP_URL + image.attributes.uri.url;
    imageUrl = imageUrl.replace("files/", "files/styles/regidor/public/"); // TODO: FIX IT! NO HI HA COMPATIBILITAT AMB JSONAPI
  }

  // let denomAlcalde = (candidat.attributes.sex === 'male') ? 'Alcalde' : 'Alcaldessa';

  let areesAux = [];
  for (let area of regidor.relationships.areas.data) {
    for (let aux of arees.data) {
      if (area.id === aux.id) {
        areesAux.push(aux);
        break;
      }
    }
  }
  areesAux = areesAux.sort((a, b) => {
    let a1 = a.attributes.title.toLowerCase();
    let b1 = b.attributes.title.toLowerCase();
    return a1 < b1 ? 1 : a1 > b1 ? -1 : 0;
  });

  let unitatsAux = [];
  for (let unitat of regidor.relationships.ou.data) {
    for (let aux of unitats.data) {
      if (unitat.id === aux.id) {
        unitatsAux.push(aux);
        break;
      }
    }
  }

  unitatsAux = unitatsAux.sort((a, b) => {
    let a1 = a.attributes.title.toLowerCase();
    let b1 = b.attributes.title.toLowerCase();
    return a1 < b1 ? 1 : a1 > b1 ? -1 : 0;
  });

  let areesFirst = true;
  let unitatFirst = areesAux.length === 0;

  return (
    <Fade>
      <div className={"mb-4"}>
        <div className={"row mt-2"}>
          <div className={"col-12 col-sm-4"}>
            <div className={"img"}>
              <img src={imageUrl} className={"img-fluid"} />
            </div>
          </div>

          <div className={"col-12 col-sm-8"}>
            <div>
              <h3>
                <strong>{regidor.attributes.name}</strong>
              </h3>
            </div>

            {regidor.attributes.is_alcalde ? (
              <div className={"mt-2"}>
                <h4>
                  <strong>Alcalde</strong>
                </h4>
              </div>
            ) : null}

            {regidor.attributes.tinent ? (
              <div className={"mt-2"}>
                <h4>
                  <strong>{regidor.attributes.tinent}</strong>
                </h4>
              </div>
            ) : null}

            <div className={"mt-2"}>
              <h4>Grup Municipal</h4>
              {regidor.attributes.is_no_adscrit ? (
                <p>Regidor no adscrit</p>
              ) : (
                <p>{grup?.attributes.title}</p>
              )}
            </div>

            <div className={"mt-2"}>
              <h4>Àrees i Unitats delegades</h4>
              {regidor.relationships.areas.data.length <= 0 &&
              regidor.relationships.ou.data.length <= 0 ? (
                <span>No té àrees ni unitats delegades</span>
              ) : null}
              {areesAux.map((area, key) => {
                if (areesFirst) {
                  areesFirst = !areesFirst;
                  return (
                    <Link key={key} to={"/ajuntament/arees/area/" + area.id}>
                      <span
                        className={"badge badge-sm badge-secondary"}
                        style={{ fontWeight: 400 }}
                      >
                        {area.attributes.title}
                      </span>
                    </Link>
                  );
                } else {
                  return (
                    <React.Fragment key={key}>
                      ,{" "}
                      <Link to={"/ajuntament/arees/area/" + area.id}>
                        <span
                          className={"badge badge-sm badge-secondary"}
                          style={{ fontWeight: 400 }}
                        >
                          {area.attributes.title}
                        </span>
                      </Link>
                    </React.Fragment>
                  );
                }
              })}
              {unitatsAux.map((unitat, key) => {
                if (unitatFirst) {
                  unitatFirst = !unitatFirst;
                  return (
                    <Link
                      key={key}
                      to={"/ajuntament/arees/unitat/" + unitat.id}
                    >
                      <span
                        className={"badge badge-sm badge-secondary"}
                        style={{ fontWeight: 400 }}
                      >
                        {unitat.attributes.title}
                      </span>
                    </Link>
                  );
                } else {
                  return (
                    <React.Fragment key={key}>
                      ,{" "}
                      <Link to={"/ajuntament/arees/unitat/" + unitat.id}>
                        <span
                          key={key}
                          className={"badge badge-sm badge-secondary"}
                          style={{ fontWeight: 400 }}
                        >
                          {unitat.attributes.title}
                        </span>
                      </Link>
                    </React.Fragment>
                  );
                }
              })}
            </div>

            {regidor.attributes.regidoria ? (
              <div className={"mt-2"}>
                <h4>Regidories</h4>
                {regidor.attributes.regidoria}
              </div>
            ) : null}

            {regidor.attributes.email ? (
              <div className={"mt-2"}>
                <h4>Correu electrònic</h4>
                <p>
                  <a href={"mailto:" + regidor.attributes.email} target="_top">
                    {regidor.attributes.email}
                  </a>
                </p>
              </div>
            ) : null}

            {regidor.attributes.phone ? (
              <div className={"mt-2"}>
                <h4>Telèfon</h4>
                <span itemProp="telephone">
                  <a href={"tel:+34" + regidor.attributes.phone}>
                    +34 {regidor.attributes.phone}
                  </a>
                </span>
              </div>
            ) : null}

            {regidor.attributes.bio ? (
              <div className={"mt-2"}>
                <h4>Biografia</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: regidor.attributes.bio.value,
                  }}
                  style={{ textAlign: "justify" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Fade>
  );
};

Regidor.propTypes = {};

export default Regidor;
