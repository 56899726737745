import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import {Timeline} from 'react-twitter-widgets';
import {FacebookProvider, Page} from "react-facebook";
import PostSidebar from "../../../components/PostSidebar";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const alcaldia = state.api['alcaldia'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        alcaldia,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Alcaldia extends React.Component
{

    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/alcaldia?include=image'));
    }


    renderTwitterTimeline(twitter)
    {
        return (
            <div className={'twitter-timeline-widget'} style={{backgroundColor: 'aliceblue'}}>
                <Timeline
                    dataSource={{
                        sourceType: 'profile',
                        screenName: twitter
                    }}
                    options={{
                        username: twitter,
                        width: '100%',
                        height: '600',
                        // chrome: 'noheader nofooter noscrollbar noborders'
                        chrome: 'noheader nofooter noborders transparent',

                    }}
                    onLoad={() =>
                    {
                        this.state.isLoadingTwitter = false;
                    }}
                />
            </div>
        );
    }


    renderFacebookTimeline(fbAppId, fbPage)
    {
        return (
            <div>
                <FacebookProvider appId={fbAppId}>
                    <Page href={'https://www.facebook.com/' + fbPage + '/'} tabs={'timeline'}/>
                </FacebookProvider>
            </div>
        );
    }


    renderSidebar()
    {
        const {alcaldia} = this.props;

        let twitter, fbAppId, fbPage;
        if (alcaldia.data.length > 0)
        {
            twitter = alcaldia.data[0]['attributes']['twitter'];
            fbAppId = alcaldia.data[0]['attributes']['fb_app_id'];
            fbPage = alcaldia.data[0]['attributes']['fb_page_name'];
        }

        const isTwitterActive = alcaldia && alcaldia.data && alcaldia.data.length > 0 && twitter;
        const isFacebookActive = alcaldia && alcaldia.data && alcaldia.data.length > 0 && fbAppId && fbPage;

        return (
            <div>
                {(isTwitterActive) ? <div>{this.renderTwitterTimeline(twitter)}</div> : null}
                {(isFacebookActive) ?
                    <div style={{paddingLeft: '10px', marginTop: (isTwitterActive) ? '50px' : '0'}}>
                        {this.renderFacebookTimeline(fbAppId, fbPage)}
                    </div>
                    : null
                }
            </div>
        );
    }


    //==================================================================================================================
    //== Render component
    //==================================================================================================================
    render()
    {
        return (
            <PostSidebar data={this.props.alcaldia.data[0]}
                         files={this.props.files.data}
                         sidebar={this.renderSidebar()}/>
        );
    }
}

export default connect(mapStateToProps)(Alcaldia);