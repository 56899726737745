import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';


class ScrollToTop extends Component
{
  componentDidUpdate(prevProps, prevState, snapshot)
  {
    // console.log(this.props);
    if (this.props.location !== prevProps.location && !this.props.location.pathname.startsWith('/ajuntament/serveis/'))
    {
      window.scrollTo(0, 0);
    }
  }

  render()
  {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);