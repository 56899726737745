import React from 'react';
import Moment from 'moment';
import {FormattedMessage} from 'react-intl';
import {
    API_APP_URL,
    API_DIBA_OFERTA_PUBLICA, API_DIBA_OFERTA_PUBLICA_NEW,
    DOM_PURIFY_ADD_ATTR,
    DOM_PURIFY_ALLOWED_TAGS
} from '../../../Constants';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import AnunciOfertaOcupacioEntity from "../../../entity/AnunciOfertaOcupacioEntity";
import OfertaOcupacioList from "../../../components/OfertaOcupacioList";
import DOMPurify from "dompurify";
import {renderAttachedFiles} from "../../../utils";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const ofertaPublica = state.api['oferta-publica'] || {data: []};
    const ofertaOcupacio = state.api['oferta-ocupacio'] || {data: []};
    const files = state.api['file'] || {data: []};
    return {
        ofertaPublica,
        ofertaOcupacio,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class OfertaPublica extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {
            anuncis: null,
            errorOfertaPublica: null
        };
    }

    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        //==============================================================================================================
        //== Retrieve "anuncis" from AOC OpenData (Dades Obertes)
        //==============================================================================================================
        let that = this;
        axios.get(API_DIBA_OFERTA_PUBLICA)
            .then(function (response)
            {
                // console.log(response);
                that.setState({
                    anuncis: response.data['resultat']
                });
            })
            .catch(function (error)
            {
                that.setState({errorOfertaPublica: error});
                console.error(error);
            });

        if (!this.props.ofertaPublica || this.props.ofertaPublica.data.length <= 0)
        {
            this.props.dispatch(readEndpoint('/node/oferta-publica?include=oferta,oferta.file,files_regulation'));
        }
    }


    //==================================================================================================================
    //== Render "anuncis"
    //==================================================================================================================
    renderAnuncis()
    {
        const {ofertaPublica, ofertaOcupacio, files} = this.props;
        const {anuncis, errorOfertaPublica} = this.state;

        let aux;
        let body;
        let mixedAnuncis = [];
        let attached = {files: []};
        if (ofertaPublica && ofertaPublica.data && ofertaPublica.data.length > 0 && ofertaPublica.data[0].relationships)
        {
            // Get description
            aux = (ofertaPublica.data[0].attributes['description']) ? ofertaPublica.data[0].attributes['description'].value : 'Sense descripció';
            body = DOMPurify.sanitize(aux, {ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS, ADD_ATTR: DOM_PURIFY_ADD_ATTR});

            // Get attached files
            if (ofertaPublica.data[0].relationships.files_regulation && ofertaPublica.data[0].relationships.files_regulation.data)
            {
                ofertaPublica.data[0].relationships.files_regulation.data.map(file =>
                {
                    if (file.type === 'file' && files && files.data && files.data.length > 0)
                    {
                        let obj = files.data.find(aux => (aux.id === file.id));
                        obj.attributes.description = file.meta.description;
                        attached.files.push(obj);
                    }
                });
            }

            // Fill array with own data
            for (let aux1 of ofertaPublica.data[0].relationships.oferta.data)  // Realment no cal fer l'associacio perque nomes esta vinculat amb aquesta entitat, pero per bon disseny i seguretat es fa aixi
            {
                for (let aux2 of ofertaOcupacio.data)
                {
                    if (aux1.id === aux2.id)
                    {
                        let datePub = (aux2.attributes.date_range.value) ? Moment(aux2.attributes.date_range.value, "YYYY-MM-DD") : null;
                        let dateEnd = (aux2.attributes.date_range.end_value) ? Moment(aux2.attributes.date_range.end_value, "YYYY-MM-DD") : null;
                        const file = files.data.find((file) => file.id === aux2.relationships.file.data.id);
                        const link = (file) ? API_APP_URL + file.attributes.uri.url : null;
/*
                        datePub = datePub && datePub.isValid() ? datePub.format('DD-MM-YYYY') : 'Veure taulell d\'anuncis'
                        dateEnd = dateEnd && dateEnd.isValid() ? dateEnd.format('DD-MM-YYYY') : 'Veure taulell d\'anuncis'
*/
                        mixedAnuncis.push(new AnunciOfertaOcupacioEntity(
                            aux2.id,
                            aux2.attributes.title,  // Falta: aux2.attributes.name
                            datePub,
                            dateEnd,
                            link,  // Link
                            aux2.attributes.status,  // Status
                            {id: 'ajuntament', name: 'Aj. Calonge'}
                        ));
                        break;
                    }
                }
            }
        }

        if (anuncis && anuncis.length > 0)
        {
            // Fill array with opendata
            for (let aux of anuncis)
            {
                let datePub = (aux['oposicio']['dataPublicacio']) ? Moment(aux['oposicio']['dataPublicacio'], "DD/MM/YYYY") : null;
                let dateEnd = (aux['oposicio']['terminiPresentacio']) ? Moment(aux['oposicio']['terminiPresentacio'], "DD/MM/YYYY") : null;
                /*console.log(datePub)
                console.log(dateEnd)
/*
                datePub = datePub && datePub.isValid() ? datePub.format('DD-MM-YYYY') : 'Veure taulell d\'anuncis'
                dateEnd = dateEnd && dateEnd.isValid() ? dateEnd.format('DD-MM-YYYY') : 'Veure taulell d\'anuncis'
                                console.log(datePub)
                                console.log(dateEnd)
*/
                mixedAnuncis.push(new AnunciOfertaOcupacioEntity(
                    aux['oposicio']['identificador'],
                    aux['oposicio']['titol'],
                    datePub,
                    dateEnd,
                    aux['oposicio']['enllac'],
                    aux['oposicio']['estat'],
                    {id: 'opendata', name: 'Opendata (DIBA)'}
                ));
            }
        }

        return (
            <div className={'mt-3'}>

                {(ofertaPublica && ofertaPublica.data && ofertaPublica.data.length > 0)
                    ?
                    <div className={'mt-2'}>
                        {(body) ? <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/> :
                            <p>Sense dades.</p>}
                    </div>
                    : <p>Carregant dades...</p>
                }

                <div className={'mt-4'}>
                    {renderAttachedFiles(attached)}
                </div>

                {(errorOfertaPublica)
                    ? <div>Error: error a l'obtenir les dades des de DIBA. Proveu a utlitzar Google Chrome o Firefox.</div>
                    : (mixedAnuncis.length > 0)
                        ? <OfertaOcupacioList anuncis={mixedAnuncis}/>
                        : <div>Carregant anuncis...</div>
                }
            </div>
        );
    }

    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {} = this.state;
        return (
            <div>
                <h1><FormattedMessage id={'app.ajuntament.oferta-publica.title'}/></h1>
                {this.renderAnuncis()}
            </div>
        );
    }
}


export default connect(mapStateToProps)(OfertaPublica);