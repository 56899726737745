import React from 'react'
import DOMPurify from "dompurify";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS, REGEX_YOUTUBE} from "../Constants";
import Fade from "react-reveal/Fade";
import Loader from "./Loader";


const renderPost = (data, image, files) =>
{
  // TODO: Unificar tipus de camps
  let text = null;
  if (!data.attributes.body && !data.attributes.description)
  {
    text = '';
  }
  else if (!data.attributes.body)
  {
    text = data.attributes.description.value;
  }
  else
  {
    text = data.attributes.body.value;
  }

  let title = data.attributes.title;
  let body = DOMPurify.sanitize(
    text,
    {ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS, ADD_ATTR: DOM_PURIFY_ADD_ATTR});
  body = body.replace(REGEX_YOUTUBE, '<div class="videoWrapper"><iframe width="100% !important" height="auto !important" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe></div>');

  let imageUrl = null;
  if (!image && data.relationships.image && data.relationships.image.data)
  {
    let image = files.find(obj => (obj.id === data.relationships['image']['data']['id']));
    imageUrl = API_APP_URL + image.attributes.uri.url;
    imageUrl = imageUrl.replace('files/', 'files/styles/section_header/public/');  // TODO: FIX IT! NO HI HA COMPATIBILITAT AMB JSONAPI
  }

  return (
    <div>
      <Fade>
        <div>
          <h1>{title}</h1>
        </div>

        {image === null ?
          <div>
            {(imageUrl) ?
              <div id={'img-top'} className={'pt-3'}>
                <img src={imageUrl}/>
              </div>
              : null
            }
          </div> : <div>
            {(image) ?
              <div id={'img-top'} className={'pt-3'}>
                <img src={image}/>
              </div>
              : null
            }
          </div>
        }

        <div className={'html-content pt-3'}>
          <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/>
        </div>
      </Fade>
    </div>
  )
};


const Post = ({data, image, files, isReading}) =>
{
  // TODO: Falta propagar la opcio "isReading"
  if (data)
  {
    // Scroll.animateScroll.scrollToTop();
  }

  return (
    <div className="app-home">
      {(!data) ? <Loader/> : renderPost(data, image, files)}
    </div>
  );
};


Post.propTypes = {
  // title: PropTypes.string.isRequired,
  // body: PropTypes.string.isRequired
};


export default Post
