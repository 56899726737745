import React from 'react'
import PropTypes from 'prop-types'
import Moment from "moment";

class AnunciOfertaOcupacioEntity
{
    constructor (id, title, datePub, dateEnd, link, status, origin)
    {
        this._id = id;
        this._title = title;
        this._datePub = datePub;
        this._dateEnd = dateEnd;
        this._link = link;
        this._status = status;
        this._origin = origin;
    }
}

// AnunciOfertaOcupacioEntity.propTypes = {
//     id: PropTypes.string.isRequired,  // id | identificador
//     title: PropTypes.string.isRequired,  // title | titol
//     datePub: PropTypes.instanceOf(Moment).isRequired,  // date | dataPublicacio
//     dateEnd: PropTypes.instanceOf(Moment).isRequired,  // date_end | terminiPresentacio
//     dateExam: PropTypes.instanceOf(Moment),  // date_exam | dataProva
//     link: PropTypes.string.isRequired,  // link | enllac
//     status: PropTypes.string.isRequired,  // status | estat
//     origin: PropTypes.string.isRequired  // "local" | "opendata"
// };

export default AnunciOfertaOcupacioEntity