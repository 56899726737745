import React from 'react';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import {FormattedMessage} from 'react-intl';
import axios from "axios";
import {API_DIBA_CONTRACTACIO, API_SEU_E_CONTRACTACIO} from '../../../Constants';
import AnunciOfertaOcupacioEntity from "../../../entity/AnunciOfertaOcupacioEntity";
import {renderAttachedFiles} from "../../../utils";
import OfertaOcupacioList from "../../../components/OfertaOcupacioList";

//======================================================================================================================
//==
//======================================================================================================================
export default class Contractacio extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {
            anuncis: null,
            errorContractacio: null,
            isLoadedContractacio: false
        };
    }

    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        //==============================================================================================================
        //== Retrieve "contracts" from DIBA
        //==============================================================================================================
        let that = this;
        axios.get(API_DIBA_CONTRACTACIO)
            .then(function (response)
            {
                // console.log(response);
                that.setState({
                    anuncis: response.data['resultat']
                });
            })
            .catch(function (error)
            {
                that.setState({errorContractacio: error});
                console.error(error);
            });

        //==============================================================================================================
        //== Retrieve all contracts
        //==============================================================================================================
        // fetch(API_SEU_E_CONTRACTACIO,
        //     {method: 'GET', headers: {Accept: 'application/json'}})
        //     .then(res => res.json())
        //     .then(
        //         (res) =>
        //         {
        //             // console.log(res);
        //             if (!res)
        //             {
        //                 this.setState({
        //                     isLoadedContractacio: true,
        //                     errorContractacio: {message: 'Bad result'}
        //                 });
        //                 return;
        //             }
        //
        //             // Sort anuncis
        //             let sortedAnuncis = res.sort((a, b) =>
        //                 new Date(b['DATA_ANUNCI_LICITACIO']) - new Date(a['DATA_ANUNCI_LICITACIO']));
        //
        //             // Set state
        //             this.setState({
        //                 isLoadedContractacio: true,
        //                 anuncis: sortedAnuncis
        //             });
        //         },
        //         (error) =>
        //         {
        //             this.setState({
        //                 isLoadedContractacio: true,
        //                 errorContractacio: error
        //             });
        //         }
        //     )
        //     .catch((error) => console.error(error));
    }


    //==================================================================================================================
    //== Render contracts
    //==================================================================================================================
    renderContracts()
    {
        const {errorContractacio, isLoadedContractacio, anuncis} = this.state;

        // if (errorContractacio)
        // {
        //     return <div>Error: {errorContractacio.message}</div>;
        // }
        // else if (!isLoadedContractacio)
        // {
        //     return <div>Carregant llistat d'anuncis...</div>;
        // }
        // else
        // {
        //     return (
        //         <div className={'mt-3'}>
        //             {anuncis.map((data, key) =>
        //             {
        //                 let dateLicitacio = (data['DATA_ANUNCI_LICITACIO']) ?
        //                     Moment(data['DATA_ANUNCI_LICITACIO']).format('DD-MM-YYYY') : 'No informat';
        //                 let dateFormalitzacio = (data['DATA_FORMALITZACIO']) ?
        //                     Moment(data['DATA_FORMALITZACIO']).format('DD-MM-YYYY') : 'No informat';
        //
        //                 return (
        //                     <div key={key} className={'mb-3'}>
        //                         <div className={'mb-2'}>
        //                             <p><strong><a href={data['ENLLAÇ']}
        //                                           target="_blank">{data['DESCRIPCIO']}</a></strong></p>
        //                         </div>
        //                         <p>Estat: <strong>{data['ESTAT_LICITACIO']}</strong></p>
        //                         <p>Procediment: <strong>{data['PROCEDIMENT']}</strong></p>
        //                         <p>Número d'expedient: <strong>{data['NUM_EXPEDIENT']}</strong></p>
        //                         <p>Preu licitació (sense IVA): <strong><NumberFormat
        //                             value={data['PREU_LICITACIO_SENSE_IVA']}
        //                             displayType={'text'} thousandSeparator={true}
        //                             suffix={'€'}/></strong></p>
        //                         <p>Preu adjudicació (sense IVA): <strong><NumberFormat
        //                             value={data['IMPORT_ADJUDICACIO_SENSE_IVA']}
        //                             displayType={'text'} thousandSeparator={true}
        //                             suffix={'€'}/></strong></p>
        //                         <p>Data anunci de licitació: <strong>{dateLicitacio}</strong></p>
        //                         <p>Data formalització: <strong>{dateFormalitzacio}</strong></p>
        //                     </div>
        //                 )
        //             })}
        //         </div>
        //     );
        // }

        let mixedAnuncis = [];

        if (anuncis && anuncis.length > 0)
        {
            // Fill array with opendata
            for (let aux of anuncis)
            {
                const datePub = (aux['contractacio']['dataPublicacio']) ? Moment(aux['contractacio']['dataPublicacio'], "DD/MM/YYYY") : null;
                const dateEnd = (aux['contractacio']['terminiPresentacio']) ? Moment(aux['contractacio']['terminiPresentacio'], "DD/MM/YYYY") : null;
                const dataExam = (aux['contractacio']['dataProva']) ? Moment(aux['contractacio']['dataProva'], "DD/MM/YYYY") : null;
                mixedAnuncis.push(new AnunciOfertaOcupacioEntity(
                    aux['contractacio']['identificador'],
                    aux['contractacio']['titol'],
                    datePub,
                    dateEnd,
                    dataExam,
                    aux['contractacio']['enllac'],
                    aux['contractacio']['estat'],
                    {id: 'opendata', name: 'Opendata (DIBA)'}
                ));
            }
        }

        return (
            <div className={'mt-3'}>
                {(errorContractacio)
                    ? <div>Error: error a l'obtenir les dades des de DIBA. Proveu a utlitzar Google Chrome o Firefox.</div>
                    : (mixedAnuncis.length > 0)
                        ? <OfertaOcupacioList anuncis={mixedAnuncis}/>
                        : <div>Carregant anuncis...</div>
                }
            </div>
        );
    }

    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {} = this.state;
        return (
            <div>
                <h1><FormattedMessage id={'app.ajuntament.contractacio.title'}/></h1>
                {this.renderContracts()}
            </div>
        );
    }
}