import React from "react";
import {IntlProvider, addLocaleData} from "react-intl";
import ca from "react-intl/locale-data/ca";
import es from "react-intl/locale-data/es";
import caTranslation from "../i18n/ca/messages";
import esTranslation from "../i18n/es/messages";
import moment from 'moment';
import _find from 'lodash/find';


//======================================================================================================================
//== CONFIG: LOCALIZATION
//======================================================================================================================
import 'moment/locale/ca';

// moment.tz.load({
//     "version": "2018g",
//     "zones": ["Europe/Madrid"],
//     "links": [
//         'Europe/Madrid'
//     ]
// });
moment.tz.setDefault("Europe/Madrid");

addLocaleData([...ca, ...es]);
let locale = _find(['ca', 'es'], (locale) =>
{
    // return app.locale.indexOf(locale) !== -1;
    return 0 !== -1;
});

// addLocaleData([...ca, ...es]);


const Context = React.createContext();


//======================================================================================================================
//== WRAPPER
//======================================================================================================================
class IntlProviderWrapper extends React.Component
{
    constructor(...args)
    {
        super(...args);

        // Get language preferences from local storage
        const defaultLocale = localStorage.getItem('defaultLocale');
        let translation = caTranslation;
        if (defaultLocale && defaultLocale !== 'ca')
        {
            switch (defaultLocale)
            {
                case 'es':
                    translation = esTranslation;
                    break;
            }
        }

        // Declare switch language functions
        this.switchToCat = () =>
        {
            this.setState({locale: "ca", messages: caTranslation}, () => localStorage.setItem('defaultLocale', 'ca'));
        };

        this.switchToEs = () =>
        {
            this.setState({locale: "es", messages: esTranslation}, () => localStorage.setItem('defaultLocale', 'es'));
        };

        // Setup initial state
        this.state = {
            locale: (defaultLocale) ? defaultLocale : 'ca',
            messages: translation,
            switchToCat: this.switchToCat,
            switchToEs: this.switchToEs
        };
    }

    render()
    {
        const {children} = this.props;
        const {locale, messages} = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale={locale}
                    // defaultLocale={'es'}
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export {IntlProviderWrapper, Context as IntlContext};