import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";
import {API_APP_URL} from "../../../Constants";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const premsa = state.api['premsa'] || {data: []};
  const files = state.api['file'] || {data: []};

  return {
    premsa,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class Premsa extends React.Component
{

  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {};
  }


  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    if (!this.props.premsa || this.props.premsa.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/premsa?include=image'));
    }
  }


  //==================================================================================================================
  //== Render component
  //==================================================================================================================
  render()
  {
    let premsa = this.props.premsa.data
    let image = null
    if (!premsa || premsa.length === 0)
    {
      return <></>
    }
    else
    {
      premsa = premsa[0]
    }

    if (premsa.relationships.image.data)
    {
      image = this.props.files.data.find((obj) => (obj.id === premsa.relationships.image.data.id));
      image = API_APP_URL + image.attributes.uri.url;
    }

    return (<>
        <Post data={this.props.premsa.data[0]} image={image}/>
      </>
    );
  }
}

export default connect(mapStateToProps)(Premsa);