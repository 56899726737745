import React from 'react'
import isFunction from 'lodash/isFunction';
import DOMPurify from "dompurify";
import {DOM_PURIFY_ALLOWED_TAGS, DOM_PURIFY_ADD_ATTR} from "../Constants";


const renderHtml = (body) =>
{
    return (
        <div>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(body, {ALLOWED_TAGS: DOM_PURIFY_ALLOWED_TAGS, ADD_ATTR: DOM_PURIFY_ADD_ATTR})}}
               style={{textAlign: 'justify'}}/>
        </div>
    )
};


const PageSimpleBody = ({body}) =>
{
    return (
        <div id={'content'}>
            <div className={'container'}>
                <div className={'row mt-3 mt-md-5'}>

                    {/* BODY */}
                    <div className="html-content col-sm-12 mb-3 mb-md-5">
                        {(isFunction(body) ? body() : renderHtml(body))}
                    </div>
                    {/* END BODY */}

                </div>
            </div>
        </div>
    );
};


PageSimpleBody.propTypes = {
    // title: PropTypes.string.isRequired,
    // body: PropTypes.string.isRequired
};


export default PageSimpleBody
