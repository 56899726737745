import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from "react-router-dom";
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import PageSimpleHeader from "../../components/PageSimpleHeader";
import PageSimpleBody from "../../components/PageSimpleBody";
import {ROUTES} from "../../Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    // const sitemap = state.api['sitemap'] || {data: []};

    return {
        // sitemap
    }
};


//======================================================================================================================
//== AvisLegal component
//======================================================================================================================
class Sitemap extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor()
    {
        super();
        this.state = {};

        // const paths = ['/'];
        // const hostname = 'http://localhost:3000';
        // const sitemap = buildSitemap(hostname, paths);

        // console.log(sitemap);
    }


    //==================================================================================================================
    //== Retrieve data
    //==================================================================================================================
    componentWillMount()
    {
        // this.props.dispatch(readEndpoint('/node/sitemap'))
    }


    //==================================================================================================================
    //== render sitemap app
    //==================================================================================================================
    renderSitemap()
    {
        return (
            <div>

                <div className={'list-group'}>

                {ROUTES.map((menu, key) =>
                {
                    return (
                        <React.Fragment key={key}>
                            {(menu.link.startsWith('http')) ?
                                <React.Fragment>
                                    <a href={menu.link} target={'_blank'}
                                       onClick={() => this.setState({drawerOpen: false})}>
                                        <div className={'header'}>
                                            <h3><FormattedMessage id={menu.title}/></h3>
                                        </div>
                                    </a>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Link to={menu.link}
                                          onClick={() => this.setState({drawerOpen: false})}>
                                        <div className={'header'}>
                                            <h3><FormattedMessage id={menu.title}/></h3>
                                        </div>
                                    </Link>
                                </React.Fragment>
                            }
                            {menu.content.map((section, key2) =>
                            {
                                return (
                                    (section.link.startsWith('http')) ?
                                        <a key={key2} href={section.link} target={'_blank'}
                                           className={'list-group-item'}>
                                            <FontAwesomeIcon icon={section.icon} fixedWidth/>
                                            <span> </span>
                                            <FormattedMessage id={section.title}/>
                                        </a>
                                        :
                                        <Link key={key2} to={menu.link + section.link}
                                              className={'list-group-item'}>
                                            <FontAwesomeIcon icon={section.icon} fixedWidth/>
                                            <span> </span>
                                            <FormattedMessage id={section.title}/>
                                        </Link>
                                );
                            })}
                        </React.Fragment>
                    );
                })}

                </div>

            </div>
        )
    }


    //==================================================================================================================
    //== Render view
    //==================================================================================================================
    render()
    {
        let title = 'Mapa del web';
        return (
            <div>
                <PageSimpleHeader title={title}/>
                <PageSimpleBody body={this.renderSitemap}/>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Sitemap);
