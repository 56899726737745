import React from 'react'


const PageSimpleHeader = ({title}) =>
{
    return (
        <div className={'page header'}>
            <div className={'container'}>
                <div className="row">

                    {/* PAGE TITLE */}
                    <div className={'col-lg-6'}>
                        <h1 className="title">{title}</h1>
                    </div>
                    {/* END PAGE TITLE */}

                </div>
            </div>
        </div>
    );
};


PageSimpleHeader.propTypes = {
    // title: PropTypes.string.isRequired,
    // body: PropTypes.string.isRequired
};


export default PageSimpleHeader
