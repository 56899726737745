import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import {Timeline} from 'react-twitter-widgets';
import {FacebookProvider, Page} from 'react-facebook';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import Fade from 'react-reveal/Fade';
import {readEndpoint} from "redux-json-api";
import {connect} from "react-redux";
import TruncateMarkup from 'react-truncate-markup';
import {API_APP_URL, REGEX_YOUTUBE} from "../../Constants";
import {Button, Modal} from "react-bootstrap";
import moment from "moment";
import {
  filterDateEnd,
  filterDateStart,
  filterSetPageNum,
  filterSetPageSize,
  filterShowInactive,
  orderBy
} from "../../actions/event";
import * as Scroll from "react-scroll/modules";
import EventList from "../../components/EventList";
import Noticia, {getAttachments} from "../../components/Noticia";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactGA from 'react-ga';
import Loader from "../../components/Loader";
import imageMirarPlatja from '../../assets/img/calonge/mirar-la-platja.jpg';

const Events = Scroll.Events;
const ScrollElement = Scroll.Element;
let scroller = Scroll.scroller;


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const config = state.api['config'] || {data: []};
  const isReading = (state.api.isReading > 0);
  let destacats = state.api['destacat'] || {data: []};
  let events = state.api['eventFiltered'] || {data: []};
  const banners = state.api['banner'] || {data: []};
  const noticies = state.api['noticia'] || {data: []};
  const images = state.api['image'] || {data: []};  // Attached images
  const audios = state.api['media--audio'] || {data: []};  // Attached audio
  const videos = state.api['media--video'] || {data: []};  // Attached video
  const files = state.api['file'] || {data: []};  // Files
  const videoteca = state.api['video_gallery'] || {data: []};
  const pvideos = state.api['video'] || {data: []};
  const webs = state.api['web'] || {data: []};
  let websAjuntament = [];
  let websAltres = [];

  webs.data.map((web) =>
  {
    if (web.attributes.type === 'official')
    {
      websAjuntament.push(web);
    }
    else if (web.attributes.type === 'other')
    {
      websAltres.push(web);
    }
  });

  destacats.data = destacats.data.sort((a, b) => (new Date(a.attributes['order']) - new Date(b.attributes['order'])));
  websAjuntament = websAjuntament.sort((a, b) => (new Date(a.attributes['order']) - new Date(b.attributes['order'])));
  websAltres = websAltres.sort((a, b) => (new Date(a.attributes['order']) - new Date(b.attributes['order'])));

  return {
    config,
    isReading,
    destacats,
    events,
    banners,
    noticies,
    images,
    audios,
    videos,
    files,
    videoteca,
    pvideos,
    websAjuntament,
    websAltres
  }
};


//======================================================================================================================
//== Component: Inici
//======================================================================================================================
class Inici extends React.Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor()
  {
    super();
    this.state = {
      showVideo: {},  // https://github.com/xiaolin/react-image-gallery/blob/master/example/app.js
      noticiesPageSize: 3,
      noticiesPageCurrent: 0,
      noticiesCount: 0,
      noticies: [],
      noticiesPaginationSize: 7,
      noticiesPageCurrentProvisional: 0,
      noticiesPagination: []
    };
    this.facebookWidget = React.createRef();  // Trick to handle Facebook widget width
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }


  //==================================================================================================================
  //== Request data before render
  //==================================================================================================================
  componentDidMount()
  {
    //==============================================================================================================
    //== Redux + JSON api
    //==============================================================================================================
    if (!this.props.config || this.props.config.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/config'));
    }

    if (!this.props.banner || this.props.banner.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/banner?filter[status][value]=1&filter[datefilter2][condition][path]=date_range.end_value&filter[datefilter2][condition][operator]=%3E%3D&filter[datefilter2][condition][value]=' + moment().toISOString() + '&include=image,file'));
    }

    if (!this.props.destacats || this.props.destacats.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/destacat?filter[status][value]=1&include=file'));
    }

    // if (!this.props.events || this.props.events.data.length <= 0)
    // {
    this.props.dispatch(readEndpoint('/node/event?filter[status][value]=1&filter[datefilter2][condition][path]=date_range.end_value&filter[datefilter2][condition][operator]=%3E%3D&filter[datefilter2][condition][value]=' + moment().toISOString()));
    // this.props.dispatch(readEndpoint('/node/event?filter[datefilter2][condition][path]=date_range.end_value&filter[datefilter2][condition][operator]=%3E%3D&filter[datefilter2][condition][value]=' + moment().toISOString()));
    // }

    // console.log(this.props);
    // if (this.props.noticies.data.length <= 0)
    // {
    //     console.log("dhfsdhfsd");
    this.props.dispatch(readEndpoint('/node/noticia?filter[status][value]=1&include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date&page[offset]=0&page[limit]=3'))
      .then((res) =>
        {
          let pagination = [];
          pagination[0] = res.body.data.map(obj => obj.id);

          this.setState({
            noticiesCount: parseInt(res.body.meta.count),
            noticiesPagination: pagination,
            noticiesPageCurrent: 0
          })
        }
      );

    // Obtenim també els destactats
    this.props.dispatch(readEndpoint('/node/noticia?filter[status][value]=1&filter[filter1][condition][path]=is_highlighted&filter[filter1][condition][operator]=%3D&filter[filter1][condition][value]=1&include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date'));


    // const url = sprintf(API_CALONGE_NOTICIES, 2, 1);
    // OJO: s'ha de fer una altra crida per obtenir les noticies que estan com a "destacades"
    // const url = sprintf(API_CALONGE_NOTICIES, this.state.noticiesPageSize, this.state.noticiesPageCurrent * this.state.noticiesPageSize);
    // this.props.dispatch(readEndpoint(url)).then((res) =>
    // {
    //     this.setState({noticiesCount: parseInt(res.body.meta.count)});
    //
    //     this.addNoticiesToMap();
    // });
    // }

    if (!this.props.webs || this.props.webs.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/web?include=image'));
    }

    if (!this.props.videoteca || this.props.videoteca.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/video_gallery?include=videos'));
    }

    if (!this.props.pvideos || this.props.pvideos.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/video'));
    }

    let that = this;
    Events.scrollEvent.register('end', function (to, element)
    {
      console.log("end", to, element);
      // that.setState(prevState => ({
      //     noticiesPageCurrent: prevState.noticiesPageCurrentProvisional
      // }));
    });
  }


  //==================================================================================================================
  //== Setup initial schedule data
  //==================================================================================================================
  componentWillMount()
  {
    // const startDate = moment().subtract(1, 'month').toDate();
    // const endDate = moment().add(1, 'month').toDate();
    // console.log(startDate);
    // console.log(endDate);
    // this.setState({eventDateStart: startDate}, () => this.props.dispatch(filterDateStart(startDate)));
    // this.setState({eventDateEnd: endDate}, () => this.props.dispatch(filterDateEnd(endDate)));
    this.setState({eventPageSize: 3}, () => this.props.dispatch(filterSetPageSize(3)));
    this.setState({eventPageNum: 0}, () => this.props.dispatch(filterSetPageNum(0)));
    this.setState({eventShowInactive: false}, () => this.props.dispatch(filterShowInactive(false)));
    this.setState({eventOrder: 'asc'}, () => this.props.dispatch(orderBy('asc')));
  }


  //==================================================================================================================
  //== Handle slide click
  //==================================================================================================================
  sliderOnClickItem(item)
  {
    if (item.url.startsWith('http'))
    {
      ReactGA.outboundLink({label: item.url}, () => window.open(item.url, '_blank'));
    }
    else
    {
      this.props.history.push(item.url);
    }
  }


  //==================================================================================================================
  //== Render home slider
  //==================================================================================================================
  renderSlider()
  {
    const {banners, events, noticies, files} = this.props;
    const today = moment().startOf('day').toDate();
    let items = [];

    //==============================================================================================================
    //== Get banners to show
    //==============================================================================================================
    banners.data.map((banner) =>
    {
      if (banner.relationships.image.data &&
        moment(banner.attributes.date_range.end_value).startOf('day').toDate() >= today)  // Active banners only
      {
        let image = files.data.find((obj) => obj.id === banner.relationships.image.data.id);
        image = API_APP_URL + image.attributes.uri.url;

        let link = '';
        if (banner.relationships.file.data)
        {
          link = files.data.find((obj) => obj.id === banner.relationships.file.data.id);
          link = API_APP_URL + link.attributes.uri.url;
        }
        else if (banner.attributes.link.uri)
        {
          let isInternal = banner.attributes.link.uri.startsWith('internal:');
          link = (isInternal) ? banner.attributes.link.uri.replace('internal:', '') : banner.attributes.link.uri;
          link = link.replace(/&amp;/g, "&");  // TODO: OJO CHAPUSA PERQUE EL DRUPAL NO SERVEIX BÉ ELS LINKS
        }

        items.push({
          image: image,
          // label: event.attributes.title,
          url: link,
          startDate: moment(banner.attributes.date_range.value).startOf('day').toDate()
        });
      }
    });

    //==============================================================================================================
    //== Get events to show
    //==============================================================================================================
    events.data.map((event) =>
    {
      if (event.relationships.image.data &&
        event.attributes.is_highlighted &&
        moment(event.attributes.date_range.end_value).startOf('day').toDate() >= today)  // Active events only
      {
        console.log(event);
        console.log(files.data);
        let image = files.data.find((obj) => obj.id === event.relationships.image.data.id);
        console.log(image);
        if (image)
        {
          image = API_APP_URL + image.attributes.uri.url;
          items.push({
            image: image,
            // label: event.attributes.title,
            url: '/municipi/agenda/' + event.id,
            startDate: moment(event.attributes.date_range.value).startOf('day').toDate()
          });
        }
      }
    });

    //==============================================================================================================
    //== Get news to show
    //==============================================================================================================
    noticies.data.map((noticia) =>
    {
      if (noticia.attributes.is_highlighted)
      {
        const currentDate = new Date();
        const startDate = new Date(noticia.attributes.date);
        const endDate = (noticia.attributes.date_end)
          ? new Date(noticia.attributes.date_end)
          : moment(startDate).add(2, 'month').toDate();

        if (currentDate.getTime() >= startDate && currentDate.getTime() <= endDate)
        {
          let attached = getAttachments(this.props, noticia);
          let image = API_APP_URL + attached.images[0].attributes.uri.url;
          items.push({
            image: image,
            // label: noticia.attributes.title,
            url: '/municipi/noticies/' + noticia.id,
            startDate: moment(noticia.attributes.date).startOf('day').toDate()
          });
        }
      }
    });

    //==============================================================================================================
    //== Order items
    //==============================================================================================================
    items = items.sort((a, b) => (b.startDate - a.startDate));

    //==============================================================================================================
    //== Render slider
    //==============================================================================================================
    return (
      <Carousel showArrows={true}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
                dynamicHeight={true}
                infiniteLoop={true}
                autoPlay={true}
                interval={15000}
                stopOnHover={true}
                swipeable={true}
                emulateTouch={true}
                onClickItem={(index) => this.sliderOnClickItem(items[index])}>
        {items.map((item, key) =>
        {
          return (
            <div key={key}>
              <img src={item.image}/>
            </div>
          );
        })}
      </Carousel>
    );
  }


  //==================================================================================================================
  //== Render launchers
  //==================================================================================================================
  renderLaunchers()
  {
    // console.log(this.props);
    const {destacats, files} = this.props;

    return (
      <Fade>
        <div className={'row pb-5'}>

          {destacats.data.map((destacat, key) =>
          {
            // Check if there are any file attached
            // console.log(destacat);
            let isInternal = false;
            let link;
            if (!destacat.relationships.file.data)
            {
              isInternal = destacat.attributes.link.uri.startsWith('internal:');
              link = (isInternal) ? destacat.attributes.link.uri.replace('internal:', '') : destacat.attributes.link.uri;
              link = link.replace(/&amp;/g, "&");  // TODO: OJO CHAPUSA PERQUE EL DRUPAL NO SERVEIX BÉ ELS LINKS
            }
            else
            {
              const obj = files.data.find(aux => (aux.id === destacat.relationships.file.data.id));
              link = API_APP_URL + obj.attributes.uri.url;
              link = link.replace(/&amp;/g, "&");  // TODO: OJO CHAPUSA PERQUE EL DRUPAL NO SERVEIX BÉ ELS LINKS
            }

            // console.log(link);

            return (
              <div key={key} className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 pt-3">
                {isInternal
                  ?
                  <Link to={link}>
                    <div className="launcher text-center">
                      <div className="title">
                        <TruncateMarkup lines={1}>
                          <h4>{destacat.attributes.title}</h4>
                        </TruncateMarkup>
                      </div>
                      <div className="message pt-2">
                        <TruncateMarkup lines={3}>
                          <span>{destacat.attributes.message}</span>
                        </TruncateMarkup>
                      </div>
                    </div>
                  </Link>
                  :
                  <a href={link} target={'_blank'}>
                    <div className="launcher text-center">
                      <div className="title">
                        <TruncateMarkup lines={1}>
                          <h4>{destacat.attributes.title}</h4>
                        </TruncateMarkup>
                      </div>
                      <div className="message pt-2">
                        <TruncateMarkup lines={3}>
                          <span>{destacat.attributes.message}</span>
                        </TruncateMarkup>
                      </div>
                    </div>
                  </a>
                }
              </div>
            );
          })}

        </div>
      </Fade>
    )
  }


  //==================================================================================================================
  //==
  //==================================================================================================================
  handleNoticiesChangePage(currentPage)
  {
    const {noticiesPagination} = this.state;
    if (!(noticiesPagination[currentPage] instanceof Array))
    {
      const offset = currentPage * 3;
      this.props.dispatch(readEndpoint('/node/noticia?filter[status][value]=1&include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date&page[offset]=' + offset + '&page[limit]=3'))
        .then((res) =>
          {
            noticiesPagination[currentPage] = res.body.data.map(obj => obj.id);
            this.setState({
              noticiesPagination: noticiesPagination,
              noticiesPageCurrent: currentPage
            }, () =>
              scroller.scrollTo('noticies', {
                duration: 750,
                smooth: true,
                spy: true
              }));
          }
        );
    }
    else
    {
      this.setState({
        noticiesPageCurrent: currentPage
      }, () =>
        scroller.scrollTo('noticies', {
          duration: 750,
          smooth: true,
          spy: true
        }));
    }
  }


  //==================================================================================================================
  //== Render news
  //==================================================================================================================
  renderNoticies()
  {
    const {noticiesPageSize, noticiesPageCurrent, noticiesCount, noticiesPagination, noticiesPaginationSize} = this.state;
    const {images, audios, videos, files, noticies} = this.props;
    const numPages = Math.ceil(noticiesCount / noticiesPageSize);
    const media = {
      images: images,
      audios: audios,
      videos: videos,
      files: files
    };

    let numPagesFirst = 0;
    let numPagesLast = numPages;
    let navItems = [];
    let showNoticies = [];


    // console.log(noticiesPageSize);
    // console.log(noticiesPageCurrent);
    // console.log(noticiesCount);
    // console.log(noticiesPagination);
    // console.log(noticiesPaginationSize);
    // console.log(numPages);


    // Posem el marge que volem per la paginació
    if (numPages > noticiesPaginationSize)
    {
      let margin = Math.trunc((noticiesPaginationSize / 2));
      numPagesFirst = noticiesPageCurrent - margin;

      if (numPagesFirst < 0)
      {
        numPagesFirst = 0;
      }

      if (numPagesFirst + noticiesPaginationSize > numPages)
      {
        numPagesFirst = numPages - noticiesPaginationSize;
      }
      numPagesLast = numPagesFirst + noticiesPaginationSize;
    }

    // Generem els números que es veuran a la paginació
    for (let i = numPagesFirst; i < numPagesLast; ++i)
    {
      navItems.push(i + 1);
    }

    // Obtenim les notícies que necesitem mostrar
    if (noticiesPagination[noticiesPageCurrent])
    {
      for (let i = 0; i < noticiesPageSize; ++i)
      {
        showNoticies.push(noticies.data.find(obj => obj.id === noticiesPagination[noticiesPageCurrent][i]));
      }
    }

    return (
      <ScrollElement name={'noticies'}>
        <div className={'mb-3 mb-lg-5'}>
          {showNoticies.map((noticia, key) =>
          {
            return (
              <div className={'pt-4'} key={key}>
                <Noticia noticia={noticia} media={media} display={'summary'}/>
              </div>
            );
          })}

          <nav aria-label="Paginació">
            <ul className="pagination justify-content-center">

              <li className={(noticiesPageCurrent > 0) ? 'page-item' : 'page-item disabled'}>
                <a className="page-link"
                   onClick={() => (noticiesPageCurrent > 0) ? this.handleNoticiesChangePage(noticiesPageCurrent - 1) : null}>Anterior
                </a>
              </li>

              {navItems.map((pageNum, key) =>
                (pageNum === noticiesPageCurrent + 1)
                  ?
                  <li key={key} className="page-item active">
                                            <span className="page-link">{pageNum}
                                              <span className="sr-only">(current)</span>
                                            </span>
                  </li>
                  :
                  <li key={key} className="page-item">
                    <a className="page-link" onClick={
                      () => this.handleNoticiesChangePage(pageNum - 1)}
                    >{pageNum}</a>
                  </li>
              )}

              <li className={(noticiesPageCurrent < numPages - 1) ? 'page-item' : 'page-item disabled'}>
                <a className="page-link"
                   onClick={() => (noticiesPageCurrent < numPages - 1) ? this.handleNoticiesChangePage(noticiesPageCurrent + 1) : null}>Següent
                </a>
              </li>

            </ul>
          </nav>

        </div>
      </ScrollElement>
    );
  }


  //==================================================================================================================
  //== Render highlighted videos
  //==================================================================================================================
  renderVideoteca()
  {
    const {videoteca, pvideos} = this.props;
    // console.log(videoteca);
    // console.log(pvideos);

    let videos = [];
    if (videoteca && videoteca.data && videoteca.data.length > 0)
    {
      videoteca.data[0].relationships.videos.data.map((video) =>
      {
        const obj = pvideos.data.find((obj) => (obj.id === video.id));
        const url = obj.attributes.link.replace(REGEX_YOUTUBE, 'https://www.youtube.com/embed/$1');
        videos.push(url);
      });
    }

    return (
      <div id={'videoteca'}>
        {videos.map((video, key) =>
        {
          return (
            <div key={key} className={'videoWrapper'}>
              <iframe width={'100% !important'} height={'auto !important'} src={video} frameBorder={'0'}
                      allowFullScreen/>
            </div>
          );
        })}
      </div>
    );

  }


  //==================================================================================================================
  //== Render Twitter timeline widget
  //==================================================================================================================
  renderTwitterTimeline()
  {
    // let {isLoadingTwitter} = this.state;
    return (
      <div className={'twitter-timeline-widget'}>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'ajuntcalonge'
          }}
          options={{
            username: 'ajuntcalonge',
            width: '100%',
            height: '600',
            // chrome: 'noheader nofooter noscrollbar noborders'
            chrome: 'noheader nofooter noborders transparent',

          }}
          onLoad={() =>
          {
            this.state.isLoadingTwitter = false;
          }}
        />
      </div>
    );
  }


  //==================================================================================================================
  //== Render Facebook timeline widget
  //==================================================================================================================
  renderFacebookTimeline()
  {
    const {} = this.state;

    // return (
    //     <ResizeDetector
    //         handleWidth
    //         handleHeight
    //         render={({width, height}) => (
    //             <div>
    //                 Width:{width}, Height:{height}
    //                 <FacebookProvider appId="509784629494409">*/}
    //                     <Page href="https://www.facebook.com/ajuntamentCalongeiSantAntoni/" tabs="timeline"/>
    //                 </FacebookProvider>
    //             </div>
    //         )}
    //     />
    // );

    return (
      <div>
        {/*<div ref={this.facebookWidget}>*/}
        <FacebookProvider appId="509784629494409">
          <Page href="https://www.facebook.com/ajuntamentCalongeiSantAntoni/" tabs="timeline"/>
        </FacebookProvider>
        {/*<div className="fb-post" data-href="https://www.facebook.com/20531316728/posts/10154009990506729/"*/}
        {/*data-width="500" data-show-text="true">*/}
        {/*</div>*/}
      </div>
    );
  }

  //==================================================================================================================
  //== Handle footer slider: next slide
  //==================================================================================================================
  next()
  {
    this.sliderFooter.slickNext();
  }


  //==================================================================================================================
  //== Handle footer slider: previous slide
  //==================================================================================================================
  previous()
  {
    this.sliderFooter.slickPrev();
  }


  //==================================================================================================================
  //== Render footer slider
  //==================================================================================================================
  renderSliderFooter()
  {
    const {websAjuntament} = this.props;
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 630,
          settings: {
            slidesToShow: 1
          }
        }
      ],
    };

    return (
      <Fade>
        <Slider ref={slider => (this.sliderFooter = slider)} {...settings}>

          {websAjuntament.map((web, key) =>
          {
            return (
              <a key={key} href={web.attributes.link.uri} target={'_blank'}>
                <div className={'slide'}>
                  <h2>{web.attributes.title}</h2>
                  <p>{web.attributes.summary}</p>
                </div>
              </a>
            );
          })}

        </Slider>
      </Fade>
    );
  }


  //==================================================================================================================
  //== Open popup webcam
  //==================================================================================================================
  renderWebcamPopup()
  {
    const width = 700;
    const height = 600;
    const left = (window.screen.width / 2) - (width / 2) + window.screenX;
    const top = (window.screen.height / 2) - (height / 2);

    // console.log(window.screen.width, window.screen.height, width, height, left, top, left + window.screenX);

    window.open(
      'http://195.76.42.16:8081',
      'Webcam de la platja',
      'toolbar=no, ' +
      'location=no, ' +
      'directories=no, ' +
      'status=no, ' +
      'menubar=no, ' +
      'scrollbars=no, ' +
      'resizable=no, ' +
      'copyhistory=no, ' +
      'width=' + width + ', ' +
      'height=' + height + ', ' +
      'top=' + top + ', ' +
      'left=' + left
    );
  }


  //==================================================================================================================
  //== Render component
  //==================================================================================================================
  render()
  {
    const {config, isReading, websAltres, files, banners, noticies, events, images, destacats} = this.props;
    const {webcamModalOpen} = this.state;

    let isSliderContentReady = false;
    if ((banners.data.length > 0 &&
      noticies.data.length > 0 &&
      events.data.length > 0 &&
      images.data.length > 0 &&
      files.data.length > 0) || !isReading)
    {
      isSliderContentReady = true;
    }

    return (
      <div>

        <Modal show={webcamModalOpen} onHide={() => this.setState({webcamModalOpen: false})}>
          <Modal.Header closeButton>
            <Modal.Title>Càmera web en manteniment</Modal.Title>
          </Modal.Header>
          <Modal.Body>{config && config.data && config.data.length > 0 && config.data[0]['attributes']['cam_maintenance_msg']}</Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => this.setState({webcamModalOpen: false})}>Tancar</Button>
          </Modal.Footer>
        </Modal>

        <div className={'container px-0 px-lg-3 mb-3 mb-sm-5'}>

          <div id={'home-slider'} className={'row pb-5'}>
            <div className={'col-lg-12'}>
              {(isSliderContentReady) ? this.renderSlider() : <Loader/>}
            </div>
          </div>

          <div className={'row'}>

            <div className={'col-lg-8'}>
              <section>
                <div className={'header'}>
                  <div className={'row'}>

                    <div className={'col-12'}>
                      <h1>Destacats</h1>
                    </div>

                  </div>
                </div>
                {(destacats.data.length > 0) ? this.renderLaunchers() : <Loader/>}
              </section>
              <section>
                <div className={'header'}>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <div className={'float-left'}>
                        <h1>Agenda</h1>
                      </div>
                      <div className={'float-right'} style={{marginTop: '13px'}}>
                        <Link to={'/municipi/agenda'}>
                          <span>Secció d'agenda</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <EventList events={events.data} isReading={isReading}/>
              </section>
              <section>
                <div className={'header'}>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <div className={'float-left'}>
                        <h1>Notícies</h1>
                      </div>
                      <div className={'float-right'} style={{marginTop: '13px'}}>
                        <Link to={'/municipi/noticies'}>
                          <span>Secció de notícies</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {(noticies.data.length <= 0) ? <Loader/> : this.renderNoticies()}
              </section>
            </div>

            <aside className={'col-lg-4'}>
              <div className={'body pb-lg-3'}>

                {(config && config.data && config.data.length > 0 && config.data[0]['attributes']['home_video'])
                  ?
                  <React.Fragment>
                    <div className={'header'}>
                      <div className={'row'}>
                        <div className={'col-12'}>
                          <h1>Salutació</h1>
                        </div>
                      </div>
                    </div>

                    <div className={'py-lg-3 px-lg-3'}>
                      <div className={'videoWrapper'}>
                        <iframe width={'100% !important'}
                                height={'auto !important'}
                                src={config.data[0]['attributes']['home_video']}
                                frameBorder={'0'}
                                allowFullScreen/>
                      </div>
                    </div>
                  </React.Fragment>
                  : null
                }

                <div className={'header'}>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <h1>El Temps</h1>
                    </div>
                  </div>
                </div>

                <div className={'py-lg-3 px-lg-3'}>

                  {(config && config.data && config.data.length > 0)
                    ?
                    <div style={{textAlign: 'center'}}>
                      {(config.data[0]['attributes']['cam_view'] === 'youtube') &&

                        <div className={'videoWrapper'}>
                          <iframe
                            src={config.data[0]['attributes']['cam_url']}
                            frameBorder={'0'} allowFullScreen>
                          </iframe>
                        </div>
                      }

                      {config.data[0]['attributes']['cam_maintenance'] &&
                      <img src={imageMirarPlatja} onClick={() => this.setState({webcamModalOpen: true})} style={{cursor: 'pointer'}}/>}

                      {config.data[0]['attributes']['cam_view'] === 'yawcam'
                      && !config.data[0]['attributes']['cam_maintenance']
                      && <img src={imageMirarPlatja} onClick={() => this.renderWebcamPopup()} style={{cursor: 'pointer'}}/>
                      }
                    </div>
                    : null
                  }

                {/*  {(config && config.data && config.data.length > 0 && config.data[0]['attributes']['meteo_local'])*/}
                {/*    ?*/}
                {/*    <div className={'pt-3'}>*/}
                {/*      <h3>Estació local</h3>*/}
                {/*      <a href={config.data[0]['attributes']['url_meteo']} target={'_blank'}>*/}
                {/*        <img className={'mt-2'} style={{width: '100%'}}*/}
                {/*             src={"https://app.weathercloud.net/device/sticker/0925872863"}/>*/}
                {/*      </a>*/}
                {/*    </div> : null*/}
                {/*  }*/}

                {/*  /!* TODO: Afegir integració amb l'AEMET per la previsió *!/*/}
                {/*  /!*<div className={'pt-3'}>*!/*/}
                {/*  /!*<h3>Previsió</h3>*!/*/}
                {/*  /!*<p>No hi ha dades disponibles.</p>*!/*/}
                {/*  /!*</div>*!/*/}

                </div>

                <div className={'header'}>
                  <div className={'row'}>

                    <div className={'col-12'}>
                      <h1>Videoteca</h1>
                    </div>

                  </div>
                </div>

                <div className={'py-3 px-3'}>
                  {this.renderVideoteca()}
                </div>

                <div className={'header'}>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <h1>Twitter</h1>
                    </div>
                  </div>
                </div>

                <div className={'py-3 px-3'}>
                  {this.renderTwitterTimeline()}
                </div>

                <div className={'header mt-lg-3'}>
                  <div className={'row'}>

                    <div className={'col-12'}>
                      <h1>Facebook</h1>
                    </div>

                  </div>
                </div>

                <div className={'py-3 px-3'}>
                  {this.renderFacebookTimeline()}
                </div>

                <div className={'header mt-lg-3'}>
                  <div className={'row'}>

                    <div className={'col-12'}>
                      <h1>Webs amigues</h1>
                    </div>

                  </div>
                </div>

                <div id={'webs-amigues'} className={'py-3 px-3'}>

                  {(websAltres.length > 0) ?
                    <div>
                      {websAltres.map((web, key) =>
                      {
                        const image = files.data.find((obj) => obj.id === web.relationships.image.data.id);
                        return (
                          <div key={key} className={'item'}>
                            <a href={web.attributes.link.uri} target={'_blank'}>
                              <img src={API_APP_URL + image.attributes.uri.url}/>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                    : <div>No hi ha webs amigues associades.</div>
                  }

                </div>
              </div>
            </aside>

          </div>
        </div>

        <div className={'row'}>
          <div className={'col-12'}>
            <section id={'home-slider-footer'}>
              <div className={'py-4'}>
                <div className="container">
                  <div className={'header'}>
                    <div className="row">

                      <div className={'col-10'}>
                        <h5>Webs de l'ajuntament</h5>
                      </div>

                      <div className={'col-2'}>
                        <div className="float-right arrow">
                          <FontAwesomeIcon icon={'arrow-left'} className={'back'}
                                           onClick={this.previous} size="lg" inverse/>
                          <FontAwesomeIcon icon={'arrow-right'} className={'next'}
                                           onClick={this.next} size="lg" inverse/>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className={'row'}>
                    <div className={'col-12'}>

                      <div className={'mt-1'}>
                        {this.renderSliderFooter()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps)(Inici);