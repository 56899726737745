import React from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import DOMPurify from "dompurify";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS, REGEX_YOUTUBE} from "../Constants";
import ReactPlayer from "react-player";
import {renderAttachedFiles} from "../utils";


//======================================================================================================================
//== Extract linked media from 'props'
//======================================================================================================================
export const getAttachments = (props, noticia) =>
{
  // Media types
  let images = [];
  let audios = [];
  let videos = [];
  let files = [];

  noticia.relationships['media'].data.map(media =>
  {
    // Get all associated images
    if (media.type === 'image' && props.images && props.images.data && props.images.data.length > 0)
    {
      let image = props.images.data.find(image => (media.id === image.id));
      let file = props.files.data.find(file => (file.id === image.relationships['media_image'].data.id));
      images.push(file);
    }
    else if (media.type === 'media--audio' && props.audios && props.audios.data && props.audios.data.length > 0)
    {
      let audio = props.audios.data.find(audio => (media.id === audio.id));
      let file = props.files.data.find(file => (file.id === audio.relationships['field_media_audio_file'].data.id));
      audios.push(file);
    }
    else if (media.type === 'media--video' && props.videos && props.videos.data && props.videos.data.length > 0)
    {
      let video = props.videos.data.find(video => (media.id === video.id));
      let file = props.files.data.find(file => (file.id === video.relationships['field_media_video_file'].data.id));
      videos.push(file);
    }
  });

  noticia.relationships['files'].data.map(file =>
  {
    if (file.type === 'file' && props.files && props.files.data && props.files.data.length > 0)
    {
      let obj = props.files.data.find(aux => (aux.id === file.id));
      obj.attributes.description = file.meta.description;
      files.push(obj);
    }
  });

  // Return object
  return {
    images: images,
    audios: audios,
    videos: videos,
    files: files
  };
};


//======================================================================================================================
//== Render first media of 'noticia' by order (image -> video -> audio)
//======================================================================================================================
const renderMainMedia = (attached, noticia, display) =>
{
  // If we have images, render first
  if (attached.images.length > 0)
  {
    return (
      <div>
        {(display === 'summary') ?
          <div className={"image link"}>
            <Link to={"/municipi/noticies/" + noticia.id}>
              <img src={API_APP_URL + attached.images[0].attributes.uri.url}/>
              <div className="middle">
                <div className="text">
                  Llegir més
                </div>
              </div>
            </Link>
          </div> : null
        }

        {(display === 'full') ?
          <div className={'image'}>
            <img src={API_APP_URL + attached.images[0].attributes.uri.url}/>
          </div> : null
        }

        {(display === 'card') ?
          <img className={'card-img-top'} src={API_APP_URL + attached.images[0].attributes.uri.url}/>
          : null
        }
      </div>
    );
  }

  // If we have videos, render it
  else if (attached.videos.length > 0)
  {
    return (
      <ReactPlayer
        url={API_APP_URL + attached.videos[0].attributes.uri.url}
        className='react-player'
        playing={false}
        controls={true}
        width='100%'
      />
    );
  }

  // If we have audios, render it
  else if (attached.audios.length > 0)
  {
    return (
      <ReactPlayer
        url={API_APP_URL + attached.audios[0].attributes.uri.url}
        className='react-player'
        playing={false}
        controls={true}
        width='100%'
        height='55px'
      />
    );
  }
};


//======================================================================================================================
//== Render noticia
//======================================================================================================================
const Noticia = ({noticia, media, display}) =>
{
  // console.log(noticia);
  // console.log(media);
  // console.log(display);

  const attached = getAttachments(media, noticia);
  const linkUrlShort = "/municipi/noticies/" + noticia.id;
  // const linkUrlFull = window.location.origin.toString() + linkUrlShort;
  const linkUrlFull = 'https://www.calonge.cat' + linkUrlShort;
  let body = ((!display) || (display === 'full')) ?
    DOMPurify.sanitize(noticia.attributes['body']['value'], {
      ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS,
      ADD_ATTR: DOM_PURIFY_ADD_ATTR
    }) :
    DOMPurify.sanitize(noticia.attributes['body']['summary'], {ALLOWED_TAGS: DOM_PURIFY_ALLOWED_TAGS});
  body = body.replace(REGEX_YOUTUBE, '<div class="videoWrapper" style="width: 100% !important"><iframe width="100% !important" height="auto !important" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe></div>');

  return (
    <div>
      {(display === 'card')
        ?
        <Link to={linkUrlShort}>
          <div className={'card mt-3'}>

            {renderMainMedia(attached, noticia, display)}

            <div className="card-body">
              <p className="card-text">{noticia.attributes.title}</p>
            </div>
          </div>
        </Link>
        :
        <div className={'row noticia px-3 px-lg-0'}>
          <div className={'w-100'}>

            <div className={'col-12'}>

              {renderMainMedia(attached, noticia, display)}

              <div className={'row pt-3'}>

                <div className={'col-12'}>
                  {(display === 'summary') ?
                    <h2><Link to={linkUrlShort}>{noticia.attributes.title}</Link></h2> :
                    <h2>{noticia.attributes.title}</h2>
                  }
                </div>
              </div>

              <div className={'row pt-3'}>

                <div className={'col-6'}>
                  <p className={'mt-0'}><Moment date={noticia.attributes['date']} format={'LL'}/>
                  </p>
                </div>

                <div className={'col-6'}>
                  <div className={'float-right'}>
                    <div className={'social-share-text'}>Comparteix:</div>

                    <FacebookShareButton url={linkUrlFull}
                                         quote={noticia.attributes['title'] + '\n\n' + noticia.attributes['body']['summary']}
                                         className={'social-share-icon'}>
                      <FacebookIcon size={20} round={true}/>
                    </FacebookShareButton>

                    <TwitterShareButton url={linkUrlFull}
                                        title={noticia.attributes['title']}
                                        via={'ajuntcalonge'}
                                        hashtags={['calonge', 'ajcalonge']}
                                        className={'social-share-icon'}>
                      <TwitterIcon size={20} round={true}/>
                    </TwitterShareButton>

                    <WhatsappShareButton url={linkUrlFull}
                                         title={noticia.attributes['title']}
                                         className={'social-share-icon'}>
                      <WhatsappIcon size={20} round={true}/>
                    </WhatsappShareButton>

                  </div>
                </div>

              </div>

              {renderAttachedFiles(attached)}

              <div className={'row mt-2'}>
                <div className={'col-12'}>
                  <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/>
                </div>
              </div>

              {(display === 'summary') ?
                <div className={'row read-more pt-3 pb-4'}>
                  <div className={'col-12'}>
                    <Link to={linkUrlShort}>Llegeix més</Link>
                  </div>
                </div> : null
              }

            </div>

          </div>
        </div>
      }
    </div>
  );
};


Noticia.propTypes = {
  noticia: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired
};


export default Noticia