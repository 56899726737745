import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import {SyncLoader} from 'react-spinners';
import Fade from 'react-reveal/Fade';
import Post from "../../../components/Post";
import {Link} from "react-router-dom";
import {API_APP_URL} from "../../../Constants";
import moment from "moment-timezone";
import Moment from "react-moment";
import Loader from "../../../components/Loader";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const descripcio = state.api['dependencies-municipals'] || {data: []};
    const dependencies = state.api['dependencia-municipal'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        descripcio,
        dependencies,
        files
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Dependencies extends Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/dependencies-municipals?include=dm,dm.image,dm.map'));
    }


    //==================================================================================================================
    //== Render "areas" body
    //==================================================================================================================
    renderDependencies()
    {
        const {dependencies, files} = this.props;
        return (
            <div className={'mt-3'}>
                {/*<div>*/}
                    {/*<FormattedMessage id={'app.ajuntament.dependencies.dependencia.descripcio'}/>*/}
                {/*</div>*/}
                <div className={'mt-4'}>
                    {dependencies.data.map((dependencia, key) =>
                    {
                        let imageUrl = null;
                        let mapUrl = null;
                        let mapLink = null;
                        if (dependencia.relationships.image)
                        {
                            let image = files.data.find(obj => (obj.id === dependencia.relationships['image']['data']['id']));
                            if (image)
                            {
                                imageUrl = API_APP_URL + image.attributes.uri.url;
                            }
                        }

                        if (dependencia.relationships.map)
                        {
                            let map = files.data.find(obj => (obj.id === dependencia.relationships['map']['data']['id']));
                            if (map)
                            {
                                mapUrl = API_APP_URL + map.attributes.uri.url;
                                mapLink = `https://www.google.com/maps/place/${dependencia.attributes.location.lat},${dependencia.attributes.location.lon}`;
                            }
                        }

                        return (
                            <div key={key}>
                                <Fade>
                                    <div className={'mt-2 mb-4'}>
                                        <div className={'row'}>
                                            <div className={'col-12'}>

                                                <div className={'row'}>
                                                    <div className={'col-12 col-md-5'}>

                                                        {(imageUrl) ?
                                                            <Link to={'/ajuntament/dependencies/' + dependencia.id}>
                                                                <div className={"img"}>
                                                                    <img src={imageUrl}
                                                                         className={"img-fluid card-img-top"}/>
                                                                </div>
                                                            </Link>
                                                            : null
                                                        }

                                                    </div>
                                                    <div className={'col-12 col-md-7 mt-3 mt-md-0'}>

                                                        <div className={'row'}>
                                                            <div className={'col-12'}>
                                                                <div className={'mb-1'}>
                                                                    <h3><Link
                                                                        to={'/ajuntament/dependencies/' + dependencia.id}>{dependencia.attributes.title}</Link>
                                                                    </h3>
                                                                </div>
                                                                <address><a href={mapLink} target={'_blank'}>{dependencia.attributes.address}</a></address>
                                                                <div>
                                                                    {(dependencia.attributes.phone)
                                                                        ?
                                                                        <span itemProp="telephone">
                                                                        Tel. <a
                                                                            href={'tel:+34' + dependencia.attributes.phone}>+34 {dependencia.attributes.phone}</a>
                                                                    </span>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {(dependencia.attributes.fax)
                                                                        ?
                                                                        <span itemProp="telephone">
                                                                        Fax. <a
                                                                            href={'tel:+34' + dependencia.attributes.fax}>+34 {dependencia.attributes.fax}</a>
                                                                    </span>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div className={'mt-2 timetable'}>
                                                                    {(dependencia.attributes.office_hours)
                                                                        ?
                                                                        <div className={'mb-1'}>
                                                                            <span><strong>Horari d'apertura</strong></span>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <span><strong>No hi ha definit cap horari d'apertura</strong></span>
                                                                        </div>
                                                                    }
                                                                    {(dependencia.attributes.office_hours)
                                                                        ?
                                                                        dependencia.attributes.office_hours.map((obj, key2) =>
                                                                        {
                                                                            let starthours = obj.starthours.toString();
                                                                            let endhours = obj.endhours.toString();
                                                                            starthours = (starthours.length === 3) ? '0' + starthours : starthours;
                                                                            endhours = (endhours.length === 3) ? '0' + endhours : endhours;

                                                                            return (
                                                                                <div key={key2}>
                                                                                    <span
                                                                                        className={'days'}><strong>{moment.weekdays(obj.day)}</strong></span>:
                                                                                    <Moment format=" HH:mm"
                                                                                            parse="HHmm">{starthours}</Moment> - <Moment
                                                                                    format="HH:mm"
                                                                                    parse="HHmm">{endhours}</Moment>
                                                                                </div>
                                                                            );
                                                                        }) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {descripcio, dependencies, files} = this.props;
        return (
            <div className={'dependencies'}>

                <Post data={descripcio.data[0]} files={files.data}/>

                <div className={'row'}>
                    <div className={'col-12'}>
                        {(dependencies.data.length <= 0) ? <Loader/> : this.renderDependencies()}
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Dependencies);