export const EVENT_FILTER_DATE_START = 'EVENT_FILTER_DATE_START';
export const EVENT_FILTER_DATE_END = 'EVENT_FILTER_DATE_END';
export const EVENT_FILTER_VIEW_INACTIVE = 'EVENT_FILTER_VIEW_INACTIVE';
export const EVENT_FILTER_SEARCH_TEXT = 'EVENT_FILTER_SEARCH_TEXT';
export const EVENT_ORDER = 'EVENT_ORDER';
export const EVENT_PAGINATION_PAGE_SIZE = 'EVENT_PAGINATION_PAGE_SIZE';
export const EVENT_PAGINATION_PAGE_NUM = 'EVENT_PAGINATION_PAGE_NUM';


export const filterDateStart = (date) => ({
    type: 'EVENT_FILTER_DATE_START',
    date
});


export const filterDateEnd = (date) => ({
    type: 'EVENT_FILTER_DATE_END',
    date
});


export const filterShowInactive = (showInactive) => ({
    type: 'EVENT_FILTER_VIEW_INACTIVE',
    showInactive
});


export const filterSearchText = (searchText) => ({
    type: 'EVENT_FILTER_SEARCH_TEXT',
    searchText
});


export const filterSetPageSize = (pageSize) => ({
    type: 'EVENT_PAGINATION_PAGE_SIZE',
    pageSize
});


export const filterSetPageNum = (pageNum) => ({
    type: 'EVENT_PAGINATION_PAGE_NUM',
    pageNum
});


export const orderBy = (order) => ({
    type: 'EVENT_ORDER',
    order
});