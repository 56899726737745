import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, Route, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Eleccions from "./eleccions/Eleccions";
// import Historia from "./historia/Historia";
import Agenda from "./agenda/Agenda";
import Vila from "./vila/Vila";
import Estadistiques from "./estadistiques/Estadistiques";
import Fade from 'react-reveal/Fade';
import Noticies from "./noticies/Noticies";
import NoticiesDetail from "./noticies/NoticiaDetail";
import connect from "react-redux/es/connect/connect";
import {readEndpoint} from "redux-json-api";
import AgendaDetail from "./agenda/AgendaDetail";
import ReactGA from "react-ga";
import {ROUTES} from "../../Constants";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const laVila = state.api['la-vila'] || {data: []};
    // const historia = state.api['historia'] || {data: []};
    const noticies = state.api['noticia'] || {data: []};
    const events = state.api['event'] || {data: []};
    const eleccions = state.api['eleccions'] || {data: []};

    return {
        laVila,
        // historia,
        noticies,
        events,
        eleccions
    };
};


//======================================================================================================================
//== Component
//======================================================================================================================
class Municipi extends React.Component
{
    constructor()
    {
        super();
    }

    componentDidMount()
    {
        if (!this.props.laVila || this.props.laVila.data.length <= 0)
        {
            // console.info('Retrieving node \'la vila\'.');
            this.props.dispatch(readEndpoint('/node/la-vila?include=image'));
        }

        if (!this.props.events || this.props.events.data.length <= 0)
        {
            // console.info('Retrieving events.');
            this.props.dispatch(readEndpoint('/node/event?include=image,map,files'));
        }

        // if (!this.props.noticies || this.props.noticies.data.length <= 0)
        // {
        //     // console.info('Retrieving noticies.');
        //     this.props.dispatch(readEndpoint('/node/noticia?include=files,media,media.field_media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date'));
        // }

        if (!this.props.eleccions || this.props.eleccions.data.length <= 0)
        {
            // console.info('Retrieving eleccions.');
            this.props.dispatch(readEndpoint('/node/eleccions?include=polling_station,polling_station.p_vots_candidatura,polling_station.p_vots_candidatura.p_candidatura'))
        }
    }


    render()
    {
        const menu = ROUTES.find(r => r.link === '/municipi');
        return (
            <Fade>

                <div className={'page header'}>
                    <div className={'container'}>
                        <div className="row">

                            <div className={'col-6'}>
                                <h1 className="title">
                                    <FormattedMessage id="app.municipi.title"/>
                                </h1>
                            </div>

                            <div className={'col-6'}>
                                <div className={'breadcrumbs float-right'}>
                                    <ul className={'list-inline'}>
                                        <li className={'list-inline-item'}>
                                            <FontAwesomeIcon icon={'home'} fixedWidth/>
                                        </li>
                                        <li className={'list-inline-item'}>/</li>
                                        <li className={'list-inline-item'}>
                                            <a> <FormattedMessage id="app.municipi.title"/></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div id={'content'}>
                    <div className={'container'}>
                        <div className={'row my-3 my-lg-5'}>

                            <aside className={'d-none d-lg-block col-lg-4 col-xl-3 mb-0 mb-lg-5'}>
                                <div className={'list-group'}>
                                    <div className={'header'}>
                                        <h3>Seccions</h3>
                                    </div>

                                    {menu.content.map((section, key) =>
                                    {
                                        return (
                                            (section.link.startsWith('http')) ?
                                                <ReactGA.OutboundLink
                                                    key={key}
                                                    className={'list-group-item'}
                                                    eventLabel={section.title}
                                                    to={section.link}
                                                    target={'_blank'}>
                                                    <FontAwesomeIcon icon={section.icon} fixedWidth/>
                                                    <FormattedMessage id={section.title}/>
                                                </ReactGA.OutboundLink>
                                                // <a key={key} href={section.link} target={'_blank'}
                                                //    className={'list-group-item'}>
                                                //     <FontAwesomeIcon icon={section.icon} fixedWidth/>
                                                //     <FormattedMessage id={section.title}/>
                                                // </a>
                                                :
                                                <Link key={key} to={menu.link + section.link}
                                                      className={'list-group-item'}>
                                                    <FontAwesomeIcon icon={section.icon} fixedWidth/>
                                                    <FormattedMessage id={section.title}/>
                                                </Link>
                                        );
                                    })}

                                </div>
                            </aside>

                            <div className="col-lg-8 col-xl-9 mb-0 mb-lg-5">

                                <Route exact path="/municipi" component={Vila} key="la-vila"/>
                                <Route exact path="/municipi/agenda" component={Agenda} key="agenda"/>
                                <Route exact path="/municipi/agenda/:id" component={AgendaDetail}
                                       key="agenda-detail"/>
                                <Route exact path="/municipi/noticies" component={Noticies} key="noticies"/>
                                <Route exact path="/municipi/noticies/:id" component={NoticiesDetail}
                                       key="noticies-detail"/>
                                <Route exact path="/municipi/eleccions" component={Eleccions} key="eleccions"/>
                                <Route exact path="/municipi/estadistiques" component={Estadistiques}
                                       key="estadistiques"/>

                            </div>

                        </div>
                    </div>
                </div>

            </Fade>
        );
    }
}


export default connect(mapStateToProps)(Municipi);