import React from 'react'
import PropTypes from 'prop-types'
import {SyncLoader} from "react-spinners";
import * as Scroll from "react-scroll/modules";
import DOMPurify from "dompurify";
import {API_APP_URL, DOM_PURIFY_ADD_ATTR, DOM_PURIFY_ALLOWED_TAGS, REGEX_YOUTUBE} from "../Constants";
import Fade from "react-reveal/Fade";


const renderPost = (data, files, sidebar) =>
{
    // TODO: Unificar tipus de camps
    let text = null;
    if (!data.attributes.body && !data.attributes.description)
    {
        text = '';
    }
    else if (!data.attributes.body)
    {
        text = data.attributes.description.value;
    }
    else
    {
        text = data.attributes.body.value;
    }

    let title = data.attributes.title;
    let body = DOMPurify.sanitize(
        text,
        {ADD_TAGS: DOM_PURIFY_ALLOWED_TAGS, ADD_ATTR: DOM_PURIFY_ADD_ATTR});
    body = body.replace(REGEX_YOUTUBE, '<div class="videoWrapper"><iframe width="100% !important" height="auto !important" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe></div>');

    let imageUrl = null;
    if (data.relationships.image && data.relationships.image.data)
    {
        let image = files.find(obj => (obj.id === data.relationships['image']['data']['id']));
        imageUrl = API_APP_URL + image.attributes.uri.url;
        imageUrl = imageUrl.replace('files/', 'files/styles/section_header/public/');  // TODO: FIX IT! NO HI HA COMPATIBILITAT AMB JSONAPI
    }

    return (
        <div className={'mb-3 mb-lg-5'}>
            <Fade>
                <div>
                    <h1>{title}</h1>
                </div>

                <div className={'row'}>
                    <div className={'col-7 pt-3'}>

                        {(imageUrl) ?
                            <div id={'img-top'}>
                                <img src={imageUrl}/>
                            </div>
                            : null
                        }


                        <div className={'html-content'}>
                            <p dangerouslySetInnerHTML={{__html: body}} style={{textAlign: 'justify'}}/>
                        </div>
                    </div>

                    <div className={'col-5 pt-3'}>
                        {sidebar}
                    </div>
                </div>
            </Fade>
        </div>
    )
};


const PostSidebar = ({data, files, sidebar}) =>
{
    if (data)
    {
        // Scroll.animateScroll.scrollToTop();
    }

    return (
        <div className="app-home">

            {/* LOADER */}
            <SyncLoader
                className={'loader'}
                sizeUnit={'px'}
                size={10}
                color={'#7ED321'}
                loading={!data}
            />
            {/* END LOADER */}

            {/* RENDER AVIS LEGAL */}
            <div>
                {(data) ? renderPost(data, files, sidebar) : null}
            </div>
            {/* END RENDER AVIS LEGAL */}

        </div>
    );
};


PostSidebar.propTypes = {
    // title: PropTypes.string.isRequired,
    // body: PropTypes.string.isRequired
};


export default PostSidebar
