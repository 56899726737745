import React, {Component} from 'react';
import {Link, Route, withRouter} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import connect from "react-redux/es/connect/connect";
import Ajuntament from "./pages/ajuntament/Ajuntament";
import Municipi from "./pages/municipi/Municipi";
import Inici from "./pages/inici/Inici";
import {Cookies} from 'react-cookie';
import Sitemap from "./pages/common/Sitemap";
import Drawer from 'react-motion-drawer';
import ScrollToTop from 'react-scroll-up';

import './App.scss';

import logoCalongeColor from './assets/img/calonge/calonge-logo-color.png';
import logoCalongeWhite from './assets/img/calonge/calonge-logo-white.png';
import scrollUpIcon from './assets/img/up_arrow_round.png';
import logoCalongeEscutColorSmall from './assets/img/calonge/calonge-logo-escut-color-small.png';
import AvisLegal from "./pages/common/AvisLegal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PoliticaCookies from "./pages/common/PoliticaCookies";
import DadesObertes from "./pages/common/DadesObertes";
import Contacte from "./pages/common/Contacte";
import CookieBanner from "react-cookie-banner";
import Notification from "react-web-notification";
import Transparencia from "./pages/common/Transparencia";
import {readEndpoint} from "redux-json-api";
import {ROUTES} from "./Constants";
import {IntlContext} from "./components/IntlProviderWrapper";
import flagCat from "./assets/img/flags/flag-cat.png";
import flagEs from "./assets/img/flags/flag-es.png";
import PoliticaPrivacitat from "./pages/common/PoliticaPrivacitat";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const config = state.api['config'] || {data: []};
  const dependencies = state.api['dependencia-municipal'] || {data: []};

  return {
    config,
    dependencies
  }
};


//======================================================================================================================
//== Language switch
//======================================================================================================================
const LanguageSwitch = () =>
{
  const {locale, switchToCat, switchToEs} = React.useContext(IntlContext);
  return (
    <React.Fragment>
      <img src={flagCat}
           onClick={switchToCat}
           className={(locale !== 'ca') ? 'flag' : null}
           style={{maxHeight: '14px'}}
      />
      <img src={flagEs}
           onClick={switchToEs}
           className={(locale !== 'es') ? 'flag' : null}
           style={{maxHeight: '14px', marginLeft: '10px'}}
      />
    </React.Fragment>
  );
};


//======================================================================================================================
//== Component
//======================================================================================================================
class App extends Component
{
  constructor()
  {
    super();
    this.state = {
      showBeta: true,
      drawerOpen: false,
      cookiesAccepted: false,
      notificationTitle: '',
      ignore: true,
      continueObsoleteBrowser: false
    };

    // const progressObserver = new ScrollProgress((x, y) =>
    // {
    // console.log(x, y);
    // progressElement.style.width = y * 100 + '%';
    // });
  }


  componentWillMount()
  {
    const cookies = new Cookies();
    this.setState({cookiesAccepted: cookies.get('accepts-cookies')});

    if (!this.props.config || this.props.config.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/config'));
    }

    if (!this.props.dependencies || this.props.dependencies.data.length <= 0)
    {
      this.props.dispatch(readEndpoint('/node/dependencia-municipal?include=image,map'));
    }
  }


  componentDidMount()
  {
    // const title = 'Ajuntament de Calonge i Sant Antoni';
    // this.setState({
    //     notificationTitle: title,
    //     options: {
    //         title: title,
    //         body: 'Benvinguts a la nostra web!',
    //         tag: 'Hola',
    //         icon: logoCalongeEscutColorSmall
    //     }
    // });
  }


  handlePermissionGranted()
  {
    // console.log('Permission Granted');
    this.setState({
      ignore: false
    });
  }


  handlePermissionDenied()
  {
    // console.log('Permission Denied');
    this.setState({
      ignore: true
    });
  }


  handleNotSupported()
  {
    // console.log('Web Notification not Supported');
    this.setState({
      ignore: true
    });
  }


  handleNotificationOnClick(e, tag)
  {
    // console.log(e, 'Notification clicked tag:' + tag);
  }


  handleNotificationOnError(e, tag)
  {
    // console.log(e, 'Notification error tag:' + tag);
  }


  handleNotificationOnClose(e, tag)
  {
    // console.log(e, 'Notification closed tag:' + tag);
  }


  handleNotificationOnShow(e, tag)
  {
    // this.playSound();
    // console.log(e, 'Notification shown tag:' + tag);
  }


  playSound(filename)
  {
    document.getElementById('sound').play();
  }


  handleButtonClick()
  {
    if (this.state.ignore)
    {
      return;
    }

    const now = Date.now();

    const title = 'Ajuntament de Calonge i Sant Antoni';
    const body = 'Benvinguts a la nostra web!';
    const tag = now;
    // const icon = 'http://georgeosddev.github.io/react-web-notification/example/Notifications_button_24.png';
    const icon = logoCalongeEscutColorSmall;
    // const icon = 'http://localhost:3000/Notifications_button_24.png';

    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'ca',
      dir: 'ltr',
      // requireInteraction: true
      // sound: './sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
    };

    this.setState({
      notificationTitle: title,
      options: options
    });
  }

  
  renderDrawer()
  {
    let {drawerOpen} = this.state;
    return (
      <Drawer open={drawerOpen}
              drawerStyle={{
                background: '#F9F9F9',
                boxShadow: 'rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px'
              }}
              onChange={open => this.setState({drawerOpen: open})}
      >
        <div className={'drawer'}>
          <div className={'header'}>
            <div id={'logo'}>
              <img src={logoCalongeColor}/>
            </div>
          </div>
          <div className={'body'}>
            <aside>
              <div className={'list-group'}>

                {ROUTES.map((menu, key) =>
                {
                  return (
                    <React.Fragment key={key}>
                      {(menu.link.startsWith('http')) ?
                        <React.Fragment>
                          <a href={menu.link} target={'_blank'}
                             onClick={() => this.setState({drawerOpen: false})}>
                            <div className={'header'}>
                              <h3><FormattedMessage id={menu.title}/></h3>
                            </div>
                          </a>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <Link to={menu.link}
                                onClick={() => this.setState({drawerOpen: false})}>
                            <div className={'header'}>
                              <h3><FormattedMessage id={menu.title}/></h3>
                            </div>
                          </Link>
                        </React.Fragment>
                      }
                      {menu.content.map((section, key2) =>
                      {
                        return (
                          (section.link.startsWith('http')) ?
                            <a key={key2} href={section.link} target={'_blank'}
                               className={'list-group-item'}
                               onClick={() => this.setState({drawerOpen: false})}>
                              <FontAwesomeIcon icon={section.icon} fixedWidth/>
                              <FormattedMessage id={section.title}/>
                            </a>
                            :
                            <Link key={key2} to={menu.link + section.link}
                                  className={'list-group-item'}
                                  onClick={() => this.setState({drawerOpen: false})}>
                              <FontAwesomeIcon icon={section.icon} fixedWidth/>
                              <FormattedMessage id={section.title}/>
                            </Link>
                        );
                      })}
                    </React.Fragment>
                  );
                })}

              </div>
            </aside>
          </div>
        </div>
      </Drawer>
    );
  }

  renderMenuLink(path, stringId)
  {
    // let isExactNeeded = (path === '/');
    // let isExactNeeded = (path === '/');
    return (
      <Route exact={path === '/'} path={path}>
        {(route) =>
        {
          return (
            <li className={route.match ? 'current-menu-ancestor' : undefined}>
              <Link to={path}>
                <FormattedMessage id={stringId}/>
              </Link>
            </li>
          );
        }}
      </Route>
    );
  }

  //==================================================================================================================
  //== Check browser
  //==================================================================================================================
  isBrowserSupported(browser)
  {
    return browser.satisfies({

      //==========================================================================================================
      //== Supported Windows browsers
      //==========================================================================================================
      windows: {
        // "internet explorer": ">10",
        'edge': '>=14'
      },

      //==========================================================================================================
      //== Supported MAC OS browsers
      //==========================================================================================================
      macos: {
        safari: ">=10"
      },

      //==========================================================================================================
      //== Supported Mobile browsers
      //==========================================================================================================
      // mobile: {
      //     safari: '>=9',
      //     'android browser': '>3.10'
      // },

      //==========================================================================================================
      //== Global (without OS restriction)
      //==========================================================================================================
      chrome: ">=58",
      firefox: ">=54",
      opera: ">=55"
    });
  }


  //==================================================================================================================
  //== Show if browser is not supported
  //==================================================================================================================
  renderBrowserNotSupported(browserName, browserVersion)
  {
    return (
      <div id={'browser-not-supported'}>
        <div className={'box'}>

          <div>
            <h3>Estàs utilitzant el navegador</h3>
          </div>

          <div className={'mt-3'}>
            <h1>{browserName} {browserVersion}</h1>
          </div>

          <div className={'mt-3'}>
            <p>Aquest navegador no està suportat per l'aplicació web. Si continues navegant és possible que
              algunes opcions no estiguin disponibles o no funcionin correctament.
              Es recomana utlitizar un dels següents navegadors:</p>
          </div>

          <div className={'row mt-4'}>
            <div className={'col-12 col-md-6 offset-md-3'}>
              <button className={'btn btn-large btn-block btn-primary'}>
                <FontAwesomeIcon icon={['fab', 'chrome']} fixedWidth={true}/>
                <a href={'https://www.google.com/intl/es/chrome/'} target={'_blank'}> Google Chrome
                  (mínim v58)</a>
              </button>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12 col-md-6 offset-md-3'}>
              <button className={'btn btn-large btn-block btn-primary'}>
                <FontAwesomeIcon icon={['fab', 'firefox']} fixedWidth={true}/>
                <a href={'https://www.mozilla.org/es-ES/firefox/new/'} target={'_blank'}> Mozilla
                  Firefox (mínim v54)</a>
              </button>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12 col-md-6 offset-md-3'}>
              <button className={'btn btn-large btn-block btn-primary'}>
                <FontAwesomeIcon icon={['fab', 'opera']} fixedWidth={true}/>
                <a href={'https://www.opera.com'} target={'_blank'}> Opera (mínim v55)</a>
              </button>
            </div>
          </div>
          <div className={'row mt-5'}>
            <div className={'col-12 col-md-6 offset-md-3'}>
              <button className={'btn btn-large btn-block btn-danger'}
                      onClick={() => this.setState({continueObsoleteBrowser: true})}>
                <span>Continuar navegant</span>
              </button>
            </div>
          </div>

          {/*<ul>*/}
          {/*<li><a href={'https://www.google.com/intl/es/chrome/'}>Google Chrome >= v58</a></li>*/}
          {/*<li><a href={'https://www.mozilla.org/es-ES/firefox/new/'}>Mozilla Firefox >= v54</a></li>*/}
          {/*<li><a href={'https://www.opera.com'}>Opera >= v55</a></li>*/}
          {/*</ul>*/}

        </div>
      </div>
    )
  }


  //==================================================================================================================
  //== Show if browser is not supported
  //==================================================================================================================
  renderBeta()
  {
    return (
      <div id={'browser-not-supported'} onClick={() => this.setState({showBeta: false})}>
        <div className={'box'}>

          <div>
            <h3>Aplicació web en fase de proves</h3>
          </div>

          <div className={'mt-3'}>
            <p>Benvingut a la nova aplicació web de l'Ajuntament de Calonge i Sant Antoni. Actualment la web
              està en fase de proves, això vol dir que estem constantment fent millores i optimitzacions.
              Si
              teniu qualsevol incidència durant la navegació us agrairíem que ens ho féssiu arribar a
              través
              del nostre correu electrònic:
            </p>
            <br/>
            <h3>informatica@calonge.cat</h3>
            <br/>
            <p>Moltes gràcies.</p>
          </div>

          <div className={'row mt-5'}>
            <div className={'col-12 col-md-6 offset-md-3'}>
              <button className={'btn btn-large btn-block btn-info'}
                      onClick={() => this.setState({continueObsoleteBrowser: true})}>
                <span>Entesos! Continuar navegant!</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    )
  }


  //==================================================================================================================
  //== Render component
  //==================================================================================================================
  render()
  {
    const {cookiesAccepted, continueObsoleteBrowser} = this.state;
    const {config, dependencies} = this.props;

    let mainDependencia;
    let address = '';
    let commaCounter = 0;
    const maxComma = 3;
    const whatsappAuth = (config.data.length > 0) ? 'https://wa.me/?text=' + config.data[0]['attributes']['whatsapp_message'] : 'https://wa.me/?text=';
    // const browser = Bowser.getParser(window.navigator.userAgent);
    // const isValidBrowser = this.isBrowserSupported(browser);
    const showBeta = (config.data.length > 0) ? config.data[0]['attributes']['is_beta'] : false;

    for (let dependencia of dependencies.data)
    {
      if (dependencia.attributes.is_main)
      {
        mainDependencia = dependencia;

        for (let i = 0; i < mainDependencia.attributes.address.length; ++i)
        {
          const char = mainDependencia.attributes.address[i];
          if (char === ',')
          {
            commaCounter += 1;
          }

          if (commaCounter < maxComma)
          {
            address += char;
          }
          else
          {
            break;
          }
        }
      }
    }


    return (
      <div>

        {/*<Confetti*/}
        {/*run={true}*/}
        {/*// width={this.props.containerWidth}*/}
        {/*// height={this.props.containerHeight}*/}
        {/*numberOfPieces={100}*/}
        {/*/>*/}

        {/*{(!isValidBrowser && !continueObsoleteBrowser)*/}
          {/*? this.renderBrowserNotSupported(browser.getBrowserName(), browser.getBrowserVersion())*/}
          {/*: (showBeta) ? this.renderBeta() : null*/}
        {/*}*/}

{/*
        <Notification
          ignore={this.state.ignore && this.state.title !== ''}
          notSupported={this.handleNotSupported.bind(this)}
          onPermissionGranted={this.handlePermissionGranted.bind(this)}
          onPermissionDenied={this.handlePermissionDenied.bind(this)}
          onShow={this.handleNotificationOnShow.bind(this)}
          onClick={this.handleNotificationOnClick.bind(this)}
          onClose={this.handleNotificationOnClose.bind(this)}
          onError={this.handleNotificationOnError.bind(this)}
          timeout={3600000}  // 1h
          title={this.state.notificationTitle}
          options={this.state.options}
        />
*/}

        {cookiesAccepted ?
          <ScrollToTop showUnder={160} style={{
            position: 'fixed',
            bottom: 50,
            right: 30,
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
            zIndex: 99
          }}>
            <img className={'scroll-top-arrow'} src={scrollUpIcon}/>
          </ScrollToTop>
          : null
        }

        <div className={'navbar fixed-bottom'} style={{padding: '0'}}>
          <CookieBanner
            buttonMessage='Tancar'
            message='Aquest lloc web fa servir cookies pròpies i de tercers per millorar l’experiència de navegació, i oferir continguts i serveis d’interès. En continuar la navegació entenem que s’accepta la nostra política de cookies.'
            // link={<a href='http://nocookielaw.com/'>More information on our use of cookies</a>}
            dismissOnScroll={false}
            onAccept={() => this.setState({cookiesAccepted: true})}
          />
        </div>

        {/* SCROLL PROGRESS BAR */}
        {/*<div className="scroll-progress-bar"/>*/}
        {/* END SCROLL PROGRESS BAR */}

        {this.renderDrawer()}

        {/* TOP HEADER */}
        <div className="d-none d-lg-block top_nav">
          <div className="container">
            <div className="row">

              {/* CONTACT DATA */}
              <div className="col-lg-6">
                <div className="float-left">
                  <div className="widget widget_text">
                    {(mainDependencia) ?
                      <a
                        href={`https://www.google.com/maps/place/${mainDependencia.attributes.location.lat},${mainDependencia.attributes.location.lon}`}
                        target={'_blank'}>
                        <div className="textwidget">{address}</div>
                      </a> : null}
                  </div>
                  <div className="widget widget_text">
                    <div className="textwidget">
                      {(mainDependencia) ?
                        <a
                          href={'tel:+34' + mainDependencia.attributes.phone}>+34 {mainDependencia.attributes.phone}</a> : null}
                    </div>
                  </div>
                  <div className="widget widget_text">
                    <div className="textwidget"><Link to={'/contacte'}><FormattedMessage
                      id={'app.menu.contacte'}/></Link></div>
                  </div>
                </div>
              </div>
              {/* END CONTACT DATA */}

              {/* SOCIAL MEDIA */}
              <div className="col-lg-6">
                <div className={'float-right'}>
                  <div id="text-4" className="widget widget_text">
                    <div className="textwidget">Segueix-nos:</div>
                  </div>
                  <div id="social_widget-3" className="widget social_widget">
                    <div className="row social_row">
                      <div className="col-lg-12">
                        <ul className="footer_social_icons">
                          <li>
                            {/* https://faq.whatsapp.com/en/android/26000030/ */}
                            <a href={whatsappAuth} target={'_blank'}>
                              <FontAwesomeIcon icon={['fab', 'whatsapp']}
                                               fixedWidth={true}/>
                              {/*<FontAwesomeIcon  icon={['fab', 'whatsapp-square']} fixedWidth/>*/}
                            </a>
                          </li>
                          <li>
                            <a href={'https://twitter.com/ajuntcalonge'} target={'_blank'}>
                              <FontAwesomeIcon icon={['fab', 'twitter']}
                                               fixedWidth={true}/>
                              {/*<FontAwesomeIcon  icon={['fab', 'twitter-square']} fixedWidth/>*/}
                            </a>
                          </li>
                          <li>
                            <a href={'https://www.facebook.com/ajuntamentCalongeiSantAntoni/'}
                               target={'_blank'}>
                              <FontAwesomeIcon icon={['fab', 'facebook-square']}
                                               fixedWidth={true}/>
                            </a>
                          </li>
                          <li>
                            <a href={'https://www.instagram.com/ajcalongeisantantoni/'}
                               target={'_blank'}>
                              <FontAwesomeIcon icon={['fab', 'instagram']}
                                               fixedWidth={true}/>
                            </a>
                          </li>
                          <li>
                            <a href={'https://www.youtube.com/channel/UCjPuV6cl6C-JHrw7ToV20NA'}
                               target={'_blank'}>
                              <FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth={true}/>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*<div id="text-4" className="widget widget_text">*/}
                  {/*<div className="textwidget flags">*/}
                  {/*<LanguageSwitch/>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                </div>
              </div>
              {/* END SOCIAL MEDIA */}

            </div>
          </div>
        </div>
        {/* END TOP HEADER */}

        {/* MAIN MENU */}
        <div className="header_wrapper no-transparent">
          <header id="header" className=" ">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">

                  {/* LOGO */}
                  <Link to="/">
                    <div id={'logo'}>
                      <img src={logoCalongeColor}/>
                    </div>
                  </Link>
                  {/* END LOGO */}

                  {/* MENU LINKS */}
                  <div id="navigation" className="d-none d-lg-block nav_top float-right">
                    <nav>
                      <ul className="menu">
                        {this.renderMenuLink('/', 'app.menu.inici')}
                        {this.renderMenuLink('/municipi', 'app.menu.municipi')}
                        {this.renderMenuLink('/ajuntament', 'app.menu.ajuntament')}
                        <li>
                          <a href="https://www.calonge-santantoni.cat" target="_blank">
                            <FormattedMessage id="app.menu.turisme"/>
                          </a>
                        </li>
                        <li>
                          <a href="https://seu.calonge.cat" target="_blank">
                            <FormattedMessage id="app.menu.seu-e"/>
                          </a>
                        </li>
                        {this.renderMenuLink('/transparencia', 'app.menu.transparencia')}
                      </ul>
                    </nav>
                  </div>

                  <a id={'mobile-menu'}
                     className={'d-block d-lg-none open'}
                    // onClick={() => this.setState(
                    //     prevState => ({drawerOpen: !prevState.drawerOpen})
                    // )}
                     onClick={() => this.setState({drawerOpen: true})}
                  />

                </div>
              </div>
            </div>
          </header>
        </div>
        {/* END MAIN MENU */}

        {/* PAGE BODY */}
        <div className="content_wrapper no-transparent">

          <Route exact path="/" component={Inici} key="inici"/>
          <Route path="/municipi" component={Municipi} key="municipi"/>
          <Route path="/ajuntament" component={Ajuntament} key="ajuntament"/>
          <Route exact path="/sitemap" component={Sitemap} key="sitemap"/>
          <Route exact path="/politica-cookies" component={PoliticaCookies} key="politica-cookies"/>
          <Route exact path="/avis-legal" component={AvisLegal} key="avis-legal"/>
          <Route exact path="/politica-privacitat" component={PoliticaPrivacitat} key="politica-privacitat"/>
          <Route exact path="/dades-obertes" component={DadesObertes} key="dades-obertes"/>
          <Route exact path="/transparencia" component={Transparencia} key="transparencia"/>
          <Route exact path="/contacte" component={Contacte} key="contacte"/>

          {/* FOOTER */}
          <div className="row">
            <div className="col-lg-12">
              <footer>

                <div className={'info py-4'}>

                  <div className="container">
                    <div className="row">

                      <div className="col-md-9">
                        <h5 className="mb-4">Calonge i Sant Antoni</h5>
                        <div className={'row px-2'}>
                          <div className={'col-sm-4 img'}>
                            <img src={logoCalongeWhite}/>
                          </div>
                          <div className={'col-sm-8 mt-3 mt-sm-0'}>
                            {(config.data.length > 0) ?
                              <p className={'mt-0'}>
                                {config.data[0]['attributes']['description_corporation']}
                              </p>
                              : <p className={'mt-0'}>
                                Sense dades
                              </p>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mx-auto mt-4 mt-md-0">
                        <h5 className="mb-4">Sobre el web</h5>
                        <ul className={'px-2'}>
                          <li className={'pb-1'}>
                            <Link to={'/sitemap'}>
                              <FormattedMessage id={'app.menu.sitemap'}/>
                            </Link>
                          </li>
                          <li className={'pb-1'}>
                            <Link to={'/politica-cookies'}>
                              <FormattedMessage id={'app.menu.politica-cookies'}/>
                            </Link>
                          </li>
                          <li className={'pb-1'}>
                            <Link to={'/avis-legal'}>
                              <FormattedMessage id={'app.menu.avis-legal'}/>
                            </Link>
                          </li>
                          <li className={'pb-1'}>
                            <Link to={'/politica-privacitat'}>
                              <FormattedMessage id={'app.menu.politica-privacitat'}/>
                            </Link>
                          </li>
                          <li className={'pb-1'}>
                            <Link to={'/dades-obertes'}>
                              <FormattedMessage id={'app.menu.dades-obertes'}/>
                            </Link>
                          </li>
                          <li className={'pb-1'}>
                            <Link to={'/transparencia'}>
                              <FormattedMessage id={'app.menu.transparencia'}/>
                            </Link>
                          </li>
                          <li>
                            <Link to={'/contacte'}>
                              <FormattedMessage id={'app.menu.contacte'}/>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copyright py-3">
                  <div className="container-fluid">
                    <p>Copyright 2023 © Ajuntament de Calonge i Sant Antoni - v1.4.2</p>
                  </div>
                </div>

              </footer>
            </div>
          </div>
          {/* END FOOTER */}

        </div>
        {/* END PAGE BODY */}

      </div>
    );
  }
}


export default withRouter(connect(mapStateToProps)(App));
