//======================================================================================================================
//== IE11 POLYFILL
//======================================================================================================================
/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';
// import 'core-js/es7/array'; // IE11 Array.prototype.includes() -> Pluggin conffetti


//======================================================================================================================
//== CSS: THIRD PARTY
//======================================================================================================================
import 'typeface-open-sans';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import 'bootstrap/dist/css/bootstrap.css'
import 'react-image-lightbox/style.css';
import "react-image-gallery/styles/css/image-gallery.css";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faArchway,
  faArrowLeft,
  faArrowRight,
  faBriefcase,
  faBuilding,
  faCalendarAlt,
  faChartLine,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faDotCircle,
  faEdit,
  faFileAlt,
  faFileArchive,
  faFileContract,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faHome,
  faLandmark,
  faMap,
  faMapMarkedAlt,
  faMonument,
  faNewspaper,
  faPercentage,
  faRedoAlt,
  faSchool,
  faScroll,
  faSitemap,
  faSortNumericDown,
  faTv,
  faUser,
  faUserAlt,
  faUsers,
  faUserShield,
  faVoteYea,
  faTasks,
  faLeaf,
  faBook,
  faTrash,
  faChild,
  faTicketAlt
} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faInstagram, faTwitter, faWhatsapp, faYoutube} from '@fortawesome/free-brands-svg-icons';


//======================================================================================================================
//== CSS: APP
//======================================================================================================================
import './assets/scss/index.scss';


//======================================================================================================================
//== JS: BASIC DEPENDENCIES
//======================================================================================================================
import React from 'react';
import {render} from 'react-dom';
import Provider from "react-redux/es/components/Provider";
import {BrowserRouter, useHistory} from "react-router-dom";
import ReactGA from 'react-ga';
import * as PropTypes from "prop-types";
import ScrollToTop from "./components/ScrollToTop";
import store from './store';
import App from './App';
import {unregister} from './registerServiceWorker';
import {IntlProviderWrapper} from "./components/IntlProviderWrapper";
import GaListener from "./components/GaListener";
import reportWebVitals from "./reportWebVitals";


//======================================================================================================================
//== CONFIG: FONTAWESOME ICONS
//======================================================================================================================
library.add(
  faVoteYea,
  faNewspaper,
  faCalendarAlt,
  faChartLine,
  faArchway,
  faMonument,
  faUser,
  faUsers,
  faUserShield,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faLandmark,
  faSchool,
  faTv,
  faMapMarkedAlt,
  faSitemap,
  faFileContract,
  faBuilding,
  faBriefcase,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileArchive,
  faFileAlt,
  faDotCircle,
  faScroll,
  faEdit,
  faMap,
  faHome,
  faArrowLeft,
  faArrowRight,
  faUserAlt,
  faRedoAlt,
  faPercentage,
  faSortNumericDown,
  faTasks,
  faLeaf,
  faBook,
  faTrash,
  faChild,

  faWhatsapp,
  faTwitter,
  faFacebookSquare,
  faInstagram,
  faYoutube,
  faTicketAlt
);


//======================================================================================================================
//== RENDER REACT APP
//======================================================================================================================
render(
  <Provider store={store}>
    <IntlProviderWrapper>
      <BrowserRouter>
        <GaListener trackingId={'UA-4208579-2'}>
          <ScrollToTop>
            <App/>
          </ScrollToTop>
        </GaListener>
      </BrowserRouter>
    </IntlProviderWrapper>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//======================================================================================================================
//== CONSOLE WELCOME MESSAGE
//======================================================================================================================
console.info(
  '                                  \n' +
  ' _    _       _         _   _   _ \n' +
  '| |  | |     | |       | | | | | |\n' +
  '| |__| | ___ | | __ _  | | | | | |\n' +
  '|  __  |/ _ \\| |/ _` | | | | | | |\n' +
  '| |  | | (_) | | (_| | |_| |_| |_|\n' +
  '|_|  |_|\\___/|_|\\__,_| (_) (_) (_)\n' +
  '                                  \n'
);
console.info('Benvingut a la web de l\'Ajuntament de Calonge i Sant Antoni.');
console.info('Si trobes algun \'bug\' pots enviar-lo a ajuntament@calonge.cat. Gràcies. ;)');









