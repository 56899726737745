import React from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {SyncLoader} from "react-spinners";

const Loader = ({}) =>
{
    return (
        <SyncLoader
            className={"loader"}
            sizeUnit={"px"}
            size={10}
            color={'#7ED321'}
        />
    );
};

Loader.propTypes = {

};

export default Loader