import React from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import {Link} from "react-router-dom";

const Event = ({id, title, description, date, address}) =>
{
    return (
        <div>
            <div className={'row event pt-4'}>
                <div className={'col-lg-2'}>
                    <Link to={'/municipi/agenda/' + id}>
                        <div className={'calendar'}>
                            <div className={'day'}>
                                <h2><Moment date={date} format="DD"/></h2>
                            </div>
                            <div className={'month'}>
                                <Moment date={date} format="MMMM" className={'month'}/>
                            </div>
                            <div className={'hour'}>
                                <Moment date={date} format="HH:mm" className={'hour'}/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'col-lg-10 mt-2 mt-lg-0'}>
                    <h3><Link to={'/municipi/agenda/' + id}>{title}</Link></h3>
                    {/*<div>*/}
                    {/*{(description)*/}
                    {/*? <div className={'html-content mt-2'}>*/}
                    {/*<p dangerouslySetInnerHTML={{__html: description.value}}*/}
                    {/*style={{textAlign: 'justify'}}/>*/}
                    {/*</div>*/}
                    {/*: null*/}
                    {/*}*/}
                    {/*</div>*/}
                    <p className={'location'}>
                        <strong>Ubicació: </strong>{address}
                    </p>
                </div>
            </div>
        </div>
    );
};

Event.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired
};

export default Event