export default {

  //==================================================================================================================
  //== GLOBALS
  //==================================================================================================================
  'app.title': 'Ajuntament de Calonge i Sant Antoni',


  //==================================================================================================================
  //== MAIN MENU
  //==================================================================================================================
  'app.menu.inici': 'Inici',
  'app.menu.ajuntament': 'Ajuntament',
  'app.menu.municipi': 'Municipi',
  'app.menu.sobre-web': 'Sobre el web ',
  'app.menu.sitemap': 'Mapa web',
  'app.menu.politica-cookies': 'Política de cookies',
  'app.menu.avis-legal': 'Avís legal',
  'app.menu.politica-privacitat': 'Política de privacitat',
  'app.menu.dades-obertes': 'Dades obertes (opendata)',
  'app.menu.transparencia': 'Transparència',
  'app.menu.contacte': 'Contacte',
  'app.menu.turisme': 'Turisme',  // External
  'app.menu.seu-e': 'Seu electrònica',  // External


  //==================================================================================================================
  //== INICI
  //==================================================================================================================
  'app.inici.title': 'Inici',


  //==================================================================================================================
  //== MUNICIPI
  //==================================================================================================================
  'app.municipi.title': 'Municipi',
  'app.municipi.menu.vila': 'La vila',
  'app.municipi.menu.agenda': 'Agenda',
  'app.municipi.menu.noticies': 'Notícies',
  'app.municipi.menu.eleccions': 'Resultats electorals',
  'app.municipi.menu.estadistiques': 'Estadístiques',
  'app.municipi.menu.mapes': 'Mapes',
  'app.municipi.vila.title': 'La vila',
  'app.municipi.agenda.title': 'Agenda',
  'app.municipi.agenda.event.sense-resultats': 'No s\'ha trobat cap event amb aquest ID.',
  'app.municipi.noticies.title': 'Notícies',
  'app.municipi.noticies.sense-resultats': 'No s\'ha trobat cap notícia amb aquest ID',
  'app.municipi.noticies.loading': 'Carregant notícia...',
  'app.municipi.eleccions.title': 'Resultats electorals',
  'app.municipi.estadistiques.title': 'Estadístiques',
  'app.municipi.mapes.title': 'Mapes',


  //==================================================================================================================
  //== AJUNTAMENT
  //==================================================================================================================
  'app.ajuntament.title': 'Ajuntament',
  'app.ajuntament.menu.alcaldia': 'Alcaldia',
  'app.ajuntament.menu.premsa': 'Premsa i comunicació',
  'app.ajuntament.menu.ple': 'Ple',
  'app.ajuntament.menu.grups-municipals': 'Grups municipals',
  'app.ajuntament.menu.equip-govern': 'Equip de govern',
  'app.ajuntament.menu.jgl': 'Junta de govern local',
  'app.ajuntament.menu.junta-portaveus': 'Junta de portaveus',
  'app.ajuntament.menu.activitats': 'Activitats',
  'app.ajuntament.menu.sindic': 'Síndic de greuges',
  'app.ajuntament.menu.dependencies': 'Dependencies municipals',
  'app.ajuntament.menu.arees': 'Àrees',
  'app.ajuntament.menu.arees.medi-ambient': 'Medi ambient',
  'app.ajuntament.menu.arees.cultura': 'Cultura',
  'app.ajuntament.menu.serveis': 'Serveis',
  'app.ajuntament.menu.serveis.recollida-escombraries': 'Recollida d\'escombraries',
  'app.ajuntament.menu.tauler': 'Tauler d\'anuncis',
  'app.ajuntament.menu.oferta-publica': 'Oferta d\'Ocupació',
  'app.ajuntament.menu.contractacio': 'Contractació',
  'app.ajuntament.menu.pressupost': 'Pressupost',
  'app.ajuntament.menu.normativa': 'Normativa',
  'app.ajuntament.menu.subvencions': ' Ajuts i subvencions',
  'app.ajuntament.menu.poum': 'POUM',
  'app.ajuntament.menu.altres-documents': 'Altres documents',
  'app.ajuntament.alcaldia.title': 'Alcaldia',
  'app.ajuntament.ple.title': 'Ple',
  'app.ajuntament.ple.regidors.title': 'Regidors actuals',
  'app.ajuntament.ple.actes.title': 'Actes',
  'app.ajuntament.grups-municipals.title': 'Grups municipals',
  'app.ajuntament.grups-municipals.grups.title': 'Llistat de grups actuals',
  'app.ajuntament.equip-govern.title': 'Equip de govern',
  'app.ajuntament.equip-govern.regidors.title': 'Regidors de l\'equip de govern',
  'app.ajuntament.jgl.title': 'Junta de govern local',
  'app.ajuntament.jgl.regidors.title': 'Regidors de la junta de govern',
  'app.ajuntament.jp.title': 'Junta de portaveus',
  'app.ajuntament.jp.regidors.title': 'Portaveus dels grups municipals',
  'app.ajuntament.sindic.title': 'Síndic de greuges',
  'app.ajuntament.dependencies.title': 'Dependencies municipals',
  'app.ajuntament.dependencies.dependencia.title': 'Dependencies',
  'app.ajuntament.dependencies.dependencia.descripcio': 'A continuació llistem les diferents dependències municiapls de l\'Ajuntament.',
  'app.ajuntament.dependencies.dependencia.sense-resultats': 'No s\'ha trobat cap dependencia amb aquest ID.',
  'app.ajuntament.organitzacio-interna.title': 'Organització interna',
  'app.ajuntament.arees.arees.title': 'Àrees',
  'app.ajuntament.arees.unitats.title': 'Unitats Organitzatives',
  'app.ajuntament.organitzacio-interna.arees.descripcio': 'A continuació llistem les diferents àrees i unitats organitzatives de l\'Ajuntament.',
  'app.ajuntament.organitzacio-interna.area.sense-resultats': 'No s\'ha trobat cap àrea amb aquest ID',
  'app.ajuntament.organitzacio-interna.area.unitats': 'Aquesta àrea està formada per les següents unitats:',
  'app.ajuntament.organitzacio-interna.unitat.sense-resultats': 'No s\'ha trobat cap unitat amb aquest ID',
  'app.ajuntament.organitzacio-interna.unitat.area': 'Aquesta unitat forma part de la següent àrea:',
  'app.ajuntament.serveis.title': 'Serveis',
  'app.ajuntament.serveis.descripcio': 'Llistat amb els serveis principals.',
  'app.ajuntament.tauler.title': 'Tauler d\'anuncis',
  'app.ajuntament.oferta-publica.title': 'Oferta d\'ocupació',
  'app.ajuntament.oferta-publica.status.termini-tancat': 'Termini tancat',
  'app.ajuntament.oferta-publica.status.no-vigent': 'No vigent',
  'app.ajuntament.oferta-publica.status.pendent-convocatoria': 'Pendent de convocatòria',
  'app.ajuntament.oferta-publica.status.termini-obert': 'Termini obert',
  'app.ajuntament.oferta-publica.status.resolt': 'Resolt',
  'app.ajuntament.oferta-publica.status.no-informat': 'No informat',
  'app.ajuntament.contractacio.title': 'Contractació',
  'app.ajuntament.pressupost.title': 'Pressupost',
  'app.ajuntament.pressupost.loading': 'Carregant pressupost...',
  'app.ajuntament.pressupost.destacats.title': 'Fitxers destacats',
  'app.ajuntament.pressupost.pmp.title': 'Termini de pagament a proveïdors',
  'app.ajuntament.normativa.reglaments-organics.title': 'Reglaments orgànics',
  'app.ajuntament.normativa.ordenances-fiscals.title': 'Ordenances fiscals',
  'app.ajuntament.normativa.ordenances-urbanisme.title': 'Ordenances urbanisme',
  'app.ajuntament.normativa.ordenances-policia.title': 'Ordenances policia',
  'app.ajuntament.normativa.tramitacio.title': 'En tramitació',
  'app.ajuntament.normativa.tramitacio.subtitle': 'Expedients',
  // 'app.ajuntament.normativa.tramitacio.fase1.title': 'Consultes públiques prèvies',
  // 'app.ajuntament.normativa.tramitacio.fase2.title': 'Avantprojectes en tramitació',
  // 'app.ajuntament.normativa.tramitacio.fase3.title': 'Aprovacions definitives',
  'app.ajuntament.normativa.subvencions.title': 'Ajuts i subvencions',
  'app.ajuntament.normativa.subvencions.documentacio.title': 'Documentació per entitats locals',
  'app.ajuntament.normativa.subvencions.altres.title': 'Altres subvencions',
  'app.ajuntament.normativa.subvencions.altres.description': 'A continuació es poden trobar els enllaços a les publicacions de subvencions de l\'Ajuntament.',
  'app.ajuntament.poum.title': 'POUM',
  'app.ajuntament.poum.normativa.title': 'Normativa',
  'app.ajuntament.poum.catalegs.title': 'Catàlegs de bens protegits',
  'app.ajuntament.poum.gc.title': 'Classificació del sòl',
  'app.ajuntament.poum.gq.title': 'Qualificació del sòl',
  'app.ajuntament.poum.other.title': 'Altra informació d\'interès',
  'app.ajuntament.altres-documents.title': 'Altres documents municipals i d\'altres ens',


  //==================================================================================================================
  //== CONTACTE
  //==================================================================================================================
  'app.contact.title': 'Contacte',
  'app.contact.press.title': 'Premsa i comunicació',
  'app.contact.software.title': 'Incidències informàtiques',


  //==================================================================================================================
  //== MAPA WEB
  //==================================================================================================================
  'app.sitemap.title': 'Mapa web',


  //==================================================================================================================
  //== AVÍS LEGAL
  //==================================================================================================================
  'app.avis-legal.title': 'Avís legal'
};
