import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import {SyncLoader} from 'react-spinners';
import Fade from 'react-reveal/Fade';
import Post from "../../../components/Post";
import {Link} from "react-router-dom";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const arees = state.api['area'] || {data: []};
    const unitats = state.api['unitat-organitzativa'] || {data: []};

    return {
        arees,
        unitats
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Unitat extends Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        this.props.dispatch(readEndpoint('/node/unitat-organitzativa?include=area'));
    }


    //==================================================================================================================
    //== Get props
    //==================================================================================================================
    componentWillReceiveProps(nextProps, nextContext)
    {

    }


    //==================================================================================================================
    //== Render "area"
    //==================================================================================================================
    renderArea()
    {
        let unitat = this.props.unitats.data.find((obj) => (obj.id === this.props.match.params.id));

        if (!unitat)
        {
            return (
                <Fade>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <p><FormattedMessage id={'app.ajuntament.organitzacio-interna.unitat.sense-resultats'}/></p>
                        </div>
                    </div>
                </Fade>
            );
        }

        let area = this.props.arees.data.find((obj) => (obj.id === unitat.relationships.area.data.id));
        return (
            <div>
                <Fade>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1>{unitat.attributes.title}</h1>
                        </div>
                    </div>
                    <div>
                        {(unitat.attributes.description)
                            ?
                            <div className={'row mt-3'}>
                                <div className={'col-12'}>
                                    <div className={'html-content mt-2'}>
                                        <p dangerouslySetInnerHTML={{__html: unitat.attributes.description.value}}
                                           style={{textAlign: 'justify'}}/>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <div>
                        <div className={'row mt-3'}>
                            <div className={'col-12'}>
                                <strong><FormattedMessage
                                    id={'app.ajuntament.organitzacio-interna.unitat.area'}/></strong>
                            </div>
                        </div>
                        <div className={'row mt-2'}>
                            <div className={'col-12'}>
                                <div className={'html-content mt-2'}>
                                    <ul>
                                        <Link
                                            to={'/ajuntament/arees/area/' + area.id}>{area.attributes.title}</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        );
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        return (
            <div className={'arees'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <SyncLoader
                            className={"loader"}
                            sizeUnit={"px"}
                            size={10}
                            color={'#7ED321'}
                            loading={this.props.arees.data.length <= 0}
                        />
                        {(this.props.arees.data.length > 0) ? this.renderArea() : null}
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Unitat);