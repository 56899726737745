import React from 'react';
import {FormattedMessage} from 'react-intl';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Fade from "react-reveal/Fade";
import Fullscreen from "react-full-screen";
import {Doughnut, Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TruncateMarkup from "react-truncate-markup";
import Loader from "../../../components/Loader";
import lowercase from 'lodash/lowerCase';
import ReactTooltip from 'react-tooltip';


//======================================================================================================================
//== Classe per calcular la llei d'hondt per a eleccions municipals (minim 5% de vots a candidatura)
//======================================================================================================================
class CalcHondt
{
    constructor(numSeats, validVotes)
    {
        this.numSeats = numSeats;
        this.validVotes = validVotes;
        this.minPercent = 5;
        this.parties = [];
    }

    addParty(name, nameGM, id, previousCandidatura, votes, color)
    {
        this.parties.push({
            name: name,
            nameGM: nameGM,
            id: id,
            previousCandidatura: previousCandidatura,
            votes: votes,
            color: color
        });
    }

    calc()
    {
        // Generate rounds
        this.parties.map((party) =>
        {
            party.hondt = [];
            party.numRegidors = 0;
            party.percentVotes = Number((party.votes / this.validVotes * 100).toFixed(2));
            for (let i = 1; i < this.numSeats; ++i)
            {
                // party.hondt.push({round: i, votes: Math.round(party.votes / i), assigned: 0});  // assigned: if this round is assigned and which round is.
                party.hondt.push({
                    round: i,
                    votes: parseFloat((party.votes / i).toFixed(2)),
                    assigned: 0,
                    tie: false,
                    next: 0,
                    diff: 0
                });  // assigned: if this round is assigned and which round is.
            }
        });

        // Assign seats (matrix)
        if (this.numSeats > 0)
        {
            let previousSeatVotes = 0;
            for (let i = 1; i <= this.numSeats + 1; i++)  // Get 17 seats +1 (next round)
            {
                let x = 0, y = 0, max = 0, tie = false;  // tie: indica si hi ha empat
                for (let j = 0; j < this.parties.length; ++j)
                {
                    // Check if party has sufficient votes (> 5%)
                    if (!isNaN(this.parties[j].percentVotes) && this.parties[j].percentVotes >= this.minPercent)
                    {
                        for (let k = 0; k < this.parties[j].hondt.length; ++k)
                        {
                            // Get max value of each party (not assigned)
                            if (this.parties[j].hondt[k].assigned === 0)
                            {
                                // Check if it's GT or EQ
                                if (max < this.parties[j].hondt[k].votes)
                                {
                                    x = j;
                                    y = k;
                                    max = this.parties[j].hondt[k].votes;
                                    tie = false;  // no hi ha empat a vots en aquesta ronda
                                    break;
                                }
                                else if (max === this.parties[j].hondt[k].votes)
                                {
                                    tie = true;  // hi ha empat a vots en aquesta ronda
                                    if (this.parties[x].votes < this.parties[j].votes)
                                    {
                                        x = j;
                                        y = k;
                                        max = this.parties[j].hondt[k].votes;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }

                // Assign tie
                this.parties[x].hondt[y].tie = tie;  // indica que s'ha assignat "seat" pero empat amb un altre partit.

                // console.log(i, max, x, y);
                if (i < this.numSeats + 1)  // Seats
                {
                    this.parties[x].hondt[y].assigned = i;

                    if (!isNaN(this.parties[x].percentVotes))
                    {
                        this.parties[x].numRegidors += 1;
                    }
                }
                else  // Next seat
                {
                    this.parties[x].hondt[y].next = i;
                    this.parties[x].hondt[y].diff = Math.ceil(previousSeatVotes - max);
                }

                previousSeatVotes = max;
                // console.log(previousSeatVotes);
            }
            // Sort by num regidors
            // this.parties.sort((a, b) => (b.votes - a.votes))
        }
    }

    getResults()
    {
        this.calc();
        // console.log(this.numSeats);
        // console.log(this.parties);
        return this.parties;
    }
}


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const isReading = (state.api.isReading > 0);
    const elections = state.api['eleccions'] || {data: []};
    const electionsTypes = state.api['tipus-eleccions'] || {data: []};
    const meses = state.api['paragraph--mesa'] || {data: []};
    const candidatures = state.api['candidatura'] || {data: []};
    const votsCandidatures = state.api['candidatura-vots'] || {data: []};

    return {
        isReading,
        elections,
        electionsTypes,
        meses,
        candidatures,
        votsCandidatures,
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Eleccions extends React.Component
{
    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {
            electionsTypes: [],
            elections: [],
            selectedElectionsType: null,
            selectedElections: null,
            previousSelectedElectionsType: null,
            previousSelectedElections: null,
            selectedView: 'overview',
            isFullscreen: false,
            isDetailsDatatableByTypePercentValues: false,
            isDetailsDatatableByCandidatureValues: false,
            autoUpdateEnabled: false,
            autoUpdateTime: 0,
            autoUpdateStart: 0
        };
    }

    //==================================================================================================================
    //== Retrieve data
    //==================================================================================================================
    componentWillMount()
    {
        // if (!this.props.eleccions || this.props.eleccions.data.length <= 0)
        // {
        // console.info('Retrieving eleccions.');
        this.props.dispatch(readEndpoint('/node/eleccions?include=polling_station,polling_station.p_vots_candidatura,polling_station.p_vots_candidatura.p_candidatura,polling_station.p_vots_candidatura.p_candidatura.previous_candidatura'));
        // }

        // if (!this.props.electionsTypes || this.props.electionsTypes.data.length <= 0)
        // {
        // console.info('Retrieving eleccions types.');
        this.props.dispatch(readEndpoint('/node/tipus-eleccions'));
        // }
    }


    //==================================================================================================================
    //==
    //==================================================================================================================
    componentDidMount()
    {

    }


    //==================================================================================================================
    //==
    //==================================================================================================================
    componentWillReceiveProps(nextProps, nextContext)
    {
        if (nextProps.electionsTypes.data.length > 0 && nextProps.elections.data.length > 0)
        {
            const electionsTypes = nextProps.electionsTypes.data.sort((a, b) => ((new Date(b.attributes.title) - new Date(a.attributes.title))));
            const elections = nextProps.elections.data.sort((a, b) => ((new Date(b.attributes.date) - new Date(a.attributes.date))));
            const electionsTypeMunicipals = electionsTypes.find(et => et.attributes.code === 'mun');
            const electionsMunicipals = elections.filter(e =>
                // (e.relationships.elections_type.data.id === electionsTypeMunicipals.id && !e.attributes.is_provisional));
                (e.relationships.elections_type.data.id === electionsTypeMunicipals.id));

            this.setState({
                electionsTypes: electionsTypes,
                elections: elections,
                selectedElectionsType: electionsTypeMunicipals,
                selectedElections: electionsMunicipals[0],
                previousSelectedElections: electionsMunicipals[1] // OJU! Que pot ser null!
            })
        }
    }


    //==================================================================================================================
    //== Start auto-update timer
    //==================================================================================================================
    handleEnableAutoUpdate()
    {
        this.setState((prevState) => ({autoUpdateEnabled: !prevState.autoUpdateEnabled}), () =>
        {
            if (this.state.autoUpdateEnabled)
            {
                this.timer = setInterval(() =>
                {
                    this.props.dispatch(readEndpoint('/node/eleccions?include=polling_station,polling_station.p_vots_candidatura,polling_station.p_vots_candidatura.p_candidatura,polling_station.p_vots_candidatura.p_candidatura.previous_candidatura'));
                }, 5000);  // 5s
            }
            else
            {
                clearInterval(this.timer)
            }
        });
    }


    //==================================================================================================================
    //== Construct result object using webservice data and perform aggregations
    //==================================================================================================================
    mapElectionsToResults(selectedElections)
    {
        const {meses, votsCandidatures, candidatures} = this.props;

        let results = {
            numRegidors: selectedElections.attributes.num_regidors,
            provisional: selectedElections.attributes.is_provisional,
            census: 0,
            censusEscrutat: 0,
            votsTotal: 0,
            votesValid: 0,
            votesBlank: 0,
            votesNull: 0,
            votesCandidatures: 0,
            firstPartResult: 0,
            secondPartResult: 0,
            meses: [],
            candidatures: []
        };

        let candidaturaIds = [];
        let candidaturaGMs = [];
        let candidaturaPrevious = [];
        let candidaturaVotes = [];
        let candidaturaColors = [];

        // Extract data from webservice object
        selectedElections.relationships.polling_station.data.map((mesa) =>
        {
            // Get elections polling station
            meses.data.map((aux) =>
            {
                if (mesa.id === aux.id)
                {
                    let mesa = {};
                    mesa.description = aux.attributes.p_description;
                    mesa.districte = aux.attributes.p_districte;
                    mesa.seccio = aux.attributes.p_seccio;
                    mesa.mesa = aux.attributes.p_mesa;
                    mesa.census = aux.attributes.p_census;
                    mesa.firstPartResult = aux.attributes.p_first_part_result;
                    mesa.secondPartResult = aux.attributes.p_second_part_result;
                    mesa.votesBlank = aux.attributes.p_votes_blank;
                    mesa.votesNull = aux.attributes.p_votes_null;
                    mesa.votesCandidatures = 0;
                    mesa.candidatures = [];
                    results.census += aux.attributes.p_census;
                    results.firstPartResult += aux.attributes.p_first_part_result;
                    results.secondPartResult += aux.attributes.p_second_part_result;
                    results.votesBlank += aux.attributes.p_votes_blank;
                    results.votesNull += aux.attributes.p_votes_null;

                    aux.relationships.p_vots_candidatura.data.map((vc) =>
                    {
                        // Get candidatura-vots entity
                        votsCandidatures.data.map((aux2) =>
                        {
                            if (vc.id === aux2.id)
                            {
                                // Get candidatura entity
                                candidatures.data.map((aux3) =>
                                {
                                    if (aux2.relationships.p_candidatura.data.id === aux3.id)
                                    {
                                        let candidatura = {};
                                        candidatura.id = aux3.id;
                                        candidatura.name = aux3.attributes.name;
                                        // candidatura.nameGM = aux3.attributes.name_gm;
                                        // candidatura.slogan = aux3.attributes.slogan;
                                        candidatura.color = aux3.attributes.color.color;
                                        candidatura.votes = aux2.attributes.p_vots;
                                        candidatura.previousCandidatura = aux3.relationships.previous_candidatura.data;
                                        mesa.votesCandidatures += aux2.attributes.p_vots;
                                        results.votesCandidatures += aux2.attributes.p_vots;

                                        // Setup candidatura ID
                                        // eslint-disable-next-line no-unused-expressions
                                        (!candidaturaIds[aux3.attributes.name]) ? candidaturaIds[aux3.attributes.name] = aux3.id : null;

                                        // Setup name GM
                                        // eslint-disable-next-line no-unused-expressions
                                        (!candidaturaGMs[aux3.attributes.name]) ? candidaturaGMs[aux3.attributes.name] = aux3.attributes.name_gm : null;

                                        // Setup previous candidatura ID
                                        if (!candidaturaPrevious[aux3.attributes.name])
                                        {
                                            candidaturaPrevious[aux3.attributes.name] = aux3.relationships.previous_candidatura.data;
                                        }

                                        // Setup vote aggregation if neccessary
                                        candidaturaVotes[aux3.attributes.name] = candidaturaVotes[aux3.attributes.name] || 0;
                                        candidaturaVotes[aux3.attributes.name] += aux2.attributes.p_vots;

                                        // Setup color (once)
                                        if (!candidaturaColors[aux3.attributes.name] && aux3.attributes.color)
                                        {
                                            candidaturaColors[aux3.attributes.name] = aux3.attributes.color.color;
                                        }
                                        mesa.candidatures.push(candidatura);
                                    }
                                });
                            }
                        });
                    });

                    // Si hi ha vots a la mesa, la donem per escrutada
                    if (mesa.votesCandidatures > 0 || mesa.votesBlank > 0 || mesa.votesNull > 0)
                    {
                        results.censusEscrutat += mesa.census;
                    }

                    results.meses.push(mesa);
                }
            });
        });

        // Calc totals
        results.votesValid = results.votesCandidatures + results.votesBlank;
        results.votesTotal = results.votesValid + results.votesNull;

        // Prepare data for chart
        let validVotes = results.votesCandidatures + results.votesBlank;
        let hondt = new CalcHondt(results.numRegidors, validVotes);
        for (let key in candidaturaVotes)
        {
            hondt.addParty(key, candidaturaGMs[key], candidaturaIds[key], candidaturaPrevious[key], candidaturaVotes[key], candidaturaColors[key]);
        }
        results.candidatures = hondt.getResults();

        // Sort results
        results.candidatures = results.candidatures.sort((a, b) => (b.votes - a.votes));

        for (let i = 0; i < results.meses.length; ++i)
        {
            results.meses[i].candidatures = results.meses[i].candidatures.sort((a, b) => (b.votes - a.votes));
        }

        return results;
    }


    //==================================================================================================================
    //== Handle 'onChange' for elections type change
    //==================================================================================================================
    handleChangeElectionsType(event)
    {
        const {electionsTypes, elections} = this.state;  // Sorted

        // Get elections from id
        for (let aux of electionsTypes)
        {
            if (aux.id === event.target.value)
            {
                let electionsOfThisType = elections.filter(e => e.relationships.elections_type.data.id === aux.id);
                // let electionsOfThisType = elections.filter(e => e.relationships.elections_type.data.id === aux.id && !e.attributes.is_provisional);
                electionsOfThisType = electionsOfThisType.sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));  // Sort by date
                this.setState({
                    selectedElectionsType: aux,
                    selectedElections: (electionsOfThisType.length > 0) ? electionsOfThisType[electionsOfThisType.length - 1] : null,
                    previousSelectedElections: (electionsOfThisType.length > 0 && electionsOfThisType.length > 1) ? electionsOfThisType[electionsOfThisType.length - 2] : null
                });
                break;
            }
        }
    }


    //==================================================================================================================
    //== Handle 'onChange' for elections change
    //==================================================================================================================
    handleChangeElections(event)
    {
        const {elections, selectedElectionsType} = this.state;

        // Get elections from id
        for (let aux of elections)
        {
            if (aux.id === event.target.value)
            {
                // let electionsOfThisType = elections.filter(e => e.relationships.elections_type.data.id === selectedElectionsType.id && !e.attributes.is_provisional);
                let electionsOfThisType = elections.filter(e => e.relationships.elections_type.data.id === selectedElectionsType.id);
                electionsOfThisType = electionsOfThisType.sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));  // Sort by date
                let i = electionsOfThisType.findIndex((element) => (aux.id === element.id));
                this.setState({
                    selectedElections: aux,
                    previousSelectedElections: (electionsOfThisType.length > 0 && i > 0) ? electionsOfThisType[i - 1] : null
                });
                break;
            }
        }
    }


    //==================================================================================================================
    //== Handle 'onChange' for fullscreen
    //==================================================================================================================
    handleFullscreen()
    {
        this.setState({isFullscreen: true});
    }


    //==================================================================================================================
    //== Render graph by mesa
    //==================================================================================================================
    renderGraphBar(graph)
    {
        if (graph.datasets[0].data.length > 0)
        {
            return (
                <Fade>
                    <div className={'chart-mini'}>
                        <div className={'title'}>
                            <TruncateMarkup lines={1}>
                                <span><strong>{graph.title}</strong> {graph.description}</span>
                            </TruncateMarkup>
                        </div>
                        <div className={'body'}>
                            <Bar data={graph}
                                 height={200}
                                 options={{
                                     responsive: true,
                                     maintainAspectRatio: true,
                                     legend: {
                                         display: false
                                     },
                                     scales: {
                                         xAxes: [{
                                             display: graph.showLabels
                                         }],
                                         yAxes: [{
                                             ticks: {
                                                 beginAtZero: true
                                             }
                                         }]
                                     },
                                     plugins: {
                                         datalabels: {
                                             display: true,
                                             color: 'white',
                                             font: {
                                                 size: 12,
                                                 weight: 'normal'
                                             }
                                         }
                                     }
                                 }}/>
                        </div>
                    </div>
                </Fade>
            );
        }
        else
        {
            return (
                <div>
                    <p>Loading chart data...</p>
                </div>
            );
        }
    }


    //==================================================================================================================
    //== Render graph
    //==================================================================================================================
    renderGraphPie(graph)
    {
        return (
            <Fade>
                <div className={'chart-mini'}>
                    <div className={'title'}>
                        <TruncateMarkup lines={1}>
                            <span><strong>{graph.title}</strong> {graph.description}</span>
                        </TruncateMarkup>
                    </div>
                    <div className={'body'}>
                        <Doughnut data={graph}
                                  height={200}
                                  options={{
                                      responsive: true,
                                      maintainAspectRatio: true,
                                      rotation: Math.PI,
                                      circumference: Math.PI,
                                      legend: {
                                          display: true,
                                          position: 'bottom'
                                      },
                                      plugins: {
                                          datalabels: {
                                              display: true,
                                              color: 'white',
                                              font: {
                                                  size: 20,
                                                  weight: 'normal'
                                              }
                                          }
                                      }
                                  }}/>
                    </div>
                </div>
            </Fade>
        );
    }


    //==================================================================================================================
    //== Render selected elections datatable (escrutini)
    //==================================================================================================================
    renderOverviewDatatableEscrutini(results)
    {
        const {selectedElectionsType, selectedElections, isFullscreen} = this.state;
        const numMeses = results.meses.length;
        let numMesesEscrutades = 0;
        results.meses.map((mesa) =>
        {
            for (let j = 0; j < mesa.candidatures.length; ++j)
            {
                if (mesa.candidatures[j].votes > 0)
                {
                    numMesesEscrutades++;
                    break;
                }
            }
        });

        return (
            <Fade>
                <div className={'mt-5 mt-xl-0'}>
                    <table className={'table table-striped mt-3 mt-xl-0'}>
                        <thead className={'thead-dark'}>
                        <tr>
                            <th colSpan={3}>{(isFullscreen) ? 'Escrutini ' + selectedElections.attributes.title : 'Escrutini'}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">Escrutat</th>
                            <td><strong>{numMesesEscrutades} / {numMeses}</strong></td>
                            <td><strong>{(results.censusEscrutat / results.census * 100).toFixed(2)} %</strong></td>
                        </tr>
                        {(selectedElectionsType.attributes.code === 'mun')
                            ?
                            <tr>
                                <th scope="row">Escons totals</th>
                                <td colSpan={2}><strong>{results.numRegidors}</strong></td>
                            </tr>
                            : null
                        }
                        <tr>
                            <th scope="row">Cens</th>
                            <td colSpan={2}><strong>{results.census}</strong></td>
                        </tr>
                        <tr>
                            <th scope="row">Participació (primer avanç)</th>
                            <td>{results.firstPartResult}</td>
                            <td>{(results.firstPartResult / results.census * 100).toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th scope="row">Participació (segon avanç)</th>
                            <td>{results.secondPartResult}</td>
                            <td>{(results.secondPartResult / results.census * 100).toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th scope="row">Vots comptabilitzats</th>
                            <td>{results.votesTotal}</td>
                            <td>{(results.votesTotal / results.census * 100).toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th scope="row">Abstencions</th>
                            <td>{results.census - results.votesTotal}</td>
                            <td>{((results.census - results.votesTotal) / results.census * 100).toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th scope="row">Vots vàlids</th>
                            <td>{results.votesValid}</td>
                            <td>{(results.votesValid / results.votesTotal * 100).toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th scope="row">Vots nuls</th>
                            <td>{results.votesNull}</td>
                            <td>{(results.votesNull / results.votesTotal * 100).toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th scope="row">Vots candidatures</th>
                            <td>{results.votesCandidatures}</td>
                            <td>{(results.votesCandidatures / results.votesValid * 100).toFixed(2)} %</td>
                        </tr>

                        <tr>
                            <th scope="row">Vots en blanc</th>
                            <td>{results.votesBlank}</td>
                            <td>{(results.votesBlank / results.votesValid * 100).toFixed(2)} %</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Fade>
        )
    }


    //==================================================================================================================
    //== Render selected elections datatable (candidatures)
    //==================================================================================================================
    renderOverviewDatatableCandidatures(results)
    {
        const {selectedElectionsType, selectedElections, previousSelectedElections, isFullscreen} = this.state;

        let pse;
        if (previousSelectedElections)
        {
            pse = this.mapElectionsToResults(previousSelectedElections);
        }

        return (
            <Fade>
                <div className={'mt-5 mt-xl-5'}>
                    <table className={'table table-striped'}>
                        <thead className={'thead-dark'}>
                        <tr>
                            <th colSpan={(previousSelectedElections) ? 6 : 4}>Vots per candidatures</th>
                        </tr>
                        <tr>
                            <th className={'text-left'}>Candidatura</th>
                            {(selectedElectionsType.attributes.code === 'mun')
                                ? <th><FontAwesomeIcon icon={'user-alt'} fixedWidth/></th>
                                : null
                            }
                            <th colSpan={2}>Vots {new Date(selectedElections.attributes.date).getFullYear()}</th>
                            {(previousSelectedElections) ?
                                <th colSpan={2} style={{
                                    backgroundColor: '#F6F6F6',
                                    color: 'inherit',
                                    fontWeight: 'normal'
                                }}>Vots {new Date(previousSelectedElections.attributes.date).getFullYear()}</th> : null}
                        </tr>
                        </thead>
                        <tbody>
                        {results.candidatures.map((candidatura, i) =>
                        {
                            let previousCandidatura;
                            if (pse && candidatura.previousCandidatura)
                            {
                                previousCandidatura = pse.candidatures.find((c) => c.id === candidatura.previousCandidatura.id);
                            }

                            const nameGM = lowercase(candidatura.nameGM);
                            return (
                                <tr key={i}>
                                    <th scope="row">
                                        <span>{candidatura.name}</span>
                                        <span style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'normal'
                                        }}> {(isFullscreen) ? ' (' + nameGM + ')' : null}</span>
                                    </th>
                                    {(selectedElectionsType.attributes.code === 'mun')
                                        ? <td className={'text-center'}><strong>{candidatura.numRegidors}</strong></td>
                                        : null
                                    }
                                    <td>{candidatura.votes}</td>
                                    <td>{(candidatura.votes / results.votesValid * 100).toFixed(2)}%</td>
                                    {(previousSelectedElections) ?
                                        <td style={{backgroundColor: '#F6F6F6'}}>{(previousCandidatura) ? previousCandidatura.votes : null}</td> : null}
                                    {(previousSelectedElections) ?
                                        <td style={{backgroundColor: '#F6F6F6'}}>{(previousCandidatura) ? (previousCandidatura.votes / pse.votesValid * 100).toFixed(2) + '%' : null}</td> : null}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </Fade>
        )
    }


    //==================================================================================================================
    //== Render selected elections datatable (candidatures)
    //==================================================================================================================
    renderOverviewDatatableHondt(results)
    {
        // Get last round ith assigned seat
        let lastAssigned = '';
        let nextAssigned = '';
        let diffAssigned = '';
        let assignedRounds = [];
        results.candidatures.map((candidatura) =>
        {
            candidatura.hondt.map((round) =>
            {
                if (round.assigned > 0)
                {
                    assignedRounds[round.round - 1] = round.round;
                }
            })
        });

        // Push next round to array
        assignedRounds.push(assignedRounds[assignedRounds.length - 1] + 1);
        const roundColumnWidth = 75 / assignedRounds.length + '%';

        return (
            <Fade>
                <div className={'mt-5 mt-xl-5'}>
                    <table className={'table'}>
                        <thead className={'thead-dark'}>
                        <tr>
                            <th colSpan={assignedRounds.length+1}>Llei d'Hondt</th>
                        </tr>
                        <tr>
                            <th colSpan={1} width={'25%'} className={'text-center'}>Candidatures</th>
                            {assignedRounds.map((round, key) =>
                            {
                                return (<th key={key} colSpan={1} width={roundColumnWidth}
                                            className={'text-center'}>{round}</th>);
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {results.candidatures.map((candidatura, i) =>
                        {
                            return (
                                <tr key={i}>
                                    <th colSpan={1} scope="row"><span>{candidatura.name}</span></th>
                                    {(candidatura.hondt.map((round, j) =>
                                    {
                                        if (round.assigned === results.numRegidors)
                                        {
                                            lastAssigned = candidatura.nameGM + ' (' + candidatura.name + ')';
                                        }

                                        if (round.next > 0)
                                        {
                                            nextAssigned = candidatura.nameGM + ' (' + candidatura.name + ')';
                                            diffAssigned = round.diff;
                                        }

                                        if (j < assignedRounds.length)
                                        {
                                            return (
                                                <th key={j} style={{
                                                    fontWeight: 'normal',
                                                    backgroundColor: (round.assigned > 0) ? 'lightgray' : 'inherit'
                                                }}>
                                                    <span>{round.votes} </span>
                                                    {(round.assigned > 0)
                                                        ? <span
                                                            className="badge badge-sm badge-secondary"> {round.assigned}</span>
                                                        : null
                                                    }
                                                    {(round.next > 0)
                                                        ?
                                                        <a data-tip data-for={'last-seat'}>
                                                            <span className="badge badge-sm badge-info"> proper</span>
                                                        </a>
                                                        : null
                                                    }
                                                    {(round.next > 0)
                                                        ?
                                                        <ReactTooltip id={'last-seat'} place="right" type='dark'
                                                                      effect='solid'>
                                                            <span>Següent regidor a assignar. Està a <strong>{round.diff}</strong> vot/s de l'últim regidor assignat.</span>
                                                        </ReactTooltip>
                                                        : null
                                                    }
                                                </th>
                                            );
                                        }
                                    }))
                                    }
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <div className={'row'}>
                        <div className={'col-12'}>
                            <p>Últim escó assignat: <strong>{lastAssigned}</strong></p>
                            <p>Candidatura més propera a obtenir escó: <strong>{nextAssigned}</strong></p>
                            <p>Diferència de vots entre candidatures: <strong>{diffAssigned}</strong> vot/s</p>
                        </div>
                    </div>

                </div>
            </Fade>
        )
    }


    //==================================================================================================================
    //== Render selected elections details datatable
    //==================================================================================================================
    renderDetailsDatatableByType(results)
    {
        const {isDetailsDatatableByTypePercentValues} = this.state;
        return (
            <Fade>
                <div className={'mt-3'}>
                    <table className={'table table-striped'}>
                        <thead className={'thead-dark'}>
                        <tr>
                            <th className={'d-none d-md-table-cell'} colSpan={3}>Meses</th>
                            <th className={'d-table-cell d-md-none'} colSpan={2}>Meses</th>
                            <th colSpan={7}>Vots</th>
                        </tr>
                        <tr style={{backgroundColor: '#F6F6F6'}}>
                            <th width={'10%'}>Mesa</th>
                            <th className={'d-none d-md-table-cell'}>Lloc</th>
                            <th>Cens</th>
                            <th>1r avanç</th>
                            <th>2n avanç</th>
                            <th style={{backgroundColor: '#F6F6F6', color: 'inherit'}}>Vots</th>
                            <th style={{backgroundColor: '#F6F6F6', color: 'inherit'}}>Nuls</th>
                            <th style={{backgroundColor: '#F6F6F6', color: 'inherit'}}>Vàlids</th>
                            <th style={{backgroundColor: '#F6F6F6', color: 'inherit'}}>Blancs</th>
                            <th style={{backgroundColor: '#F6F6F6', color: 'inherit'}}>Cand.</th>
                        </tr>
                        </thead>
                        <tbody>
                        {results.meses.map((mesa, key) =>
                        {
                            return (
                                <tr key={key}>
                                    <td className={'text-center'}
                                        style={{color: ((mesa.votesCandidatures + mesa.votesBlank + mesa.votesNull) <= 0) ? 'red' : 'inherit'}}>{mesa.districte + "-" + mesa.seccio + "-" + mesa.mesa}</td>
                                    <td className={'text-left d-none d-md-table-cell'}
                                        style={{color: ((mesa.votesCandidatures + mesa.votesBlank + mesa.votesNull) <= 0) ? 'red' : 'inherit'}}>{mesa.description}</td>
                                    <td className={'text-center'}>{mesa.census}</td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? mesa.firstPartResult
                                            : (mesa.firstPartResult / mesa.census * 100).toFixed(2) + '%'
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? mesa.secondPartResult
                                            : (mesa.secondPartResult / mesa.census * 100).toFixed(2) + '%'
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? (mesa.votesCandidatures + mesa.votesBlank + mesa.votesNull)
                                            : ((mesa.votesCandidatures + mesa.votesBlank + mesa.votesNull) / mesa.census * 100).toFixed(2) + '%'
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? (mesa.votesNull)
                                            : (mesa.votesNull / mesa.census * 100).toFixed(2) + '%'
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? (mesa.votesCandidatures + mesa.votesBlank)
                                            : ((mesa.votesCandidatures + mesa.votesBlank) / mesa.census * 100).toFixed(2) + '%'
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? (mesa.votesBlank)
                                            : (mesa.votesBlank / mesa.census * 100).toFixed(2) + '%'
                                        }</td>
                                    <td className={'text-center'}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? (mesa.votesCandidatures)
                                            : (mesa.votesCandidatures / mesa.census * 100).toFixed(2) + '%'
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        <tr style={{backgroundColor: '#E6E6E6'}}>
                            <td className={'text-center d-none d-md-table-cell'}></td>
                            <td className={'text-left'}><strong>Total</strong></td>
                            <td className={'text-center'}><strong>{results.census}</strong></td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.firstPartResult)
                                    : (results.firstPartResult / results.census * 100).toFixed(2) + '%'
                                }
                            </td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.secondPartResult)
                                    : (results.secondPartResult / results.census * 100).toFixed(2) + '%'
                                }
                            </td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.votesCandidatures + results.votesBlank + results.votesNull)
                                    : ((results.votesCandidatures + results.votesBlank + results.votesNull) / results.census * 100).toFixed(2) + '%'
                                }
                            </td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.votesNull)
                                    : (results.votesNull / results.census * 100).toFixed(2) + '%'
                                }
                            </td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.votesCandidatures + results.votesBlank)
                                    : ((results.votesCandidatures + results.votesBlank) / results.census * 100).toFixed(2) + '%'
                                }
                            </td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.votesBlank)
                                    : (results.votesBlank / results.census * 100).toFixed(2) + '%'
                                }</td>
                            <td className={'text-center'}>
                                {(!isDetailsDatatableByTypePercentValues)
                                    ? (results.votesCandidatures)
                                    : (results.votesCandidatures / results.census * 100).toFixed(2) + '%'
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Fade>
        )
    }


    //==================================================================================================================
    //== Render selected elections details datatable
    //==================================================================================================================
    renderDetailsDatatableByCandidature(results)
    {
        const {selectedElectionsType, isDetailsDatatableByCandidatureValues} = this.state;
        const maxCandidatures = (selectedElectionsType.attributes.code === 'mun') ? results.candidatures.length : 9;
        const topCandidatures = [];

        for (let i = 0; i < maxCandidatures; ++i)
        {
            topCandidatures.push(results.candidatures[i].id);
        }

        let otherAggregatedVotesByCandidatura = 0;
        for (let i = 0; i < results.meses.length; ++i)
        {
            let otherAggregatedVotesByMesa = 0;
            for (let j = 0; j < results.meses[i].candidatures.length; ++j)
            {
                if (!topCandidatures.includes(results.meses[i].candidatures[j].id))
                {
                    otherAggregatedVotesByMesa += results.meses[i].candidatures[j].votes;
                }
            }
            results.meses[i].candidatures.push({name: 'Altres', votes: otherAggregatedVotesByMesa});
            otherAggregatedVotesByCandidatura += otherAggregatedVotesByMesa;
        }
        results.candidatures.push({name: 'Altres', votes: otherAggregatedVotesByCandidatura});

        return (
            <Fade>
                <div className={'mt-3'}>
                    <table className={'table table-striped'}>
                        <thead className={'thead-dark'}>
                        <tr>
                            <th className={'d-none d-md-table-cell'} colSpan={3}>Meses</th>
                            <th className={'d-table-cell d-md-none'} colSpan={2}>Meses</th>
                            <th colSpan={(selectedElectionsType.attributes.code === 'mun') ? maxCandidatures : maxCandidatures + 1}>Candidatures</th>
                        </tr>
                        <tr style={{backgroundColor: '#F6F6F6'}}>
                            <th width={'10%'}>Mesa</th>
                            <th className={'d-none d-md-table-cell'}>Lloc</th>
                            <th>Cens</th>
                            {results.candidatures.map((candidatura, i) =>
                            {
                                if (i < maxCandidatures)
                                {
                                    return (
                                        <th key={i}
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                color: 'inherit',
                                                textTransform: 'none'
                                            }}>{candidatura.name}</th>
                                    );
                                }
                                else if (i === results.candidatures.length - 1 && candidatura.votes > 0)
                                {
                                    return (
                                        <th key={i}
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                color: 'inherit',
                                                textTransform: 'none'
                                            }}>{candidatura.name}</th>
                                    );
                                }
                            })}
                        </tr>
                        </thead>
                        <tbody>

                        {results.meses.map((mesa, i) =>
                        {
                            const votesValid = mesa.votesCandidatures + mesa.votesBlank;
                            return (
                                <tr key={i}>
                                    <td className={'text-center'}
                                        style={{color: ((mesa.votesCandidatures + mesa.votesBlank + mesa.votesNull) <= 0) ? 'red' : 'inherit'}}>{mesa.districte + "-" + mesa.seccio + "-" + mesa.mesa}</td>
                                    <td className={'text-left text-left d-none d-md-table-cell'}
                                        style={{color: ((mesa.votesCandidatures + mesa.votesBlank + mesa.votesNull) <= 0) ? 'red' : 'inherit'}}>{mesa.description}</td>
                                    <td className={'text-center'}>{mesa.census}</td>
                                    {
                                        results.candidatures.map((candidatura, j) =>
                                        {
                                            // Get candidture in a 'mesa'
                                            const candidaturaMesa = mesa.candidatures.find(c => c.id === candidatura.id);
                                            console.log(mesa);
                                            console.log(candidaturaMesa);
                                            if (j < maxCandidatures)
                                            {
                                                return (
                                                    <td key={j} className={'text-center'}>
                                                        {(!isDetailsDatatableByCandidatureValues)
                                                            ? (candidaturaMesa.votes)
                                                            : (votesValid === 0) ? '0.00%' : (candidaturaMesa.votes / votesValid * 100).toFixed(2) + '%'
                                                        }
                                                    </td>
                                                );
                                            }

                                            // else if (i === mesa.candidatures.length - 1 && candidaturaMesa.votes > 0)
                                            // else if (!candidaturaMesa.id && candidaturaMesa.votes > 0)
                                            else if (!candidaturaMesa.id && (selectedElectionsType.attributes.code !== 'mun'))
                                            {
                                                return (
                                                    <td key={j} className={'text-center'}>
                                                        {(!isDetailsDatatableByCandidatureValues)
                                                            ? (candidaturaMesa.votes)
                                                            : (votesValid === 0) ? '0.00%' : (candidaturaMesa.votes / votesValid * 100).toFixed(2) + '%'
                                                        }
                                                    </td>
                                                );
                                            }
                                        })
                                    }
                                </tr>
                            );
                        })}

                        <tr style={{backgroundColor: '#E6E6E6'}}>
                            <td className={'text-center d-none d-md-table-cell'}></td>
                            <td className={'text-left'}><strong>Total</strong></td>
                            <td className={'text-center'}><strong>{results.census}</strong></td>
                            {results.candidatures.map((candidatura, i) =>
                            {
                                if (i < maxCandidatures)
                                {
                                    return (
                                        <td key={i} className={'text-center'}>
                                            <strong>
                                                {(!isDetailsDatatableByCandidatureValues)
                                                    ? (candidatura.votes)
                                                    : (candidatura.votes / results.votesValid * 100).toFixed(2) + '%'
                                                }
                                            </strong>
                                        </td>
                                    );
                                }
                                else if (i === results.candidatures.length - 1 && candidatura.votes > 0)
                                {
                                    return (
                                        <td key={i} className={'text-center'}>
                                            <strong>{(!isDetailsDatatableByCandidatureValues)
                                                ? (candidatura.votes)
                                                : (candidatura.votes / results.votesValid * 100).toFixed(2) + '%'
                                            }</strong>
                                        </td>
                                    );
                                }
                            })}
                        </tr>

                        {selectedElectionsType.attributes.code === 'mun' ?
                            <tr style={{backgroundColor: '#F6F6F6'}}>
                                <td className={'text-center d-none d-md-table-cell'}></td>
                                <td className={'text-left'}><FontAwesomeIcon icon={'user-alt'} fixedWidth/></td>
                                <td className={'text-center'}></td>
                                {results.candidatures.map((candidatura, i) =>
                                {
                                    if (i < maxCandidatures)
                                    {
                                        return (
                                            <td key={i} className={'text-center'}>
                                                <strong>{candidatura.numRegidors}</strong>
                                            </td>
                                        )
                                    }
                                })}
                            </tr> : null
                        }

                        </tbody>
                    </table>
                </div>
            </Fade>
        )
    }


    //==================================================================================================================
    //== Render button menu
    //==================================================================================================================
    renderMenu()
    {
        const {electionsTypes, elections} = this.props;
        const {selectedElectionsType, selectedElections} = this.state;

        const selectedElectionsTypeId = (selectedElectionsType) ? selectedElectionsType.id : '';
        const selectedElectionsId = (selectedElections) ? selectedElections.id : '';

        return (
            <div>
                <div className={'row py-3'}>
                    <div className={'col-lg-12'}>
                        <div className={'controls px-3 py-3'}>
                            <div className={'pb-3'}>
                                <h5>SELECCIÓ D'ELECCIONS</h5>
                            </div>

                            <div className={'row'}>
                                <div className={'col-lg-4'}>
                                    <div className="form-group">
                                        <label htmlFor="selectElectionsType" className={'pl-1'}>Tipus</label>
                                        <select id="selectElectionsType"
                                                className="form-control form-control-sm"
                                                value={selectedElectionsTypeId}
                                                onChange={(event) => this.handleChangeElectionsType(event)}>
                                            {electionsTypes.data.map((electionsType, key) =>
                                            {
                                                return (
                                                    <option key={key} value={electionsType.id}>
                                                        {electionsType.attributes.title}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className={'col-lg-4'}>
                                    <div className="form-group">
                                        <label htmlFor="selectElections" className={'pl-1'}>Eleccions</label>
                                        <select id="selectElections"
                                                className="form-control form-control-sm"
                                                value={selectedElectionsId}
                                                onChange={(event) => this.handleChangeElections(event)}>
                                            {elections.data.map((elections, key) =>
                                            {
                                                // if (selectedElectionsType
                                                //     && elections.relationships.elections_type.data.id === selectedElectionsType.id
                                                //     && !elections.attributes.is_provisional)
                                                if (selectedElectionsType && elections.relationships.elections_type.data.id === selectedElectionsType.id)
                                                {
                                                    return (
                                                        <option key={key} value={elections.id}>
                                                            {elections.attributes.title}
                                                        </option>
                                                    );
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className={'col-lg-4'}>
                                    <div className="form-group" style={{marginTop: '32px'}}>
                                        <button type={'button'}
                                                className={'btn btn-info btn-sm btn-block'}
                                                onClick={() => this.handleFullscreen()}>
                                            <FontAwesomeIcon icon={'tv'} fixedWidth/> Veure pantalla completa
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    //==================================================================================================================
    //== Render auto-update button
    //==================================================================================================================
    renderAutoUpdateButton()
    {
        const {autoUpdateEnabled} = this.state;
        return (
            <button type={'button'}
                    className={(autoUpdateEnabled) ? 'btn btn-warning btn-sm active' : 'btn btn-default btn-sm'}
                    onClick={() => this.handleEnableAutoUpdate()}>
                <FontAwesomeIcon icon={'redo-alt'} fixedWidth/> Activar actualització automàtica
            </button>
        );
    }


    //==================================================================================================================
    //== Render selected elections submenu
    //==================================================================================================================
    renderSubmenu()
    {
        const {selectedElections, selectedView} = this.state;

        let title = selectedElections.attributes.title;
        let subtitle = (selectedElections.attributes['is_provisional']) ? ' (dades provisionals)' : null;

        return (
            <div className={'mt-4'}>

                <Fade>
                    <div className={'row'}>

                        <div className={'col-12'}>
                            <h2>{title}
                                <small>{subtitle}</small>
                            </h2>
                        </div>

                    </div>

                    <div className={'row mb-5'}>

                        <div className={'col-12'}>
                            <div className={'mt-3'}>
                                <button type={'button'}
                                        className={(selectedView === 'overview') ? 'btn btn-info btn-sm active' : 'btn btn-default btn-sm'}
                                        onClick={() => this.setState({selectedView: 'overview'})}>Vista general
                                </button>
                                <button type={'button'}
                                        className={(selectedView === 'details') ? 'btn btn-info btn-sm ml-3 active' : 'btn btn-default btn-sm ml-3'}
                                        onClick={() => this.setState({selectedView: 'details'})}>Distribució per meses
                                </button>
                                <div className={'d-none d-sm-block float-right'}>
                                    {this.renderAutoUpdateButton()}
                                </div>
                                <div className={'d-block d-sm-none mt-3'}>
                                    {this.renderAutoUpdateButton()}
                                </div>
                            </div>
                        </div>

                    </div>
                </Fade>
            </div>
        )
    }


    //==================================================================================================================
    //== Render selected elections
    //==================================================================================================================
    renderElections()
    {
        const {selectedView, selectedElectionsType, selectedElections} = this.state;

        if (!selectedElections)
        {
            return (
                <div>
                    <span>No hi ha cap eleccions seleccionades.</span>
                </div>
            )
        }

        if (selectedView === 'overview')
        {
            return (
                <div>
                    {this.renderSubmenu()}
                    {this.renderElectionsOverview(selectedElectionsType, selectedElections)}
                </div>
            );
        }
        else if (selectedView === 'details')
        {
            return (
                <div>
                    {this.renderSubmenu()}
                    {this.renderElectionsDetails(selectedElectionsType, selectedElections)}
                </div>
            );
        }
    }


    //==================================================================================================================
    //== Render selected elections overview
    //==================================================================================================================
    renderElectionsOverview(selectedElectionsType, selectedElections)
    {
        const {isFullscreen} = this.state;
        const results = this.mapElectionsToResults(selectedElections);
        let graphPie, graphBar;

        if (selectedElectionsType.attributes.code === 'mun')
        {
            graphPie = {
                title: 'DISTRIBUCIÓ D\'ESCONS PER CANDIDATURES',
                description: '',
                showLabels: true,
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: []
                }]
            };
        }

        graphBar = {
            title: 'DISTRIBUCIÓ DE VOTS PER CANDIDATURES',
            description: '',
            showLabels: (!isFullscreen),
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: []
            }]
        };

        if (selectedElectionsType.attributes.code === 'mun')
        {
            results.candidatures.map((candidatura) =>
            {
                if (candidatura.numRegidors > 0)
                {
                    graphPie.labels.push(candidatura.name);
                    graphPie.datasets[0].data.push(candidatura.numRegidors);
                    graphPie.datasets[0].backgroundColor.push(candidatura.color);
                    graphPie.datasets[0].hoverBackgroundColor.push(candidatura.color);
                }

                graphBar.labels.push(candidatura.name);
                graphBar.datasets[0].data.push(candidatura.votes);
                graphBar.datasets[0].backgroundColor.push((candidatura.color) ? candidatura.color : '#E7E7E7');
                graphBar.datasets[0].hoverBackgroundColor.push(candidatura.color);
            });
        }

        // Show first 10 in order of votes and aggregate the rest
        else
        {
            let aggregatedVotes = 0;
            for (let i = 0; i < results.candidatures.length; ++i)
            {
                if (i < 10)
                {
                    graphBar.labels.push(results.candidatures[i].name);
                    graphBar.datasets[0].data.push(results.candidatures[i].votes);
                    graphBar.datasets[0].backgroundColor.push((results.candidatures[i].color) ? results.candidatures[i].color : '#E7E7E7');
                    graphBar.datasets[0].hoverBackgroundColor.push(results.candidatures[i].color);
                }
                else
                {
                    aggregatedVotes += results.candidatures[i].votes;
                }
            }

            graphBar.labels.push('Altres');
            graphBar.datasets[0].data.push(aggregatedVotes);
            graphBar.datasets[0].backgroundColor.push('#a6a6a6');
            graphBar.datasets[0].hoverBackgroundColor.push('#d6d6d6');
        }

        // Render graph and datatable
        return (
            <div>

                <div className={'results'}>
                    <div className={'row'}>
                        <div className={'col-12 col-xl-7'}>

                            {(selectedElectionsType.attributes.code === 'mun')
                                ?
                                <div className={'row mb-5'}>
                                    <div className={'col-12'}>
                                        {this.renderGraphPie(graphPie)}
                                    </div>
                                </div>
                                : null
                            }

                            <div className={'row'}>
                                <div className={'col-12'}>
                                    {this.renderGraphBar(graphBar)}
                                </div>
                            </div>

                        </div>

                        <div className={'col-12 col-xl-5'}>
                            <div className={'pl-0'}>
                                {this.renderOverviewDatatableEscrutini(results)}
                            </div>
                            <div className={'pl-0'}>
                                {this.renderOverviewDatatableCandidatures(results)}
                            </div>
                        </div>

                    </div>

                    {(selectedElectionsType.attributes.code === 'mun')
                        ?
                        <div className={'row'}>
                            <div className={'col-12'}>
                                {this.renderOverviewDatatableHondt(results)}
                            </div>
                        </div>
                        : null
                    }
                </div>

                <Fullscreen
                    enabled={this.state.isFullscreen}
                    onChange={isFullscreen => this.setState({isFullscreen})}

                >
                    <div className={'results'} style={{display: (this.state.isFullscreen) ? 'block' : 'none'}}>
                        <div className={'row'}>

                            {(selectedElectionsType.attributes.code === 'mun')
                                ?
                                <div className={'col-12 col-xl-4'}>

                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            {this.renderGraphPie(graphPie)}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <div
                                className={(selectedElectionsType.attributes.code === 'mun') ? 'col-12 col-xl-4' : 'col-12 col-xl-5'}>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        {this.renderGraphBar(graphBar)}
                                    </div>
                                </div>
                            </div>

                            <div
                                className={(selectedElectionsType.attributes.code === 'mun') ? 'col-12 col-xl-4' : 'col-12 col-xl-7'}>
                                <div className={'pl-0'}>
                                    {this.renderOverviewDatatableEscrutini(results)}
                                </div>
                            </div>

                        </div>


                        <div className={'row'}>
                            <div className={(selectedElectionsType.attributes.code === 'mun') ? 'col-6' : 'col-12'}>
                                <div className={'pl-0'}>
                                    {this.renderOverviewDatatableCandidatures(results)}
                                </div>
                            </div>
                            {(selectedElectionsType.attributes.code === 'mun')
                                ?
                                <div className={'col-6'}>
                                    <div className={'pl-0'}>
                                        {this.renderOverviewDatatableHondt(results)}
                                    </div>
                                </div>
                                : null
                            }
                        </div>


                    </div>
                </Fullscreen>

            </div>
        );
    }


    //==================================================================================================================
    //== Render selected elections details
    //==================================================================================================================
    renderElectionsDetails(selectedElectionsType, selectedElections)
    {
        const {isFullscreen, isDetailsDatatableByTypePercentValues, isDetailsDatatableByCandidatureValues} = this.state;
        const results = this.mapElectionsToResults(selectedElections);

        let graphs = [];
        results.meses.map((mesa) =>
        {
            let data = {
                title: mesa.districte + "-" + mesa.seccio + "-" + mesa.mesa,
                description: mesa.description,
                showLabels: false,
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: []
                }]
            };

            if (selectedElectionsType.attributes.code === 'mun')
            {
                mesa.candidatures.map((candidatura) =>
                {
                    data.labels.push(candidatura.name);
                    data.datasets[0].data.push(candidatura.votes);
                    data.datasets[0].backgroundColor.push(candidatura.color);
                    data.datasets[0].hoverBackgroundColor.push(candidatura.color);
                });
            }
            else
            {
                let aggregatedVotes = 0;
                for (let i = 0; i < mesa.candidatures.length; ++i)
                {
                    if (i < 10)
                    {
                        data.labels.push(mesa.candidatures[i].name);
                        data.datasets[0].data.push(mesa.candidatures[i].votes);
                        data.datasets[0].backgroundColor.push(mesa.candidatures[i].color);
                        data.datasets[0].hoverBackgroundColor.push(mesa.candidatures[i].color);
                    }
                    else
                    {
                        aggregatedVotes += mesa.candidatures[i].votes;
                    }
                }

                data.labels.push('Altres');
                data.datasets[0].data.push(aggregatedVotes);
                data.datasets[0].backgroundColor.push('#a6a6a6');
                data.datasets[0].hoverBackgroundColor.push('#d6d6d6');
            }
            graphs.push(data);
        });

        return (
            <Fullscreen
                enabled={this.state.isFullscreen}
                onChange={isFullscreen => this.setState({isFullscreen})}
            >
                <div className={'results'}>

                    <div className={'row'}>
                        <div className={'col-12'}>

                            <div className={'row'}>

                                <div className={(isFullscreen) ? 'col-6' : 'col-12'}>
                                    <h3 style={{display: 'inline-block'}}>Tipus de vots per meses</h3>
                                    <button type={'button'}
                                            className={'btn btn-info btn-xs ml-3'}
                                            onClick={() => this.setState((prevState) => ({isDetailsDatatableByTypePercentValues: !prevState.isDetailsDatatableByTypePercentValues}))}>
                                        {(!isDetailsDatatableByTypePercentValues)
                                            ? <span><FontAwesomeIcon icon={'percentage'} fixedWidth/> Veure percentatges</span>
                                            :
                                            <span><FontAwesomeIcon icon={'sort-numeric-down'} fixedWidth/> Veure valors</span>
                                        }
                                    </button>
                                </div>

                                <div className={(isFullscreen) ? 'col-6' : 'd-none'}>
                                    <div className={'float-right'}>
                                        <h3>
                                            <strong>{selectedElections.attributes.title}</strong>
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            {this.renderDetailsDatatableByType(results)}

                        </div>

                        <div className={(isFullscreen) ? 'col-12 mt-2' : 'col-12 mt-4'}>
                            <h3 style={{display: 'inline-block'}}>Vots a candidatures per meses</h3>
                            <button type={'button'}
                                    className={'btn btn-info btn-xs ml-3'}
                                    onClick={() => this.setState((prevState) => ({isDetailsDatatableByCandidatureValues: !prevState.isDetailsDatatableByCandidatureValues}))}>
                                {(!isDetailsDatatableByCandidatureValues)
                                    ? <span><FontAwesomeIcon icon={'percentage'} fixedWidth/> Veure percentatges</span>
                                    : <span><FontAwesomeIcon icon={'sort-numeric-down'} fixedWidth/> Veure valors</span>
                                }
                            </button>
                            {this.renderDetailsDatatableByCandidature(results)}
                        </div>

                        <div className={(isFullscreen) ? 'col-12 mt-2' : 'col-12 mt-4'}>
                            <h3>Gràfics de vots a candidatures</h3>
                        </div>

                        {graphs.map((graph, key) =>
                        {
                            return (
                                <div key={key} className={'col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 mt-3'}>
                                    {this.renderGraphBar(graph)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Fullscreen>
        );
    }


    //==================================================================================================================
    //== Render all
    //==================================================================================================================
    render()
    {
        const {isReading} = this.props;
        const {autoUpdateEnabled} = this.state;

        return (
            <div className={'resultats-electorals'}>

                <Fade>
                    <h1><FormattedMessage id={'app.municipi.eleccions.title'}/></h1>
                </Fade>

                {(isReading && !autoUpdateEnabled)
                    ? <Loader/>
                    :
                    <div>
                        {this.renderMenu()}
                        {this.renderElections()}
                    </div>
                }

            </div>
        );
    }
}

export default connect(mapStateToProps)(Eleccions);