import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API_APP_URL} from "./Constants";


//======================================================================================================================
//== Render MIME type icon
//======================================================================================================================
const renderFileAttachmentIcon = (filename) =>
{
    let extension = filename.split('.');
    if (!extension || !extension[extension.length - 1])
    {
        extension = 'file-alt';
    }
    else
    {
        extension = extension[extension.length - 1];
        if (!extension)
        {
            extension = 'file-alt';
        }
        else
        {
            switch (extension)
            {
                case 'pdf':
                    extension = 'file-pdf';
                    break;
                case 'doc':
                case 'docx':
                    extension = 'file-word';
                    break;
                case 'xls':
                case 'xlsx':
                    extension = 'file-excel';
                    break;
                case 'zip':
                case 'rar':
                case '7z':
                    extension = 'file-archive';
                    break;
                default:
                    extension = 'file-alt';
                    break;
            }
        }
    }

    return (
        <FontAwesomeIcon icon={extension} fixedWidth/>
    );
};


//======================================================================================================================
//== Render attached files
//======================================================================================================================
const renderAttachedFiles = (attached) =>
{
    if (attached.files.length > 0)
    {
        return (
            <div className={'row mt-3'}>

                <div className={'col-12'}>
                    <div className="alert alert-info" role="alert">
                        <h4 className="alert-heading">Fitxers adjunts</h4>
                        <hr/>
                        {(attached.files.map((file, key) =>
                            <div key={key}>
                                <p>
                                    {/*<strong>{file.attributes.description}: </strong>*/}
                                    {/*<a href={API_APP_URL + file.attributes.uri.url}*/}
                                    {/*target={'_blank'}> {renderFileAttachmentIcon(file.attributes.filename)}{file.attributes.filename}</a>*/}
                                    <a href={API_APP_URL + file.attributes.uri.url} target={'_blank'}>
                                        {renderFileAttachmentIcon(file.attributes.filename)} {file.attributes.description}
                                    </a>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        );
    }
};


//======================================================================================================================
//== Export functions
//======================================================================================================================
export {
    renderAttachedFiles,
    renderFileAttachmentIcon
};