import React from 'react';
import {FormattedMessage} from 'react-intl';
import Fade from 'react-reveal/Fade';
import {connect} from "react-redux";
import {readEndpoint} from "redux-json-api";
import Masonry from 'react-masonry-css';
import {SyncLoader} from 'react-spinners';
import Noticia from "../../../components/Noticia";
import moment from "moment";
import Loader from "../../../components/Loader";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const noticies = state.api['noticia'] || {data: []};
  const images = state.api['image'] || {data: []};  // Attached images
  const audios = state.api['media--audio'] || {data: []};  // Attached audio
  const videos = state.api['media--video'] || {data: []};  // Attached video
  const files = state.api['file'] || {data: []};  // Files

  return {
    noticies,
    images,
    audios,
    videos,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class Noticies extends React.Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {
      displayMode: 'highlighted',
      noticiesPageSize: 21,
      noticiesPageCurrent: 0,
      noticiesCount: 0,
      // noticiesPagination: []
    };
  }


  //==================================================================================================================
  //== Get all necessary data from different webservices
  //==================================================================================================================
  componentDidMount()
  {
    // window.scrollTo(0,0);

    //==============================================================================================================
    //== Retrieve noticies
    //==============================================================================================================
    // this.props.dispatch(readEndpoint('/node/noticia?include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date&page[offset]=0&page[limit]=21'))
    //     .then((res) =>
    //         {
    //             this.setState({
    //                 noticiesCount: parseInt(res.body.meta.count),
    //                 noticiesPageCurrent: 0
    //             })
    //         }
    //     );

    this.props.dispatch(readEndpoint('/node/noticia?include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date&filter[filter1][condition][path]=is_highlighted&filter[filter1][condition][operator]=%3D&filter[filter1][condition][value]=1&filter[status][value]=1'))
      .then((res) =>
        {
          this.setState({
            noticiesCount: parseInt(res.body.meta.count),
            noticiesPageCurrent: 0
          })
        }
      );

  }


  switchDisplayMode = () =>
  {
    // this.setState({displayMode: 'all'});

    this.props.dispatch(readEndpoint('/node/noticia?filter[status][value]=1&include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date&page[offset]=0&page[limit]=21'))
      .then((res) =>
        {
          this.setState({
            noticiesCount: parseInt(res.body.meta.count),
            noticiesPageCurrent: 0,
            displayMode: 'all'
          })
        }
      );
  };


  loadMore = () =>
  {
    const {noticiesPageSize, noticiesPageCurrent} = this.state;
    const offset = noticiesPageSize * (noticiesPageCurrent + 1);
    this.props.dispatch(readEndpoint('/node/noticia?filter[status][value]=1&include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&sort=-field_date&page[offset]=' + offset + '&page[limit]=21'))
      .then((res) =>
        {
          this.setState(prevState => ({
            noticiesPageCurrent: prevState.noticiesPageCurrent + 1
          }));
        }
      );
  };


  //==================================================================================================================
  //== Render all
  //==================================================================================================================
  render()
  {
    const {images, audios, videos, files, noticies} = this.props;
    const {displayMode, noticiesCount, noticiesPageSize, noticiesPageCurrent} = this.state;
    const media = {
      images: images,
      audios: audios,
      videos: videos,
      files: files
    };
    const breakpointColumns = {  //  Twitter Bootstrap 4 breakpoints
      default: 3,
      1200: 3,
      992: 3,
      768: 2,
      576: 1
    };


    // console.log(noticies);
    // console.log(noticiesCount);
    // console.log(noticiesPageSize);
    // console.log(noticiesPageCurrent);


    let showNoticies = [];
    if (displayMode === 'highlighted')
    {
      for (let n of noticies.data)
      {
        if (n.attributes.is_highlighted)
        {
          const currentDate = new Date();
          const startDate = new Date(n.attributes.date);
          const endDate = (n.attributes.date_end)
            ? new Date(n.attributes.date_end)
            : moment(startDate).add(2, 'month').toDate();

          if (currentDate.getTime() >= startDate && currentDate.getTime() <= endDate)
          {
            showNoticies.push(n);
          }
        }
      }
    }
    else if (displayMode === 'all')
    {
      // Obtenim nomes les 21 primers ordenades per data
      showNoticies = noticies.data.sort((a, b) => new Date(b.attributes.date).getTime() - new Date(a.attributes.date).getTime());
      showNoticies = showNoticies.slice(0, noticiesPageSize * (noticiesPageCurrent + 1));
      // noticiesPageSize
      //
      // showNoticies = noticies.data;
    }

    // console.log(showNoticies);

    return (
      <div>
        <Fade>
          <div className={'row'}>
            <div className={'col-12'}>
              <h1><FormattedMessage id={'app.municipi.noticies.title'}/></h1>
            </div>
          </div>
        </Fade>

        <div className={'row mt-4'}>
          <div className={'col-12'}>

            <Fade>
              <button type={'button'}
                      className={(displayMode === 'highlighted') ? 'btn btn-info btn-sm active' : 'btn btn-info btn-sm'}
                      onClick={() => this.setState({displayMode: 'highlighted'})}>Notícies destacades
              </button>

              <button type={'button'}
                      className={(displayMode === 'all') ? 'btn btn-info btn-sm ml-3 active' : 'btn btn-info btn-sm ml-3'}
                      onClick={() => this.switchDisplayMode()}>Totes les notícies
              </button>
            </Fade>

          </div>
        </div>

        <div className={'mt-2'}>
          {/*<SyncLoader*/}
          {/*className={'loader'}*/}
          {/*sizeUnit={'px'}*/}
          {/*size={10}*/}
          {/*color={'#7ED321'}*/}
          {/*loading={noticies.data.length <= 0}*/}
          {/*/>*/}

          {noticies.data.length <= 0 && <Loader/>}

          {(showNoticies.length > 0) ?
            <Masonry
              breakpointCols={breakpointColumns}
              className="masonry-grid"
              columnClassName="masonry-grid_column">
              {showNoticies.map((noticia, key) =>
              {
                return (
                  <Fade key={key}>
                    <Noticia media={media} noticia={noticia} display={'card'}/>
                  </Fade>
                );
              })}
            </Masonry> : null
          }

          {(displayMode === 'all' && noticiesCount > showNoticies.length)
            ?
            <div className={'text-center mt-4'}>
              <button type={'button'}
                      className={'btn btn-sm'}
                      onClick={() => this.loadMore()}>Mostrar més notícies
              </button>
            </div>
            : null
          }
        </div>

      </div>
    );
  }
}


export default connect(mapStateToProps)(Noticies);