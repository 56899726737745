import React from 'react';
import {FormattedMessage} from 'react-intl';
import Fade from 'react-reveal/Fade';
import {connect} from "react-redux";
import {readEndpoint} from "redux-json-api";
import {SyncLoader} from 'react-spinners';
import Lightbox from 'react-image-lightbox';
import Noticia from "../../../components/Noticia";
import Loader from "../../../components/Loader";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
  const isReading = (state.api.isReading > 0);
  const noticies = state.api['noticia'] || {data: []};
  const images = state.api['image'] || {data: []};  // Attached images
  const audios = state.api['media--audio'] || {data: []};  // Attached audio
  const videos = state.api['media--video'] || {data: []};  // Attached video
  const files = state.api['file'] || {data: []};  // Files

  return {
    isReading,
    noticies,
    images,
    audios,
    videos,
    files
  }
};


//======================================================================================================================
//==
//======================================================================================================================
class NoticiaDetail extends React.Component
{
  //==================================================================================================================
  //== Constructor
  //==================================================================================================================
  constructor(props)
  {
    super(props);
    this.state = {
      isLightBoxOpen: false,
      lightBoxSrc: ''
    };
  }


  //====================================================================================================================
  //== Get all necessary data from different webservices
  //====================================================================================================================
  componentDidMount()
  {
    const {params} = this.props.match;

    //==================================================================================================================
    //== Retrieve noticia details if not loaded (reuse data)
    //==================================================================================================================
    this.props.dispatch(readEndpoint('/node/noticia?include=files,media,media.media_image,media.field_media_audio_file,media.field_media_video_file&filter[id][condition][path]=id&filter[id][condition][operator]=%3D&filter[id][condition][value]=' + params.id));
  }


  //====================================================================================================================
  //== Render all
  //====================================================================================================================
  render()
  {
    const {isReading, noticies, images, audios, videos, files} = this.props;
    const {isLightBoxOpen} = this.state;
    const media = {
      images: images,
      audios: audios,
      videos: videos,
      files: files
    };

    const noticia = noticies.data.find(obj => (obj.id === this.props.match.params.id));
    if (isReading)
    {
      return (
        <Fade>
          <div className={'row'}>
            <div className={'col-12'}>
              <p><FormattedMessage id={'app.municipi.noticies.loading'}/></p>
            </div>
          </div>
        </Fade>
      );
    }
    else if (!noticia)
    {
      return (
        <Fade>
          <div className={'row'}>
            <div className={'col-12'}>
              <p><FormattedMessage id={'app.municipi.noticies.sense-resultats'}/></p>
            </div>
          </div>
        </Fade>
      );
    }

    return (
      <div>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={isLightBoxOpen}
            onCloseRequest={() => this.setState({isLightBoxOpen: false})}
          />
        )}
        {(noticies.data.length <= 0) ? <Loader/> : <Noticia media={media} noticia={noticia} display={'full'}/>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(NoticiaDetail);