import React from 'react';
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import Post from "../../../components/Post";
import {FormattedMessage} from "react-intl";
import {renderAttachedFiles} from "../../../utils";
import Loader from "../../../components/Loader";


//======================================================================================================================
//== Redux
//======================================================================================================================
const mapStateToProps = (state) =>
{
    const isReading = (state.api.isReading > 0);
    const subvencions = state.api['grants'] || {data: []};
    const files = state.api['file'] || {data: []};

    return {
        isReading,
        subvencions,
        files,
    }
};


//======================================================================================================================
//==
//======================================================================================================================
class Subvencions extends React.Component
{

    //==================================================================================================================
    //== Constructor
    //==================================================================================================================
    constructor(props)
    {
        super(props);
        this.state = {};
    }


    //==================================================================================================================
    //== Get all necessary data from different webservices
    //==================================================================================================================
    componentDidMount()
    {
        if (!this.props.grants || this.props.grants.data.length <= 0)
        {
            console.info('Retrieving node normativaDescription.');
            this.props.dispatch(readEndpoint('/node/grants?include=files'));
        }
    }


    //==================================================================================================================
    //== Render component
    //==================================================================================================================
    render()
    {
        const {subvencions, files, isReading} = this.props;
        const attached = {files: []};
        if (subvencions.data.length > 0
            && subvencions.data[0].relationships.files
            && subvencions.data[0].relationships.files.data)
        {
            subvencions.data[0].relationships['files'].data.map(file =>
            {
                if (file.type === 'file' && files && files.data && files.data.length > 0)
                {
                    let obj = files.data.find(aux => (aux.id === file.id));
                    obj.attributes.description = file.meta.description;
                    attached.files.push(obj);
                }
            });
        }

        return (
            <div>

                <Post data={subvencions.data[0]} isReading={isReading}/>

                {(isReading) ? <Loader/>
                    :
                    (subvencions.data.length > 0)
                        ?
                        <div>
                            <div className={'row'}>

                                <div className={'col-12'}>
                                    {renderAttachedFiles(attached)}
                                </div>

                            </div>

                            <div className={'row mt-4'}>

                                <div className={'col-12'}>
                                    <h3><FormattedMessage id={'app.ajuntament.normativa.subvencions.altres.title'}/></h3>
                                </div>

                                <div className={'col-12'}>
                                    <p><FormattedMessage id={'app.ajuntament.normativa.subvencions.altres.description'}/></p>
                                </div>

                                {subvencions.data[0].attributes.other_links.length > 0 ?
                                    <div className={'html-content mt-3'}>
                                        <ul>
                                        {subvencions.data[0].attributes.other_links.map((link, key) =>
                                        {
                                            return (<li key={key}><a href={link.uri} target={'_blank'}>{link.title}</a></li>);
                                        })}
                                        </ul>
                                    </div> : null
                                }

                            </div>

                        </div>
                        :
                        <div>
                            <p>No hi ha dades disponibles.</p>
                        </div>
                }

            </div>
        );
    }
}

export default connect(mapStateToProps)(Subvencions);